import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PersonIcon from "@mui/icons-material/Person";
import { SidebarProps, WorkShiftData } from "../../models/monitorView/monitorViewTypes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface MonitorViewWorkShiftHeaderProps extends SidebarProps<WorkShiftData> {
    header: string;
}

export const MonitorViewWorkShiftHeader = ({
    data,
    header,
    setSelectedVehicleId,
}: MonitorViewWorkShiftHeaderProps) => {
    const workShift = data?.[0]?.workShift;
    const effectiveDate = workShift?.effectiveDate;
    const employeeId = workShift?.employeeId;

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1, justifyContent: "flex-start" }}>
                <ArrowBackIcon
                    onClick={() => setSelectedVehicleId(null)}
                />
            </Box>
            <Tabs value={0} sx={{ flexGrow: 0 }}>
                <Tab label={header} icon={<PersonIcon sx={{ color: "#FFFFFF" }} />} />
            </Tabs>
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1, justifyContent: "flex-end" }}>
                {effectiveDate ? (
                    <a
                        href={`/worktimepage/details/${employeeId}/${effectiveDate}/hourbooking`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <OpenInNewIcon fontSize="small" color="primary" />
                    </a>
                ) : null}
            </Box>
        </Box>
    );
};