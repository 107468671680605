import { useEffect, useMemo, useState } from "react";
import {
    MapPointProps,
    MapRouteProps,
} from "../../components/framework/map/map";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { getVehiclePosition } from "../../services/vehicleService";
import { Base } from "../../framework/base";
import { useReload } from "../useReload";
import { getWorkTimeTypeColorCode } from "../../models/workShitTimeSlot/workTimeType";

interface WorkShiftTimeSlotRoute {
    [id: string]: MapRouteProps;
}

interface WorkShiftTimeSlotPoint {
    [id: string]: MapPointProps;
}

export interface WorkShiftTimeSlotMapData {
    routes: WorkShiftTimeSlotRoute;
    points: WorkShiftTimeSlotPoint;
}

export const useWorkShiftTimeSlotMapData = (
    workShiftTimeSlots: WorkShiftTimeSlotItem[],
    selectedId?: string,
    hoveredId?: string
) => {
    const [mapData, setMapData] = useState<WorkShiftTimeSlotMapData>();
    const [loading, setLoading] = useState(false);
    const [key, reload] = useReload();

    useEffect(() => {
        const abortController = new AbortController();
        const routeBySlot: WorkShiftTimeSlotRoute = {};
        const pointBySlot: WorkShiftTimeSlotPoint = {};

        setLoading(true);

        Promise.all(
            workShiftTimeSlots
                .map(
                    (w, i) =>
                        ({
                            ...w,
                            vehicleId:
                                !w.vehicleId &&
                                new WorkShiftTimeSlotItem(w).isBreak()
                                    ? workShiftTimeSlots[i - 1]?.vehicleId
                                    : w.vehicleId,
                        } as WorkShiftTimeSlotItem)
                )
                .filter((w) => w.vehicleId)
                .map((w) =>
                    getVehiclePosition(
                        w.vehicleId,
                        w.startDate,
                        w.endDate || Base.dayjsToJsonDateTimeOffset(),
                        abortController.signal
                    )
                        .then((res) => {
                            if (res?.data?.length > 0) {
                                routeBySlot[w.id] = {
                                    coords: res.data
                                        .sort((a, b) =>
                                            Base.dayjsCompare(
                                                a.timestamp,
                                                b.timestamp
                                            )
                                        )
                                        .map((d) => ({
                                            lat: d.latitude,
                                            lng: d.longitude,
                                        })),
                                };

                                pointBySlot[w.id] = {
                                    id: w.id,
                                    coords: routeBySlot[w.id].coords[0],
                                };
                            }
                        })
                        .catch(console.error)
                )
        ).finally(() => {
            setMapData({ routes: routeBySlot, points: pointBySlot });
            setLoading(false);
        });

        return () => {
            abortController.abort();
        };
    }, [
        key,
        workShiftTimeSlots
            .map((s) => s.rowId)
            .sort()
            .join(","),
    ]);

    const routes = useMemo(
        () =>
            mapData
                ? workShiftTimeSlots
                    .map((s) => {
                        const r = mapData.routes[s.id];
                        return r
                            ? {
                                ...r,
                                highlight:
                                        selectedId === s.id ||
                                        hoveredId === s.id,
                            }
                            : null;
                    })
                    .filter(Boolean)
                : null,
        [mapData, workShiftTimeSlots, selectedId, hoveredId]
    );

    const points = useMemo(
        () =>
            mapData
                ? workShiftTimeSlots
                    .filter((s) => !selectedId || selectedId === s.id)
                    .map((s) => {
                        const p = mapData.points[s.id];
                        const slot = new WorkShiftTimeSlotItem(s);
                        return p
                            ? {
                                ...p,
                                text: slot.getMapToolTipText(),
                                textColorClass:
                                        slot.getContrastColorClass(),
                                textBgColorClass: slot.getColorClass(),
                                groupName: slot.workTimeTypeName,
                                groupColor: getWorkTimeTypeColorCode(
                                    slot.workTimeTypeType
                                ),
                            }
                            : null;
                    })
                    .filter(Boolean)
                : null,
        [mapData, workShiftTimeSlots, selectedId]
    );

    return { routes, points, reload, loading };
};