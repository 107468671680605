import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, configureStore, combineReducers, Reducer, PreloadedState } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { alert, fetchCount, acknowledgementMain, confirmation, initialState, install, owner, ownerRoutePoints, shoppingCart, user, workMain, reportResponse } from "../models/store/storeReducers";
import { Session } from "../models/session/session";
import * as StoreActions from "../models/store/storeActions";

import { reportingReducer, reportingSlice } from "../store/reportingSlice";
import { storageReducer, storageSlice } from "../store/storageSlice";
import { timelinePlannerReducer, timelinePlannerSlice } from "../store/timelinePlannerSlice";
import { transportOrdersReducer, transportOrdersSlice } from "../store/transport/transportOrdersSlice";
import { parcelReducer, parcelSlice } from "../store/parcelSlice";
import { transportOrderInvoiceReducer, transportOrderInvoiceSlice } from "../store/transportOrderInvoiceSlice";
import { transportPlansReducer, transportPlansSlice } from "../store/transport/transportPlansSlice";
import { transportPlanTemplatesReducer, transportPlanTemplatesSlice } from "../store/transport/transportPlanTemplatesSlice";
import { transportVehiclesReducer, TransportVehiclesSlice } from "../store/transport/transportVehiclesSlice";
import { workTimeBetaReducer, workTimeBetaSlice, initialState as WorkTimeBetaInitialState } from "../store/workTimeBetaSlice";
import { workTimeDetailsReducer, workTimeDetailsSlice, initialState as WorkTimeDetailsInitialState } from "../store/workTimeDetailsSlice";
import { workShiftTimeSlotReducer, workShiftTimeSlotSlice } from "../store/workShiftTimeSlotSlice";
import { employeesReducer, employeesSlice } from "../store/employeeSlice";
import { workHoursReducer, workHoursSlice } from "../store/workHoursSlice";
import { workShiftReducer, workShiftSlice } from "../store/workShiftSlice.";
import { calendarReducer, calendarSlice } from "../store/calendarSlice";
import { appReducer, appSlice } from "../store/appSlice";
import { IApplicationState } from "../models/store/storeTypes";
import { sessionReducer, sessionSlice, initialState as SessionInitialState } from "../store/sessionSlice";
import { reportOptionsReducer, reportOptionsSlice, initialState as ReportOptionsState } from "../store/reportOptionsSlice";
import { monitoriViewReducer, monitorViewSlice, initialState as MonitorViewInitialState } from "../store/monitorViewSlice";
import { salaryPeriodSlice } from "../store/salaryPeriodSlice";

// Add slice here to persist state to LocalStorage
// Use initialState to set missing properties after reading from LocalStorage
const persistedSlices = [
    { name: sessionSlice.name, initialState: SessionInitialState },
    { name: workTimeBetaSlice.name, initialState: WorkTimeBetaInitialState },
    {
        name: workTimeDetailsSlice.name,
        initialState: WorkTimeDetailsInitialState,
    },
    { name: monitorViewSlice.name, initialState: MonitorViewInitialState },
    {
        name: reportOptionsSlice.name,
        initialState: ReportOptionsState,
        include: ["filters", "grouping"],
    },
];

const combinedReducer = combineReducers({
    fetchCount,
    install,
    alert,
    confirmation,
    owner,
    user,
    acknowledgementMain,
    workMain,
    ownerRoutePoints,
    shoppingCart,
    reportResponse,
    [employeesSlice.name]: employeesReducer,
    [timelinePlannerSlice.name]: timelinePlannerReducer,
    [storageSlice.name]: storageReducer,
    [reportingSlice.name]: reportingReducer,
    [workTimeBetaSlice.name]: workTimeBetaReducer,
    [workTimeDetailsSlice.name]: workTimeDetailsReducer,
    [TransportVehiclesSlice.name]: transportVehiclesReducer,
    [transportPlansSlice.name]: transportPlansReducer,
    [transportPlanTemplatesSlice.name]: transportPlanTemplatesReducer,
    [transportOrdersSlice.name]: transportOrdersReducer,
    [parcelSlice.name]: parcelReducer,
    [transportOrderInvoiceSlice.name]: transportOrderInvoiceReducer,
    [workShiftTimeSlotSlice.name]: workShiftTimeSlotReducer,
    [workHoursSlice.name]: workHoursReducer,
    [workShiftSlice.name]: workShiftReducer,
    [calendarSlice.name]: calendarReducer,
    [appSlice.name]: appReducer,
    [sessionSlice.name]: sessionReducer,
    [reportOptionsSlice.name]: reportOptionsReducer,
    [monitorViewSlice.name]: monitoriViewReducer,
    [salaryPeriodSlice.name]: salaryPeriodSlice.reducer,
});

export type RootState = ReturnType<typeof combinedReducer>;
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === "app/clearStore") {
        // Using initialState directly doesn't clear the store correctly for some reason.
        // Let's set the relevant individual properties instead.
        state = {
            user: { ...initialState.user },
            fetchCount: state.fetchCount,
            owner: { ...initialState.owner },
            acknowledgementMain: { ...initialState.acknowledgementMain },
            workMain: { ...initialState.workMain },
            ownerRoutePoints: { ...initialState.ownerRoutePoints },
            shoppingCart: { ...initialState.shoppingCart },
        } as RootState;
    }
    return combinedReducer(state, action);
};

// Middleware which persists specified parts of the state to LocalStorage
// Note that the state must be serializable (Pure JS Objecst, no classes/functions etc.)
// All parts are removed from LocalStorage when "clearStore" event is received.
const localStorageMiddleware = ({ getState }) => {
    return (next) => (action) => {
        const result = next(action);

        persistedSlices.forEach(({ name, include = [] }) => {
            if (action.type === "app/clearStore") {
                localStorage.removeItem(name);
            } else if (action.type?.startsWith(`${name}/`)) {
                const state = getState()[name];
                // If only specific are included, only store them.
                const storedState =
                    include.length > 0
                        ? Object.fromEntries(include.map((k) => [k, state[k]]))
                        : state;
                localStorage.setItem(name, JSON.stringify(storedState));
            }
        });

        return result;
    };
};

// Re-Hydrate specifed parts from LocalStorage to the application state.
const reHydrateStore = (appState: IApplicationState) => {
    persistedSlices.forEach(({ name, initialState, include = [] }) => {
        const state = localStorage.getItem(name);

        if (state !== null) {
            appState[name] = JSON.parse(state);

            // Set missing properties after reading from LocalStorage
            // in case there have been new properties added to the state on top-level
            Object.keys(initialState ?? {})
                // Only set keys which should be included, if any provided.
                .filter((k) => include.length === 0 || include.includes(k))
                .forEach((k) => {
                    if (appState[name][k] === undefined) {
                        appState[name][k] = { ...initialState[k] };
                    }
                });
        }
    });

    return appState as RootState;
};

export const customStore = configureStore({
    reducer: rootReducer,
    middleware: [thunk, localStorageMiddleware],
    preloadedState: reHydrateStore(initialState),
});

// Test helper fn to setup store using actual reducers. Provide preloadedState to alter initial state
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState
    });
};

export type AppDispatch = typeof customStore.dispatch;
export type Store = ReturnType<typeof customStore.getState>;

// TS wrappers for select and dispatch. Use to avoid boilerplate.
export const useAppDispatch = () : AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Initialize authentication
void Session.getAccessToken().then(i => {
    if (!i) return;
    customStore.dispatch(StoreActions.loginSuccess());
}).catch(console.error);
