import { WorkTimeTypeListItem } from "./workTimeTypeListItem";

export class WorkTimeTypeEditItem {

    workTimeTypeId: string = null;
    name = "";
    type: number = null;
    ownerId: string = null;
    calculateOvertime: boolean;
    calculateAddition: boolean;

    constructor();
    constructor(obj: WorkTimeTypeListItem);
    constructor(obj?: any) {
        this.workTimeTypeId = obj && obj.workTimeTypeId || "";
        this.name = obj && obj.name || "";
        this.type = obj && obj.type || 0;
        this.ownerId = obj && obj.ownerId || "";
        this.calculateOvertime = obj && obj.calculateOvertime || false;
        this.calculateAddition = obj && obj.calculateAddition || false;
    }
}
