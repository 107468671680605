import { EmployeeWorkTimeType } from "../workShitTimeSlot/workTimeType";

export interface VehiclePositionInfo {
    carId: string;
    vehicleId: string;
    registerNumber: string;
    brand: string;
    latitude: number;
    longitude: number;
    timestamp: string;
    employeeWorkTimeTypes: EmployeeWorkTimeType[];
    codriverGmt: string | null;
    codriverId: number;
    driverGmt: string | null;
    driverId: number;
    driving: number;
    drivingGmt: string;
    speed: number;
}

export interface VehiclePositionQueryParams {
    windowStartTime: string;
    windowEndTime: string;
}

export enum VehicleDriving {
    Inactive = -1,
    Stopped = 0,
    Moving = 1,
    Driving = 2
}