import React, { useCallback, useMemo, useState } from "react";
import { Box, Divider, CircularProgress, Typography } from "@mui/material";
import { Base } from "../../framework/base";
import { useTranslation } from "react-i18next";
import { AppUtils } from "../../models/common/appUtils";
import {
    calculatedWorkHours,
    normalWorkHours,
} from "../../store/workHoursSlice";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import {
    useSelectCalculatedWorkHoursAmountBySalaryRowTypeId,
    useSelectPaidWorkHoursAmountByDate,
} from "../../hooks/workTime/useSelectWorkHours";
import { useSelectSalaryPeriodByDate } from "../../hooks/workTime/useSelectSalaryPeriod";
import AccordionGroup, { AccordionDetailsItem } from "../../framework/muiAccordion";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import {
    additionCategories,
    normalWorkPaidCategories,
    overtimeCategories,
} from "../../models/workTime/workHoursItem";
import { handleApiError } from "../../models/store/storeEffects";
import dayjs, { Dayjs } from "dayjs";


interface WorkLogMainProps {
    employeeId: string;
}

export const WorklogMain: React.FC<WorkLogMainProps> = ({ employeeId }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const [dataFetched, setDataFetched] = useState(false);

    const dateToShortDateStr = (date: string | Dayjs | Date | number): string => {
        return dayjs(date).format("D.M.");
    };

    const dateToDateStr = (date: string | Dayjs | Date | number): string => {
        return dayjs(date).format("D.M.YYYY");
    };

    const dateToDateJSON = (date?: string | Dayjs | Date | number): string => {
        return dayjs(date).format("YYYY-MM-DD");
    };

    const dateString = dateToDateJSON();

    const salaryRowTypes = useAppSelector(
        (state) => state.workShiftTimeSlot.salaryRowTypes
    );

    const selectedSalaryPeriodItem = useSelectSalaryPeriodByDate(dateString);

    const salaryPeriodMinutes = useSelectPaidWorkHoursAmountByDate(
        employeeId,
        selectedSalaryPeriodItem?.startDate,
        selectedSalaryPeriodItem?.endDate
    );

    const salaryPeriodWorkHours =
        useSelectCalculatedWorkHoursAmountBySalaryRowTypeId(
            employeeId,
            selectedSalaryPeriodItem?.startDate,
            selectedSalaryPeriodItem?.endDate
        );

    const salaryRowTypeMap = useMemo(
        () => new Map((salaryRowTypes ?? []).map((s) => [s.id, s])),
        [salaryRowTypes]
    );

    const getSectionContent = useCallback(
        (workHours: { [k: string]: number }) => {
            const categories = [
                normalWorkPaidCategories(),
                additionCategories(),
                overtimeCategories(),
            ];

            const grouped: { [key: number]: JSX.Element[] } = {};

            const srts = Object.keys(workHours)
                .map((k) => salaryRowTypeMap.get(k))
                .filter(Boolean);

            srts.forEach((srt) => {
                let idx = categories.findIndex((c) =>
                    c.some((cat) => srt.hourBookingCategories.includes(cat))
                );

                // If idx not found, it will be in last group.
                idx = idx === -1 ? categories.length : idx;
                grouped[idx] ||= [];

                // Group items by idx
                // Push each item to their group
                grouped[idx].push(
                    <AccordionDetailsItem
                        key={srt.id}
                        title={srt.name}
                        hours={AppUtils.getDisplayedNumberBasedOnUnit(
                            workHours[srt.id],
                            srt.measureUnit
                        )}
                    />
                );
            });

            let retVal: JSX.Element[] = [];

            // Return each group separated by Divider
            Object.keys(grouped)
                .sort()
                .forEach((k, i) => {
                    const arr = grouped[k];

                    if (arr.length > 0) {
                        if (retVal.length > 0) {
                            retVal.push(
                                <Divider
                                    key={`divider-${i}`}
                                    sx={{ mt: 1.5, mb: 0.5 }}
                                />
                            );
                        }

                        retVal = [...retVal, ...arr];
                    }
                });

            return retVal;
        },
        [salaryRowTypeMap]
    );

    useAsyncThunkAction(
        () => {
            if (selectedSalaryPeriodItem?.id) {
                return calculatedWorkHours({
                    employeeId: employeeId,
                    salaryPeriodId: selectedSalaryPeriodItem?.id,
                    startDate: selectedSalaryPeriodItem?.startDate,
                    endDate: selectedSalaryPeriodItem?.endDate,
                });
            }
        },
        {
            onError: (e) => {
                handleApiError(e, dispatch);
            },
            onSuccess: () => {
                setLoading(false);
                setDataFetched(true);
            },
        },
        [selectedSalaryPeriodItem?.id]
    );

    useAsyncThunkAction(
        () =>
            normalWorkHours({
                employeeId: employeeId,
                startDate: dateToDateJSON(
                    Base.minDate([
                        selectedSalaryPeriodItem?.startDate,
                        Base.dateStartOf(
                            Base.dateStartOf(dateString, "month"),
                            "week"
                        ),
                    ])
                ),
                endDate: dateToDateJSON(
                    Base.maxDate([
                        selectedSalaryPeriodItem?.endDate,
                        Base.dateEndOf(
                            Base.dateEndOf(dateString, "month"),
                            "week"
                        ),
                    ])
                ),
            }),
        {
            onError: (e) => {
                handleApiError(e, dispatch);
            },
            onSuccess: () => {
                setLoading(false);
                setDataFetched(true);
            },
        },
        [
            selectedSalaryPeriodItem?.startDate,
            selectedSalaryPeriodItem?.endDate,
            new Date(dateString).getMonth(),
        ]
    );

    const salaryPeriodTitle = useMemo(
        () =>
            `${t("salaryPeriod")} (${
                new Date(selectedSalaryPeriodItem?.startDate).getFullYear() !==
                new Date(selectedSalaryPeriodItem?.endDate).getFullYear()
                    ? dateToDateJSON(selectedSalaryPeriodItem?.startDate)
                    : dateToShortDateStr(
                          selectedSalaryPeriodItem?.startDate
                      )
            } -
                ${dateToDateStr(selectedSalaryPeriodItem?.endDate)})`,
        [
            selectedSalaryPeriodItem?.startDate,
            selectedSalaryPeriodItem?.endDate,
            t,
        ]
    );

    const dataFound = useAppSelector(
        (state) => {
            const calculatedWorkHours = state.workHours.calculated.find(item => item.employeeId === employeeId);
            const normalWorkHours = state.workHours.normal.find(item => item.employeeId === employeeId);
            return !!calculatedWorkHours && !!normalWorkHours;
        }
    );

    return (
        <Box sx={{ p: 1 }}>
            {loading ? (
                <CircularProgress />
            ) : !dataFound && dataFetched ? (
                <Typography variant="h6" color="primary">
                    {t("monitorView.worklogNoData")}
                </Typography>
            ) : (
                <AccordionGroup
                    items={[
                        selectedSalaryPeriodItem && salaryPeriodMinutes
                            ? {
                                header: salaryPeriodTitle,
                                subheader:
                                    AppUtils.getDurationStrByDurationMin(
                                        salaryPeriodMinutes,
                                        true
                                    ),
                                content: getSectionContent(
                                    salaryPeriodWorkHours
                                ),
                            }
                            : null,
                    ].filter(Boolean)}
                />
            )}
        </Box>
    );
};