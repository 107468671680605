import { IEditItem, EditItem } from "../common/editItem";

export interface IEmployeeGroupEditItem extends IEditItem {
    employmentId: string;
    salaryPeriodTypeId: string;
    name: string;
    description: string;
    workQueueInUse: boolean;
    locationDataInUse: boolean;
    canCreateWorkOrderInMobile: boolean;
    collectiveAgreementId: string;
    workTimeAutoStartEnabled: boolean;
    workTimeAutoEndEnabled: boolean;
    workTimeAutoDrivingEnabled: boolean;
    workTimeAutoStopEnabled: boolean;
    workTimeAutoStopWorkTimeTypeId: string;
    workTimeAutoDrivingThreshold: number;
    workTimeAutoStopThreshold: number;
    paymentGroup: string;
    canEditWorkEntries: boolean;
}

export class EmployeeGroupEditItem extends EditItem implements IEmployeeGroupEditItem {
    employmentId: string;
    salaryPeriodTypeId: string;
    name: string;
    description: string;
    workQueueInUse: boolean;
    locationDataInUse: boolean;
    canCreateWorkOrderInMobile: boolean;
    collectiveAgreementId: string;
    workTimeAutoStartEnabled: boolean;
    workTimeAutoEndEnabled: boolean;
    workTimeAutoDrivingEnabled: boolean;
    workTimeAutoStopEnabled: boolean;
    workTimeAutoStopWorkTimeTypeId: string;
    workTimeAutoDrivingThreshold: number;
    workTimeAutoStopThreshold: number;
    paymentGroup: string;
    canEditWorkEntries: boolean;

    constructor();
    constructor(obj: IEmployeeGroupEditItem);
    constructor(obj?: any) {
        super(obj);
        this.employmentId = obj && obj.employmentId || "";
        this.salaryPeriodTypeId = obj && obj.salaryPeriodTypeId || "";
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.workQueueInUse = obj && obj.workQueueInUse || false;
        this.locationDataInUse = obj && obj.locationDataInUse || false;
        this.canCreateWorkOrderInMobile = obj && obj.canCreateWorkOrderInMobile || false;
        this.collectiveAgreementId = obj && obj.collectiveAgreementId || "";
        this.workTimeAutoStartEnabled = obj && obj.workTimeAutoStartEnabled || false;
        this.workTimeAutoEndEnabled = obj && obj.workTimeAutoEndEnabled || false;
        this.workTimeAutoDrivingEnabled = obj && obj.workTimeAutoDrivingEnabled || false;
        this.workTimeAutoStopEnabled = obj && obj.workTimeAutoStopEnabled || false;
        this.workTimeAutoStopWorkTimeTypeId = obj && obj.workTimeAutoStopWorkTimeTypeId || "";
        this.workTimeAutoDrivingThreshold = (obj && obj.workTimeAutoDrivingThreshold) ?? 30;
        this.workTimeAutoStopThreshold = (obj && obj.workTimeAutoStopThreshold) ?? 5;
        this.paymentGroup = obj && obj.paymentGroup || "";
        this.canEditWorkEntries = obj && obj.canEditWorkEntries || false;
    }
}
