// SettingsEmployeeDialog - MODULE
// ***********************************************************************************************************************
import downloadjs from "downloadjs";
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import * as baseService from "../../services/baseService";
import * as documentService from "../../services/documentService";
import * as employeeService from "../../services/employeeService";
import * as tokenService from "../../services/tokenService";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { CheckBox } from "../framework/checkbox";
import { IEmployeeEdit } from "../../models/employee/employeeEdit";
import { Button } from "../framework/button";
import { SettingsEmployeeDialogDayBookingList } from "./settingsEmployeeDialogDayBookingList";
import { IDocument, Document, IDocumentInfoSave, DocumentInfoSave } from "../../models/document/document";
import { DocumentList } from "../document/documentList";
import { SettingsChangePasswordDialog } from "./settingsChangePasswordDialog";
import { SettingsEmployeeDialogChangeUsernameDialog } from "./settingsEmployeeDialogChangeUsernameDialog";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { EmployeeEditItemEmployeeCompetencyItem, IEmployeeEditItemEmployeeCompetencyItem, EmployeeEditItemSaveEmployeeCompetencyItem } from "../../models/employee/employeeEditItemEmployeeCompetencyItem";
import { EmployeeEditCompetencyItem } from "../../models/employee/employeeEditCompetencyItem";
import { SettingsEmployeeDialogEmployeeCompetencyList } from "./settingsEmployeeDialogEmployeeCompetencyList";
import { ScrollSpy, IScrollSpyItem, ScrollSpyItem } from "../framework/scrollSpy";
import { ImageSelector } from "../framework/imageSelector";
import { IdTitleDescription, IIdTitleDescription } from "../../models/common/idTitleDescription";
import { IVehicleItem, VehicleItem } from "../../models/vehicle/vehicleItem";
import { IDayBookingEditItem } from "../../models/dayBooking/dayBookingEditItem";
import { SettingsEmployeeDialogSsoIntegrationList } from "./settingsEmployeeDialogSsoIntegrationList";
import { IEmployeeEditItemSsoIntegrationAttribute } from "../../models/employee/employeeEditItemSsoIntegrationAttribute";
import { EmployeeEditItemSaveSsoIntegration, IEmployeeEditItemSsoIntegration } from "../../models/employee/employeeEditItemSsoIntegration";
import { IUserGroupItem } from "../../models/userGroup/userGroupItem";
import { SettingsEmployeeWorkHourAccountTransactions } from "./settingsEmployeeWorkHourAccountTransactions";
import { IWorkTimeAccountTransaction } from "../../models/workTimeAccount/workTimeAccountTransaction";
import { DocumentType } from "../../models/common/enums";
import { IEmployeeEditEmployeeGroupItem } from "../../models/employee/employeeEditEmployeeGroupItem";
import { SettingsEmployeeDialogEmployeeGroupList } from "./settingsEmployeeDialogEmployeeGroupList";
import { t } from "i18next";
// SettingsEmployeeDialog
// ***********************************************************************************************************************
export interface ISettingsEmployeeDialogEditViewProp {
    classes?: string;
    employeeEdit: IEmployeeEdit;
    userGroups: IUserGroupItem[];
    dayBookings: IDayBookingEditItem[];
    employeeEmployeeGroups: IEmployeeEditEmployeeGroupItem[];
    ssoIntegrations: IEmployeeEditItemSsoIntegration[];
    onDayBookingRemoved: (id: string) => void;
    onAddDayBooking: (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => void;
    onEditDayBooking: (id: string, dayBookingTypeId: string, date: number, duration: number, dayUsageType: number, comment: string, rowId: string) => void;
    onEmployeeEmployeeGroupRemoved: (id: string) => void;
    onAddEmployeeEmployeeGroup: (id: string, employeeGroupId: string, groupName: string) => void;
    onEditSsoIntegration: (serviceName: string, attributes: IEmployeeEditItemSsoIntegrationAttribute[]) => void;
    workHourTransactions: IWorkTimeAccountTransaction[];
    workHourBalance: number;
}

export interface ISettingsEmployeeDialogEditViewState {
    vehicles: IVehicleItem[];
    costCenters: IIdTitleDescription[];
    rowId: string;
    picture: string;
    pictureFile: File;
    removePicture: boolean;
    firstName: string;
    lastName: string;
    abbreviation: string;
    phone: string;
    email: string;
    erp: string;
    employeeGroupId: string;
    salaryEmployeeGroupId: string;
    vehicleId: string;
    costCenterId: string;
    userGroupId: string;
    salaryTypeId: string;
    username: string;
    password: string;
    passwordConfirmation: string;
    activeState: number;
    workTimeAccountInUse: boolean;

    competencies: IEmployeeEditItemEmployeeCompetencyItem[];
    removedEmployeeCompetencyIds: string[];
    savedEmployeeCompetencyIds: string[];

    documents: IDocument[];
    removedDocumentIds: string[];
    showChangePasswordDialog: boolean;
    showChangeUsernameDialog: boolean;

    scrollSpyItems: IScrollSpyItem[];

    postalCodeRangeStart: string;
    postalCodeRangeEnd: string;
    mapOnId: number;
    tachoCardId: string;
}

export class SettingsEmployeeDialogEditView extends React.Component<ISettingsEmployeeDialogEditViewProp, ISettingsEmployeeDialogEditViewState> {
    private static orgStateHash: string = "";
    private employeeContentAreaDiv: HTMLDivElement;
    private static getScrollSpyItems(workTimeAccountInUse: boolean): IScrollSpyItem[] {
        const result: IScrollSpyItem[] = [];
        result.push(ScrollSpyItem.createScrollSpyItem("general", Translations.General, "title"));
        result.push(ScrollSpyItem.createScrollSpyItem("dayBookings", Translations.DayBookings, "title"));
        result.push(ScrollSpyItem.createScrollSpyItem("employeeGroups", Translations.SecondaryEmployeeGroups, "title"));
        if (workTimeAccountInUse) {
            result.push(ScrollSpyItem.createScrollSpyItem("workHourTransactions", Translations.WorkhourBank, "title"));
        }
        result.push(ScrollSpyItem.createScrollSpyItem("competencies", Translations.Competencies, "title"));
        result.push(ScrollSpyItem.createScrollSpyItem("documents", Translations.Documents, "title"));
        result.push(ScrollSpyItem.createScrollSpyItem("integrations", Translations.SsoIntegrations, "title"));
        return result;
    }

    constructor(props: ISettingsEmployeeDialogEditViewProp) {
        super(props);
        const employee = props.employeeEdit.employee;
        const vehicles = props.employeeEdit.vehicles.slice(0);
        const unselectedVehicle = new VehicleItem();
        unselectedVehicle.id = Base.emptyGuid;
        vehicles.unshift(unselectedVehicle);
        const costCenters = props.employeeEdit.costCenters.slice(0);
        costCenters.unshift(IdTitleDescription.createIdTitleDescription(Base.emptyGuid, "", ""));
        this.state = {
            vehicles: vehicles,
            costCenters: costCenters,
            rowId: employee.rowId,
            firstName: employee.firstName,
            lastName: employee.lastName,
            abbreviation: employee.abbreviation,
            picture: employee.picture,
            pictureFile: null,
            removePicture: false,
            phone: employee.phone,
            email: employee.email,
            erp: employee.erp,
            employeeGroupId: employee.employeeGroupId,
            salaryEmployeeGroupId: employee.salaryEmployeeGroupId,
            vehicleId: employee.vehicleId,
            costCenterId: employee.costCenterId,
            userGroupId: employee.userGroupId,
            salaryTypeId: employee.salaryTypeId,
            username: employee.username,
            password: "",
            passwordConfirmation: "",
            activeState: employee.activeState,
            competencies: EmployeeEditItemEmployeeCompetencyItem.setCompetencyNames(employee.competencies.map(i => new EmployeeEditItemEmployeeCompetencyItem(i)), props.employeeEdit.competencies),
            removedEmployeeCompetencyIds: [],
            savedEmployeeCompetencyIds: [],
            documents: employee.documents.map(i => new Document(i)),
            removedDocumentIds: [],
            showChangePasswordDialog: false,
            showChangeUsernameDialog: false,
            scrollSpyItems: SettingsEmployeeDialogEditView.getScrollSpyItems(true),
            workTimeAccountInUse: employee.workTimeAccountInUse,
            postalCodeRangeStart: employee.postalCodeRangeStart,
            postalCodeRangeEnd: employee.postalCodeRangeEnd,
            mapOnId: employee.mapOnId,
            tachoCardId: employee.tachoCardId
        };
        const saveData = SettingsEmployeeDialogEditView.getSaveDataFromState(props, this.state);
        SettingsEmployeeDialogEditView.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "FNm") {
            this.setState({ firstName: value });
        } else if (name === "LNm") {
            this.setState({ lastName: value });
        } else if (name === "abbreviation") {
            this.setState({ abbreviation: value });
        } else if (name === "Phn") {
            this.setState({ phone: value });
        } else if (name === "Eml") {
            this.setState({ email: value });
        } else if (name === "vehicleId") {
            this.setState({ vehicleId: value });
        } else if (name === "costCenterId") {
            this.setState({ costCenterId: value });
        } else if (name === "userGroupId") {
            this.setState({ userGroupId: value });
        } else if (name === "salaryTypeId") {
            this.setState({ salaryTypeId: value });
        } else if (name === "uNm") {
            this.setState({ username: value });
        } else if (name === "password") {
            this.setState({ password: value });
        } else if (name === "passwordConfirmation") {
            this.setState({ passwordConfirmation: value });
        } else if (name === "erp") {
            this.setState({ erp: value });
        } else if (name === "employeeGroupId") {
            this.setState({ employeeGroupId: value });
        } else if (name === "salaryEmployeeGroupId") {
            this.setState({ salaryEmployeeGroupId: value });
        } else if (name === "PostalCodeRangeFrom") {
            this.setState({ postalCodeRangeStart: value });
        } else if (name === "PostalCodeRangeTo") {
            this.setState({ postalCodeRangeEnd: value });
        } else if (name === "maponid") {
            this.setState({ mapOnId: +value });
        } else if (name === "tachoCardId") {
            this.setState({ tachoCardId: value });
        }
    };

    handleActiveStateChange = (newActiveState: number) => {
        this.setState({ activeState: newActiveState });
    };

    handleWorkTimeInUseChange = (workTimeAccountInUse: boolean) => {
        this.setState({
            workTimeAccountInUse: workTimeAccountInUse,
            scrollSpyItems: SettingsEmployeeDialogEditView.getScrollSpyItems(workTimeAccountInUse)
        });
    };

    handleChangeUsername = () => {
        this.setState({
            showChangeUsernameDialog: true
        });
    };

    handleChangePassword = () => {
        this.setState({
            showChangePasswordDialog: true
        });
    };

    handleSetPicture = (file: File) => {
        if (Base.isNullOrUndefined(file)) return;
        const obj = this;
        Base.blobToBase64(file).then(base64Image => {
            obj.setState({
                pictureFile: file,
                picture: base64Image,
                removePicture: false
            });
        });
    };

    handleRemovePicture = () => {
        this.setState({
            pictureFile: null,
            picture: "",
            removePicture: true
        });
    };
    // #endregion General

    // #region EmployeeCompetencies
    saveEmployeeCompetency = (competencies: IEmployeeEditItemEmployeeCompetencyItem[], savedEmployeeCompetencyIds: string[], item: EmployeeEditItemEmployeeCompetencyItem,
        id: string, competencyId: string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => {
        item.id = id;
        item.competencyId = competencyId;
        const value = EmployeeEditCompetencyItem.getNameAndCompetencyNameById(this.props.employeeEdit.competencies, competencyId);
        item.competencyName = value.name;
        item.competencyTypeName = value.competencyTypeName;
        item.date = date;
        item.expireDate = expireDate;
        item.comment = comment;
        item.documents = documents;
        savedEmployeeCompetencyIds.push(item.id);
        EmployeeEditItemEmployeeCompetencyItem.sortEmployeeEditItemEmployeeCompetencyItems(competencies, "name", true);
        const allRemovedDocumentIds = this.state.removedDocumentIds.slice(0);
        for (let i = 0; i < removedDocumentIds.length; i++) {
            allRemovedDocumentIds.push(removedDocumentIds[i]);
        }
        this.setState({ competencies: competencies, savedEmployeeCompetencyIds: savedEmployeeCompetencyIds, removedDocumentIds: removedDocumentIds });
    };

    handleEmployeeCompetencyAdd = (id: string, competencyId: string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => {
        const competencies = this.state.competencies.slice(0);
        const savedEmployeeCompetencyIds = this.state.savedEmployeeCompetencyIds.slice(0);
        const competency = new EmployeeEditItemEmployeeCompetencyItem();
        competencies.push(competency);
        this.saveEmployeeCompetency(competencies, savedEmployeeCompetencyIds, competency, id, competencyId, date, expireDate, comment, documents, removedDocumentIds);
    };

    handleEmployeeCompetencyEdit = (id: string, competencyId: string, date: number, expireDate: number, comment: string, documents: IDocument[], removedDocumentIds: string[]) => {
        if (!id) return;
        const competencies = this.state.competencies.slice(0);
        const index = competencies.findIndex(i => i.id === id);
        if (index < 0) return;
        const savedEmployeeCompetencyIds = this.state.savedEmployeeCompetencyIds.filter(i => i !== id);
        this.saveEmployeeCompetency(competencies, savedEmployeeCompetencyIds, competencies[index], id, competencyId, date, expireDate, comment, documents, removedDocumentIds);
    };

    handleEmployeeCompetencyRemove = (id: string) => {
        if (!id) return;
        const item = this.state.competencies.find(i => i.id === id);
        if (Base.isNullOrUndefined(item)) return;
        const removedEmployeeCompetencyIds = this.state.removedEmployeeCompetencyIds.filter(i => i !== id);
        if (!item.isNew()) {
            removedEmployeeCompetencyIds.push(id);
        }
        this.setState({ competencies: this.state.competencies.filter(i => i.id !== id), savedEmployeeCompetencyIds: this.state.savedEmployeeCompetencyIds.filter(i => i !== id), removedEmployeeCompetencyIds: removedEmployeeCompetencyIds });
    };
    // #endregion EmployeeCompetencies

    // #region Documents
    handleDocumentsAdd = (fileList: FileList) => {
        const obj = this;
        const employee = this.props.employeeEdit.employee;
        Document.addFileListToDocuments(employee.id, this.state.documents, fileList).then(documents => {
            Document.sortDocuments(documents, "title", true);
            obj.setState({ documents: documents });
        });
    };

    handleDocumentDownload = (id: string) => {
        if (!id) return;
        const document = this.state.documents.find(i => i.id === id);
        if (!document) return;
        if (document.file) {
            downloadjs(document.file, document.reference); //, "image/jpeg"
            return;
        }
        if (document.isNew()) return;
        documentService.getDocument(id).catch(error => {
            store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
        });
    };

    handleDocumentEdit = (id: string, comment: string, documentType: DocumentType) => {
        if (!id) return;
        const documents = this.state.documents.slice(0);
        const document = documents.find(i => i.id === id);
        if (!document) return;
        document.comment = comment;
        document.documentType = documentType;
        this.setState({
            documents: documents
        });
    };

    handleDocumentRemove = (id: string) => {
        if (!id) return;
        const documents = this.state.documents.filter(i => i.id !== id);
        const removedDocumentIds = this.state.removedDocumentIds.slice(0);
        removedDocumentIds.push(id);
        this.setState({ documents: documents, removedDocumentIds: removedDocumentIds });
    };
    // #endregion Documents

    private static checkErrors = (props: ISettingsEmployeeDialogEditViewProp, state: ISettingsEmployeeDialogEditViewState): Promise<boolean> => {
        return new Promise<boolean>((resolve) => {
            const employee = props.employeeEdit.employee;
            if (!state.firstName) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.FirstNameMustBeDefined));
                return resolve(false);
            }
            if (!state.lastName) {
                store.customStore.dispatch(storeActions.showErrorMessage(Translations.LastNameMustBeDefined));
                return resolve(false);
            }
            if (employee.isNew()) {
                if (!state.username) {
                    store.customStore.dispatch(storeActions.showErrorMessage(Translations.UsernameMustBeDefined));
                    return resolve(false);
                }
                if (!state.password) {
                    store.customStore.dispatch(storeActions.showErrorMessage(Translations.PasswordMustBeDefined));
                    return resolve(false);
                }
                if (!Base.isValidUsername(state.username)) {
                    store.customStore.dispatch(storeActions.showErrorMessage(Translations.UsernameMustMeetComplexityRequirements));
                    return resolve(false);
                }
                if (!Base.isValidPassword(state.password)) {
                    store.customStore.dispatch(storeActions.showErrorMessage(Translations.PasswordMustMeetComplexityRequirements));
                    return resolve(false);
                }
                if (state.password !== state.passwordConfirmation) {
                    store.customStore.dispatch(storeActions.showErrorMessage(Translations.PasswordConfirmationDoesNotMatchPassword));
                    return resolve(false);
                }
            }
            return resolve(true);
        });
    };

    private static getWarningMessage = (state: ISettingsEmployeeDialogEditViewState): Promise<string> => {
        return new Promise<string>((resolve) => {
            //if (!state.vehicleId || state.vehicleId === Base.emptyGuid) {
            //    return resolve(Translations.EmployeeWhenVehicleIsNotDefinedEmployeeCannotMakeRideBookings + Base.lf);
            //}
            return resolve("");
        });
    };

    private static validate = (props: ISettingsEmployeeDialogEditViewProp, state: ISettingsEmployeeDialogEditViewState, saveCallback: () => void): Promise<void> => {
        return SettingsEmployeeDialogEditView.checkErrors(props, state).then(success => {
            if (success) {
                return SettingsEmployeeDialogEditView.getWarningMessage(state).then(warnings => {
                    if (!warnings) {
                        saveCallback();
                        return new Promise<void>((resolve) => { resolve(); });
                    }
                    return new Promise<void>((resolve) => {
                        store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, warnings + Base.lf + Translations.DoYouReallyWantToSaveData,
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                saveCallback();
                                resolve();
                            },
                            () => {
                                store.customStore.dispatch(storeActions.clearConfirmation());
                                resolve();
                            }, null));
                    });
                });
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        });
    };

    private static getSaveDataFromState = (props: ISettingsEmployeeDialogEditViewProp, state: ISettingsEmployeeDialogEditViewState): SaveData => {
        const data = new SaveData();
        const employee = props.employeeEdit.employee;
        let oldDocuments = employee.documents.slice(0);
        for (let i = 0; i < employee.competencies.length; i++) {
            oldDocuments = oldDocuments.concat(employee.competencies[i].documents);
        }
        // Common
        data.append("id", employee.id);
        data.append("rowId", state.rowId);
        // General
        data.append("firstName", state.firstName);
        data.append("lastName", state.lastName);
        data.append("abbreviation", state.abbreviation);
        data.append("phone", state.phone);
        data.append("email", state.email);
        data.append("erp", state.erp);
        data.append("employeeGroupId", state.employeeGroupId);
        data.append("salaryEmployeeGroupId", state.salaryEmployeeGroupId);
        data.append("vehicleId", state.vehicleId !== Base.emptyGuid ? state.vehicleId : String(null));
        data.append("costCenterId", state.costCenterId !== Base.emptyGuid ? state.costCenterId : String(null));
        data.append("userGroupId", state.userGroupId);
        data.append("salaryTypeId", state.salaryTypeId);
        data.append("postalCodeRangeStart", state.postalCodeRangeStart);
        data.append("postalCodeRangeEnd", state.postalCodeRangeEnd);
        data.append("mapOnId", state.mapOnId.toString());
        data.append("tachoCardId", state.tachoCardId.replace(/\s/g, ""));
        if (employee.isNew()) {
            data.append("username", state.username);
            data.append("password", state.password);
        }
        data.append("activeState", state.activeState.toString(10));
        data.append("workTimeAccountInUse", state.workTimeAccountInUse.toString());
        if (!Base.isNullOrUndefined(state.pictureFile)) {
            data.append("picture[]", state.pictureFile, state.pictureFile.name);
        }
        data.append("removePicture", state.removePicture ? "1" : "0");
        // Competencies
        data.append("competencies", JSON.stringify(state.competencies.reduce((result, i) => {
            if (state.savedEmployeeCompetencyIds.indexOf(i.id) >= 0) {
                result.push(new EmployeeEditItemSaveEmployeeCompetencyItem(i));
            }
            return result;
        }, [])));
        data.append("removedEmployeeCompetencyIds", JSON.stringify(state.removedEmployeeCompetencyIds));
        const documentUpdates: IDocumentInfoSave[] = [];
        const employeeCompetencyDocumentAdds: IDocumentInfoSave[] = [];
        const employeeCompetencyDocumentIds: string[] = [];
        for (let i = 0; i < state.competencies.length; i++) {
            const competency = state.competencies[i];
            if (state.savedEmployeeCompetencyIds.indexOf(competency.id) < 0) continue;
            for (let j = 0; j < competency.documents.length; j++) {
                const document = competency.documents[j];
                const file = document.file;
                const isOld = Base.isNullOrUndefined(file);
                if (isOld) {
                    const oldDocument = oldDocuments.find(k => k.id === document.id);
                    if (!oldDocument || oldDocument.comment === document.comment) continue;
                    const documentSave = new DocumentInfoSave(document);
                    documentSave.relatedObjectId = competency.id;
                    documentUpdates.push(documentSave);
                } else {
                    data.append("employeeCompetencyDocuments[]", file, file.name);
                    employeeCompetencyDocumentIds.push(competency.id);
                    const documentAdd = new DocumentInfoSave(document);
                    documentAdd.relatedObjectId = competency.id;
                    employeeCompetencyDocumentAdds.push(documentAdd);
                }
            }
        }
        data.append("employeeCompetencyDocumentIds", JSON.stringify(employeeCompetencyDocumentIds));
        data.append("employeeCompetencyDocumentAdds", JSON.stringify(employeeCompetencyDocumentAdds));
        // Documents
        const documentAdds: IDocumentInfoSave[] = [];
        for (let i = 0; i < state.documents.length; i++) {
            const document = state.documents[i];
            const file = document.file;
            const isOld = Base.isNullOrUndefined(file);
            if (isOld) {
                const oldDocument = oldDocuments.find(j => j.id === document.id);
                if (!oldDocument || oldDocument.comment === document.comment) continue;
                const documentSave = new DocumentInfoSave(document);
                documentSave.relatedObjectId = employee.id;
                documentUpdates.push(documentSave);
            } else {
                data.append("documents[]", file, file.name);
                const documentAdd = new DocumentInfoSave(document);
                documentAdd.relatedObjectId = employee.id;
                documentAdds.push(documentAdd);
            }
        }
        data.append("removedDocumentIds", JSON.stringify(state.removedDocumentIds));
        data.append("documentAdds", JSON.stringify(documentAdds));
        data.append("documentUpdates", JSON.stringify(documentUpdates));
        // SsoIntegrations
        data.append("ssoIntegrations", JSON.stringify(props.ssoIntegrations.reduce((result, i) => {
            result.push(new EmployeeEditItemSaveSsoIntegration(i));
            return result;
        }, [])));
        return data;
    };

    saveEmployee = (afterSaveCallback: (saved: boolean, employeeId: string) => void = null) => {
        SettingsEmployeeDialogEditView.validate(this.props, this.state, () => {
            const saveData = SettingsEmployeeDialogEditView.getSaveDataFromState(this.props, this.state);
            if (Base.isNullOrUndefined(saveData)) return;
            // Call server
            store.customStore.dispatch(storeActions.fetchStart());
            employeeService.saveEmployeeEdit(saveData.formData)
                .then(success => {
                    store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                    tokenService.refreshAuthentication(true);
                    afterSaveCallback(true, success.id);
                })
                .catch(error => {
                    store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                })
                .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
        });
    };

    handleChangePasswordDialogOk = (rowId: string) => {
        this.setState({
            rowId: rowId,
            showChangePasswordDialog: false
        });
    };

    handleChangePasswordDialogCancel = () => {
        this.setState({
            showChangePasswordDialog: false
        });
    };

    handleChangeUsernameDialogOk = (username: string, rowId: string) => {
        this.setState({
            rowId: rowId,
            username: username,
            showChangeUsernameDialog: false
        });
    };

    handleChangeUsernameDialogCancel = () => {
        this.setState({
            showChangeUsernameDialog: false
        });
    };

    private cancelEmployeeEditSub = (onCancelEditCallback: (saved: boolean, employeeId: string) => void) => {
        const obj = this;
        const saveData = SettingsEmployeeDialogEditView.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsEmployeeDialogEditView.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEmployee(onCancelEditCallback);
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    onCancelEditCallback(false, obj.props.employeeEdit.employee.id);
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            onCancelEditCallback(false, obj.props.employeeEdit.employee.id);
        }
    };

    cancelEmployeeEdit = (onCancelEditCallback: (saved: boolean, employeeId: string) => void) => {
        const obj = this;
        obj.cancelEmployeeEditSub(onCancelEditCallback);
    };

    render() {
        const props = this.props;
        const state = this.state;
        const employeeEdit = this.props.employeeEdit;
        const employee = employeeEdit.employee;

        return (
            <div>
                <div className="row">
                    <div className="sideMenu col-3">
                        <div className="scrollSpyArea">
                            <ScrollSpy
                                dataContainer={this.employeeContentAreaDiv}
                                items={state.scrollSpyItems}
                                offset={0}
                            />
                        </div>
                    </div>
                    <div className="employeeContentArea employeeContainer col-9">
                        <div className="row">
                            <div id="general" className="col-3">
                                <ImageSelector
                                    fileInputName="picture"
                                    image={state.picture}
                                    aspectRatio={null}
                                    onChange={this.handleSetPicture}
                                    onClear={this.handleRemovePicture}
                                    onError={(message) => { store.customStore.dispatch(storeActions.showErrorMessage(message)); }}
                                />
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-2">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.Number}</label>
                                            <input type="text" className="form-control" name="number" title={Translations.Number} value={employee.isNew() ? Translations.New : employee.number.toString(10)} readOnly={true} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.FirstName}</label>
                                            <input type="text" className="form-control" name="FNm" title={Translations.FirstName} value={state.firstName} onChange={this.handleChange} maxLength={50} autoFocus={true} />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.LastName}</label>
                                            <input type="text" className="form-control" name="LNm" title={Translations.LastName} value={state.lastName} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.Abbreviation}</label>
                                            <input type="text" className="form-control" name="abbreviation" title={Translations.Abbreviation} value={state.abbreviation} onChange={this.handleChange} maxLength={3} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.UserGroup}</label>
                                            <select className="form-control" name="userGroupId" title={Translations.UserGroup} value={state.userGroupId} onChange={this.handleChange} disabled={employeeEdit.editorIsEmployee}>
                                                {props.userGroups.map((userGroup) =>
                                                    <option key={userGroup.id} value={userGroup.id}>{userGroup.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.Username}</label>
                                            {employee.isNew() &&
                                                <input type="text" className="form-control" name="uNm" title={Translations.Username} value={state.username} onChange={this.handleChange} maxLength={50} />
                                            }
                                            {!employee.isNew() &&
                                                <div className="input-group">
                                                    <input type="text" className="form-control" title={Translations.Username} readOnly={true} disabled={true} value={state.username} />
                                                    <span className="input-group-append">
                                                        <Button
                                                            title={Translations.Change}
                                                            classes={""}
                                                            enabled={true}
                                                            onClick={this.handleChangeUsername}
                                                        />
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.Password}</label>
                                            {employee.isNew() &&
                                                <input type="text" className="form-control passwordFont" name="password" title={Translations.Password} value={state.password} onChange={this.handleChange} maxLength={50} />
                                            }
                                            {!employee.isNew() &&
                                                <div>
                                                    <Button
                                                        classes="btn-default changePassword"
                                                        title={Translations.ChangePassword}
                                                        enabled={true}
                                                        onClick={(index?: number) => { this.handleChangePassword(); }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {employee.isNew() &&
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.PasswordConfirmation}</label>
                                                <input type="text" className={"form-control passwordFont" + (state.password !== state.passwordConfirmation ? " error" : "")} name="passwordConfirmation" title={Translations.PasswordConfirmation} value={state.passwordConfirmation} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="control-label smallFont">&nbsp;</label>
                                            <div>
                                                <CheckBox
                                                    title={Translations.InUse}
                                                    enabled={true}
                                                    checked={state.activeState > 0}
                                                    onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.EmployeeGroupForDesign}</label>
                                            <select className="form-control" name="employeeGroupId" title={Translations.EmployeeGroupForDesign} value={state.employeeGroupId} onChange={this.handleChange}>
                                                {employeeEdit.employeeGroups.map((employeeGroup) =>
                                                    <option key={employeeGroup.id} value={employeeGroup.id}>{employeeGroup.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.EmployeeGroupForSalaryCalculation}</label>
                                            <select className="form-control" name="salaryEmployeeGroupId" title={Translations.EmployeeGroupForSalaryCalculation} value={state.salaryEmployeeGroupId} onChange={this.handleChange}>
                                                {employeeEdit.employeeGroups.filter(i => !!i.salaryPeriodTypeId).map((employeeGroup) =>
                                                    <option key={employeeGroup.id} value={employeeGroup.id}>{employeeGroup.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group required">
                                            <label className="control-label smallFont">{Translations.SalaryType}</label>
                                            <select className="form-control" name="salaryTypeId" title={Translations.SalaryType} value={state.salaryTypeId} onChange={this.handleChange}>
                                                {employeeEdit.salaryTypes.map((salaryType) =>
                                                    <option key={salaryType.id} value={salaryType.id}>{salaryType.title}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.Phone}</label>
                                            <input type="text" className="form-control" name="Phn" title={Translations.Phone} value={state.phone} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.Email}</label>
                                            <input type="text" className="form-control" name="Eml" title={Translations.Email} value={state.email} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{t("employee.maponId")}</label>
                                            <input type="number" className="form-control" name="maponid" title={t("employee.maponId")} value={state.mapOnId} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{t("employee.tachoCardId")}</label>
                                            <input type="text" className="form-control" name="tachoCardId" title={t("employee.tachoCardId")} value={state.tachoCardId} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.Vehicle}</label>
                                            <select className="form-control" name="vehicleId" title={Translations.Vehicle} value={state.vehicleId} onChange={this.handleChange}>
                                                {state.vehicles.map((vehicle) =>
                                                    <option key={vehicle.id} value={vehicle.id}>{vehicle.getTitle()}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.ErpReference}</label>
                                            <input type="text" className="form-control" name="erp" title={Translations.ErpReference} value={state.erp} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.CostCenter}</label>
                                            <select className="form-control" name="costCenterId" title={Translations.CostCenter} value={state.costCenterId} onChange={this.handleChange}>
                                                {state.costCenters.map((costCenter) =>
                                                    <option key={costCenter.id} value={costCenter.id}>{costCenter.title}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">&nbsp;</label>
                                            <div>
                                                <CheckBox
                                                    title={Translations.WorkhourBankInUse}
                                                    enabled={true}
                                                    checked={state.workTimeAccountInUse}
                                                    onCheckboxClickBoolean={this.handleWorkTimeInUseChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.WorkQueuePostalCodeRangeStart}</label>
                                            <input type="text" className="form-control" name="PostalCodeRangeFrom" title={Translations.WorkQueuePostalCodeRangeStart} value={state.postalCodeRangeStart} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="control-label smallFont">{Translations.WorkQueuePostalCodeRangeEnd}</label>
                                            <input type="text" className="form-control" name="PostalCodeRangeTo" title={Translations.WorkQueuePostalCodeRangeEnd} value={state.postalCodeRangeEnd} onChange={this.handleChange} maxLength={50} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="employeeGroups">
                            <SettingsEmployeeDialogEmployeeGroupList
                                title={Translations.SecondaryEmployeeGroups}
                                employeeId={employee.id}
                                reservedEmployeeGroupIds={[state.employeeGroupId, state.salaryEmployeeGroupId]}
                                readOnly={employee.isNew()}
                                items={props.employeeEmployeeGroups}
                                onRemoveEmployeeGroup={props.onEmployeeEmployeeGroupRemoved}
                                onAddEmployeeGroup={props.onAddEmployeeEmployeeGroup}
                            />
                        </div>
                        <div id="dayBookings">
                            <SettingsEmployeeDialogDayBookingList
                                title={Translations.DayBookings}
                                isReadOnly={employee.isNew()}
                                employeeId={employee.id}
                                dayBookingTypes={employeeEdit.dayBookingTypes}
                                items={props.dayBookings}
                                onDayBookingRemoved={props.onDayBookingRemoved}
                                onAddDayBooking={props.onAddDayBooking}
                                onEditDayBooking={props.onEditDayBooking}
                            />
                        </div>
                        {state.workTimeAccountInUse &&
                            <div id="workHourTransactions">
                                <SettingsEmployeeWorkHourAccountTransactions
                                    title={Translations.WorkhourBank + ", " + Translations.WorkHourBalance + " " + props.workHourBalance}
                                    items={props.workHourTransactions}
                                />
                            </div>
                        }
                        <div id="competencies">
                            <SettingsEmployeeDialogEmployeeCompetencyList
                                title={Translations.Competencies}
                                competencies={employeeEdit.competencies}
                                items={state.competencies}
                                competencyWarningLimitInDays={employeeEdit.competencyWarningLimitInDays}
                                onRemoveEmployeeCompetency={this.handleEmployeeCompetencyRemove}
                                onAddEmployeeCompetency={this.handleEmployeeCompetencyAdd}
                                onEditEmployeeCompetency={this.handleEmployeeCompetencyEdit}
                            />
                        </div>
                        <div id="documents">
                            <DocumentList
                                title={Translations.Documents}
                                relatedObjectId={employee.id}
                                documents={state.documents}
                                isReadOnly={false}
                                canShowCamera={false}
                                changeDocumentTypeEnabled={true}
                                onEditDocument={this.handleDocumentEdit}
                                onRemoveDocument={this.handleDocumentRemove}
                                onDownloadDocument={this.handleDocumentDownload}
                                onAddDocuments={this.handleDocumentsAdd}
                                onAddPhoto={null}
                            />
                        </div>
                        <div id="integrations">
                            <SettingsEmployeeDialogSsoIntegrationList
                                title={Translations.SsoIntegrations}
                                isReadOnly={employee.isNew()}
                                items={props.ssoIntegrations}
                                onEditSsoIntegration={props.onEditSsoIntegration}
                            />
                        </div>
                    </div>
                </div>
                {state.showChangePasswordDialog &&
                    <SettingsChangePasswordDialog
                        employeeId={employee.id}
                        employeeRowId={state.rowId}
                        adminMode={true}
                        onOk={this.handleChangePasswordDialogOk}
                        onCancel={this.handleChangePasswordDialogCancel}
                    />
                }
                {state.showChangeUsernameDialog &&
                    <SettingsEmployeeDialogChangeUsernameDialog
                        employeeId={employee.id}
                        employeeRowId={state.rowId}
                        username={employee.username}
                        onOk={this.handleChangeUsernameDialogOk}
                        onCancel={this.handleChangeUsernameDialogCancel}
                    />
                }
            </div>
        );
    }
}
