import React from "react";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import VehicleCard from "./vehicleCard/vehicleCard";
import { SidebarProps, VehicleData } from "../../models/monitorView/monitorViewTypes";

export const MonitorViewVehiclesSidebar = ({
    data,
    selectedId: selectedVehicleId,
    setSelectedId: setSelectedVehicleId,
}: SidebarProps<VehicleData>) => {
    const { t } = useTranslation();

    return (
        data?.length > 0 ? (
            data.map((v) => (
                <VehicleCard
                    key={v.vehicleId}
                    vehicle={v}
                    driver={v?.employeeName}
                    isSelected={v.vehicleId === selectedVehicleId}
                    onClick={() => setSelectedVehicleId(v.vehicleId)}
                />
            ))
        ) : (
            <Alert variant="standard" severity="info">
                {t("vehicle.noRows")}
            </Alert>
        )
    );
};