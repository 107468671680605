// -----------------------------------------------------------------
// !!! THIS FILE IS AUTOMATICALLY GENERATED DO NOT EDIT MANUALLY !!!
// -----------------------------------------------------------------
export class Translations_en_US {
    static ApplicationName = 'EasyOpp työnohjaus';
    static ArgumentParameterIsNULL = 'Value of the argument \'{0}\' is NULL!';
    static Cancel = 'Cancel';
    static ClassParameterHasNotBeenInitialized = 'Class \'{0}\' has not bee initialized!';
    static EmployeeParameterCodeIsAlreadyInUse = 'Employee option code is already in use!';
    static EmployeeParameterParameterSavedSuccessfully = 'Employee option \'{0}\' saved successfully!';
    static EmployeeParameterParameterSaveFailedConcurrencyError = 'Failed to save employee option \'{0}\', because data in the employee option has been modified concurrently by another user!';
    static EmployeeParameterParameterSaveFailedErrorParameter = 'Failed to save employee option \'{0}\'! Error: \'{1}\'';
    static Maintenance = 'Maintenance';
    static No = 'No';
    static Password = 'Password';
    static PermissionDeniedForOperation = 'Permission denied for operation!';
    static PlannedWorks = 'Planned Works';
    static Planning = 'Planning';
    static Reporting = 'Reporting';
    static SignIn = 'Sign In';
    static SignOut = 'Sign Out';
    static ToBeCheckedWorks = 'To Be Checked Works';
    static ToBeInvoicedWorks = 'To Be Invoiced Works';
    static UserClaimParameterNotFound = 'User claim \'{0}\' not found!';
    static UserClaimsNotFound = 'User claims not found!';
    static Username = 'Username';
    static WaitingWorks = 'Waiting Works';
    static Yes = 'Yes';
    static Employee = 'Employee';
    static InvalidUsernameOrPassword = 'Invalid username or password!';
    static PasswordIsTooShort = 'Password is too short!';
    static PasswordMustContainDigitOrSpecialCharacter = 'Password must contain number or special character!';
    static PasswordMustContainLowercaseChar = 'Password must contain lowercase alphabet!';
    static PasswordMustContainUppercaseChar = 'Password must contain uppercase alphabet!';
    static TheSystemDoesNotSupportYourWebBrowserUpdateToLatestBrowserVersion = 'This system does not support your web browser. Update your web browser to latest version.';
    static Week = 'Week';
    static WorkPlan = 'Work Plan';
    static Actions = 'Actions';
    static Copy = 'Copy';
    static Edit = 'Edit';
    static FindWeek = 'Find Week';
    static New = 'New';
    static NextWeek = 'Next Week';
    static PreviousWeek = 'Previous Week';
    static Remove = 'Remove';
    static Close = 'Close';
    static Contact = 'Contact';
    static Duration = 'Duration';
    static OK = 'OK';
    static Site = 'Site';
    static StartDate = 'Start Date';
    static State = 'State';
    static WorkDescription = 'Work Description';
    static WorkOrder = 'Work Order';
    static WorkOrderBookingsTab = 'Records';
    static WorkOrderDocumentsTab = 'Attachments';
    static WorkOrderGeneralTab = 'General';
    static WorkOrderType = 'Work Order Type';
    static Clear = 'Clear';
    static MeasureUnitKg = 'kg';
    static MeasureUnitL = 'l';
    static MeasureUnitM = 'm';
    static MeasureUnitPcs = 'pcs';
    static WorkOrderParameterNotFound = 'Work order \'{0}\' was not found!';
    static WorkOrderStateChecked = 'Checked';
    static WorkOrderStateDone = 'Done';
    static WorkOrderStateInProgress = 'In Progress';
    static WorkOrderStatePlanned = 'Planned';
    static WorkOrderStatePreliminary = 'Preliminary';
    static WorkOrderStateTransferred = 'Transferred';
    static Add = 'Add';
    static AddPhoto = 'Add Photo';
    static Amount = 'Amount';
    static AmountH = 'Amount (h)';
    static AmountKm = 'Amount (km)';
    static Attachments = 'Attachments';
    static Change = 'Change';
    static Comment = 'Comment';
    static Cost = 'Cost';
    static Customer = 'Customer';
    static Date = 'Date';
    static DocumentIsEmpty = 'Document is empty!';
    static DocumentParameterNotFound = 'Document \'{0}\' was not found!';
    static Download = 'Download';
    static EndDate = 'End Date';
    static HourBooking = 'Hour Booking';
    static HourBookings = 'Hour Bookings';
    static ProductBooking = 'ProductBooking';
    static ProductBookings = 'Products';
    static Product = 'Product';
    static MeasureUnitH = 'h';
    static MeasureUnitKm = 'km';
    static RideBooking = 'Transportation';
    static RideBookings = 'Transportations';
    static Save = 'Save';
    static SelectCustomer = 'Select Customer';
    static UnitPrice = 'Unit Price';
    static Unnamed = 'Unnamed';
    static Vehicle = 'Equipment';
    static DecreaseState = 'Decrease  State';
    static EmployeesMustBeDefined = 'Employees must be defined!';
    static EndDateMustBeDefined = 'End time must be defined!';
    static HourBookingParameterNotFound = 'Hour booking \'{0}\' was not found!';
    static IncreaseState = 'Increase State';
    static ProductBookingParameterNotFound = 'Product booking \'{0}\' was not found!';
    static NotImplemented = 'Not implemented!';
    static RideBookingParameterNotFound = 'Transportation \'{0}\' was not found!';
    static SelectDate = 'Select Date';
    static StartDateMustBeDefined = 'Start time must be defined!';
    static WorkOrderSaveFailedConcurrencyError = 'Failed to save work order \'{0}\', because the work order has been modified concurrently by another user!';
    static WorkOrderSaveFailedErrorParameter = 'Failed to save work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderSaveSuccess = 'Work order \'{0}\' saved successfully!';
    static WorkOrderCannotBeModifiedInThisState = 'Work order cannot be modified in this state!';
    static FinishWork = 'Finish Working';
    static StartWork = 'Start Working';
    static WorkOrderCannotCrossWeekBoundary = 'Work order cannot cross week boundary!';
    static WorkOrders = 'Work Orders';
    static MeasureUnitD = 'd';
    static StartDateAndDuration = 'Start Date and Duration';
    static Warning = 'Warning';
    static YouHaveNotSavedChangesDoYouWantToSaveChanges = 'You have not saved changes that you have made. Do you want to save changes?';
    static Loading = 'Loading...';
    static Photo = 'Photo';
    static AddToHomeScreen = 'Add to Home Screen';
    static DoYouWantToAddThisApplicationToHomeScreen = 'Do you want to add this application to home screen?';
    static NewVersionAvailable = 'New application version available!';
    static Update = 'Update';
    static Contacts = 'Contacts';
    static CustomerParameterNotFound = 'Customer \'{0}\' was not found!';
    static Customers = 'Customers';
    static CustomerSaveFailedConcurrencyError = 'Failed to save customer \'{0}\', because the customer has been modified concurrently by another user!';
    static CustomerSaveFailedErrorParameter = 'Failed to save customer \'{0}\'! Error: \'{1}\'';
    static CustomerSaveSuccess = 'Customer \'{0}\' saved successfully!';
    static Employees = 'Employees';
    static ErpReference = 'ERP Reference';
    static General = 'General';
    static Products = 'Products';
    static Name = 'Name';
    static NameMustBeDefined = 'Name must be defined!';
    static Next = 'Next';
    static Previous = 'Previous';
    static Receipts = 'Receipts';
    static Search = 'Search';
    static SearchCondition = 'Search';
    static Sites = 'Sites';
    static Tools = 'Tools';
    static Vehicles = 'Equipments';
    static WorkOrderTypes = 'Work Order Types';
    static EventLogMessageWorkOrderCreated = 'Work order created';
    static EventLogMessageWorkOrderRemoved = 'Work order removed';
    static EventLogMessageWorkOrderStateChanged = 'Work order state changed: \'{0}\' => \'{1}\'';
    static Events = 'Events';
    static WorkOrderEventsTab = 'Events';
    static WorkOrderRemoveConfirmation = 'Do you really want to remove worker order \'{0}\'?';
    static WorkOrderRemoveFailedConcurrencyError = 'Failed to remove work order \'{0}\', because the work order has been modified concurrently by another user!';
    static WorkOrderRemoveFailedErrorParameter = 'Failed to remove work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderRemoveSuccess = 'Work order \'{0}\' removed successfully!';
    static CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Customer \'{0}\' cannot be removed, because the customer is used in {1} work orders!';
    static CustomerRemoveConfirmation = 'Do you really want to remove customer \'{0}\'?';
    static CustomerRemoveFailedConcurrencyError = 'Failed to remove customer \'{0}\', because the customer has been modified concurrently by another user!';
    static CustomerRemoveFailedErrorParameter = 'Failed to remove customer \'{0}\'! Error: \'{1}\'';
    static CustomerRemoveSuccess = 'Customer \'{0}\' removed successfully!';
    static EmployeeRemoveConfirmation = 'Do you really want to remove employee \'{0}\'?';
    static EventLogCustomerRemoved = 'Customer removed';
    static EventLogMessageCustomerCreated = 'Customer created';
    static InUse = 'In Use';
    static Number = 'Number';
    static ChangePassword = 'Change Password';
    static DayBookings = 'Day Bookings';
    static Email = 'Email';
    static EmployeeParameterNotFound = 'Employee \'{0}\' was not found!';
    static FirstName = 'First Name';
    static FirstNameMustBeDefined = 'First name must be defined!';
    static LastName = 'Last Name';
    static LastNameMustBeDefined = 'Last name must be defined!';
    static Phone = 'Phone';
    static UserGroup = 'User Group';
    static ChangePasswordFailedConcurrencyError = 'Failed to change employee \'{0}\' password, because the employee has been modified concurrently by another user!';
    static ChangePasswordFailedErrorParameter = 'Failed to change employee \'{0}\' password! Error: \'{1}\'';
    static ChangePasswordSuccess = 'Employee \'{0}\' password changed successfully!';
    static ChangeUsername = 'Change Username';
    static ChangeUsernameFailedConcurrencyError = 'Failed to change employee \'{0}\' username, because the employee has been modified concurrently by another user!';
    static ChangeUsernameFailedErrorParameter = 'Failed to change employee \'{0}\' username! Error: \'{1}\'';
    static ChangeUsernameSuccess = 'Employee \'{0}\' username changed successfully!';
    static CreditCardNumber = 'Credit Card Number';
    static CurrentPasswordIsIncorrect = 'Current password is incorrect!';
    static DayBooking = 'Day Booking';
    static DayBookingParameterNotFound = 'Day booking \'{0}\' was not found!';
    static DayBookingType = 'Day Booking Type';
    static Documents = 'Documents';
    static EmployeeGroup = 'Employee Group';
    static EmployeeSaveFailedConcurrencyError = 'Failed to save employee \'{0}\', because the employee has been modified concurrently by another user!';
    static EmployeeSaveFailedErrorParameter = 'Failed to save employee \'{0}\'! Error: \'{1}\'';
    static EmployeeSaveSuccess = 'Employee \'{0}\' saved successfully!';
    static EventLogMessageEmployeeCreated = 'Employee created';
    static NewPassword = 'Uusi Password';
    static NewPasswordConfirmation = 'New Password Confirmation';
    static NewUsername = 'New Username';
    static PasswordConfirmation = 'Password Confirmation';
    static PasswordConfirmationDoesNotMatchPassword = 'Password confirmation does not match the password!';
    static PasswordMustBeDefined = 'Password must be defined!';
    static PasswordMustMeetComplexityRequirements = 'Password must be at least 8 characters long and it must contain lowercase letters, capital letters and at least one number or special character!';
    static UsernameIsAlreadyInUse = 'Username is already in use!';
    static UsernameMustBeDefined = 'Username must be defined!';
    static UsernameMustMeetComplexityRequirements = 'Username must be at least 8 characters long!';
    static CityAddress = 'City';
    static ContactParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Contact \'{0}\' cannot be removed, because the contact is used in {1} work orders!';
    static ContactParameterNotFound = 'Contact \'{0}\' was not found!';
    static ContactRemoveConfirmation = 'Do you really want to remove contact \'{0}\'?';
    static ContactRemoveFailedConcurrencyError = 'Failed to remove contact \'{0}\', because the contact has been modified concurrently by another user!';
    static ContactRemoveFailedErrorParameter = 'Failed to remove contact \'{0}\'! Error: \'{1}\'';
    static ContactRemoveSuccess = 'Contact \'{0}\' removed successfully!';
    static ContactSaveFailedConcurrencyError = 'Failed to save contact \'{0}\', because the contact has been modified concurrently by another user!';
    static ContactSaveFailedErrorParameter = 'Failed to save contact \'{0}\'! Error: \'{1}\'';
    static ContactSaveSuccess = 'Contact \'{0}\' saved successfully!';
    static CustomerSiteNumber = 'Customer Site Number';
    static Distance = 'Distance (km)';
    static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Employee \'{0}\' cannot be removed, because the employee is used in {1} work orders!';
    static EmployeeRemoveFailedConcurrencyError = 'Failed to remove employee \'{0}\', because the employee has been modified concurrently by another user!';
    static EmployeeRemoveFailedErrorParameter = 'Failed to remove employee \'{0}\'! Error: \'{1}\'';
    static EmployeeRemoveSuccess = 'Employee \'{0}\' removed successfully!';
    static MapLink = 'Map Link';
    static PostalCode = 'Postal Code';
    static SelectContact = 'Select Contact';
    static SiteParameterNotFound = 'Site \'{0}\' was not found!';
    static Specifier = 'Specifier';
    static StreetAddress = 'Street Address';
    static Brand = 'Brand';
    static Description = 'Description';
    static RegisterNumber = 'Plate Number';
    static RegisterNumberMustBeDefined = 'Plate number must be defined!';
    static RideBookingRequired = 'Ride Booking Required';
    static SelectEmployee = 'Select Employee';
    static SelectTool = 'Select Tool';
    static Tool = 'Tool';
    static ToolParameterNotFound = 'Tool \'{0}\' was not found!';
    static ToolRemoveConfirmation = 'Do you really want to remove tool \'{0}\'?';
    static ToolRemoveFailedConcurrencyError = 'Failed to remove tool \'{0}\', because the tool has been modified concurrently by another user!';
    static ToolRemoveFailedErrorParameter = 'Failed to remove tool \'{0}\'! Error: \'{1}\'';
    static ToolRemoveSuccess = 'Tool \'{0}\' removed successfully!';
    static ToolSaveFailedConcurrencyError = 'Failed to save tool \'{0}\', because the tool has been modified concurrently by another user!';
    static ToolSaveFailedErrorParameter = 'Failed to save tool \'{0}\'! Error: \'{1}\'';
    static ToolSaveSuccess = 'Tool \'{0}\' saved successfully!';
    static VehicleParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Equipment \'{0}\' cannot be removed, because the equipment is used in {1} work orders!';
    static VehicleParameterNotFound = 'Equipment \'{0}\' was not found!';
    static VehicleRemoveConfirmation = 'Do you really want to remove equipment \'{0}\'?';
    static VehicleRemoveFailedConcurrencyError = 'Failed to remove equipment \'{0}\', because the equipment has been modified concurrently by another user!';
    static VehicleRemoveFailedErrorParameter = 'Failed to remove equipment \'{0}\'! Error: \'{1}\'';
    static VehicleRemoveSuccess = 'Equipment \'{0}\' removed successfully!';
    static VehicleSaveFailedConcurrencyError = 'Failed to save equipment \'{0}\', because the equipment has been modified concurrently by another user!';
    static VehicleSaveFailedErrorParameter = 'Failed to save equipment \'{0}\'! Error: \'{1}\'';
    static VehicleSaveSuccess = 'Equipment \'{0}\' saved successfully!';
    static WorkDescriptionMustBeDefined = 'Work description must be defined!';
    static ProductParameterNotFound = 'Product \'{0}\' was not found!';
    static ProductRemoveConfirmation = 'Do you really want to remove product \'{0}\'?';
    static ProductRemoveFailedConcurrencyError = 'Failed to remove product \'{0}\', because the product has been modified concurrently by another user!';
    static ProductRemoveFailedErrorParameter = 'Failed to remove product \'{0}\'! Error: \'{1}\'';
    static ProductRemoveSuccess = 'Product \'{0}\' removed successfully!';
    static ProductSaveFailedConcurrencyError = 'Failed to save product \'{0}\', because the product has been modified concurrently by another user!';
    static ProductSaveFailedErrorParameter = 'Failed to save product \'{0}\'! Error: \'{1}\'';
    static ProductSaveSuccess = 'Product \'{0}\' saved successfully!';
    static MeasureUnit = 'Measure Unit';
    static PrivacyPolicy = 'Privacy Policy';
    static ContactIsNotDefined = 'Contact is not defined!';
    static DoYouReallyWantToSetWorkOrderDone = 'Do you really want to move work order to Done state?';
    static DoYouReallyWantToSetWorkOrderPlanned = 'Do you really want to move work order to Planned state?';
    static EmployeesHaveDayBookingsForWorkOrderTime = 'Work order overlaps with employees day bookings!';
    static HourBookingsMustBeDefined = 'Hour bookings must be defined!';
    static HourBookingsAreNotDefinedForAllWorkDays = 'Hour bookings are not defined for all work days!';
    static ProductBookingsAreNotDefined = 'Product bookings are not defined!';
    static RideBookingsMustBeDefined = 'Ride bookings must be defined!';
    static StartDateIsInThePast = 'Start time is in the past!';
    static MeasureUnitBtl = 'btl';
    static MeasureUnitM2 = 'm2';
    static MeasureUnitM3 = 'm3';
    static MeasureUnitPkg = 'pkg';
    static SiteMustBeDefined = 'Site must be defined!';
    static SiteSaveFailedConcurrencyError = 'Failed to save site \'{0}\', because the site has been modified concurrently by another user!';
    static SiteSaveFailedErrorParameter = 'Failed to save site \'{0}\'! Error: \'{1}\'';
    static SiteSaveSuccess = 'Site \'{0}\' saved successfully!';
    static SitesThatRelatedToWorkOrdersCannotBeRemoved = 'Sites that are used in work orders cannot be removed!';
    static NoWorkOrdersForThisDay = 'No work orders';
    static AttachmentRemoveConfirmation = 'Do you want to remove the attachment?';
    static HourBookingRemoveConfirmation = 'Do you want to remove the hour booking?';
    static ProductBookingRemoveConfirmation = 'Do you want to remove the product booking?';
    static RideBookingRemoveConfirmation = 'Do you want to remove the ride booking?';
    static Code = 'Code';
    static DateRangeEnd = 'Date Range End';
    static DateRangeStart = 'Date Range Start';
    static DocumentParameterGenerationFailedParameter = 'Failed to generate document \'{0}\'! Error: \'{1}\'';
    static EmployeeReportName = 'Employee weekly report';
    static Message = 'Message';
    static Print = 'Print';
    static SelectDateRange = 'Select Date Range';
    static WorkOrderAcknowledgementType = 'Work Order Acknowledgement Request';
    static DoYouWantToSendWorkOrderAcknowledgementToContact = 'Do you want to send work order acknowledgement request to contact person email address \'{0}\'?';
    static Information = 'Information';
    static AcknowledgementCannotBeSendBecauseValidEmailIsNotDefinedForContact = 'Acknowledgement request cannot be send because valid email is not defined for contact!';
    static EmailAddressParameterIsInvalid = 'Email address \'{0}\' is not valid!';
    static SendingEmailFailedErrorParameter = 'Sending email failed! Error: \'{0}\'';
    static AcknowledgementParameterNotFound = 'Acknowledgement \'{0}\' was not found, because it is expired!';
    static ApplicationTempPathIsNotSpecified = 'Application temp-folder is not specified!';
    static ApplicationTempPathParameterNotFound = 'Application temp-folder \'{0}\' not found!';
    static ApprovedToBeInvoiced = 'Approved to be invoiced';
    static ApproveToBeInvoiced = 'Approve to be invoiced';
    static CustomerView = 'Customer View';
    static LoadingAcknowledgement = 'Loading acknowledgement... Please wait.';
    static MessageContent = 'Message Content';
    static MessageContentMustBeDefined = 'Message content must be defined!';
    static MessageSendFailedErrorParameter = 'Failed to send message! Error: \'{0}\'';
    static MessageSendSuccess = 'Message sent successfully!';
    static MessageSubject = 'Message Subject';
    static MessageSubjectMustBeDefined = 'Message subject must be defined!';
    static MessageToAcknowledgementRequest = 'Message to acknowledgement request';
    static Send = 'Send';
    static Sender = 'Sender';
    static SendMessage = 'Send Message';
    static ThisBrowserDoesNotSupportPdfsPleaseDownloadPdfToViewIt = 'This browser does not support PDFs. Please download the PDF using Download button.';
    static AcknowledgementParameterHasAlreadyBeenApproved = 'Acknowledgement request \'{0}\' has already been approved!';
    static EventLogMessageWorkOrderApprovalRequest = 'Work order approval request sent';
    static EventLogMessageWorkOrderApproved = 'Work order approved';
    static EventLogSaveFailedErrorParameter = 'Failed to save event to log! Error: \'{0}\'';
    static EventLogSaveSuccess = 'Event saved successfully to log!';
    static WorkOrderAcknowledgementApprovalFailedConcurrencyError = 'Failed to approve acknowledgement request \'{0}\', because the acknowledgement request has been modified concurrently by another user!';
    static WorkOrderAcknowledgementApprovalFailedErrorParameter = 'Failed to approve acknowledgement request \'{0}\'! Error: \'{1}\'';
    static WorkOrderAcknowledgementApprovalSuccess = 'Acknowledgement request \'{0}\' approved successfully!';
    static AcknowledgementSentToAddress = 'Acknowledgement request sent to email address \'{0}\'?';
    static DoYouReallyWantToSaveData = 'Do you really want to save data?';
    static DoYouWantToMakeProductBookingsBeforehand = 'Do you really want to make product bookings beforehand?';
    static EmployeeWhenVehicleIsNotDefinedEmployeeCannotMakeRideBookings = 'If equipment is not defined for employee, the employee cannot make ride bookings.';
    static HourBookingAmountIsZero = 'Hour booking amount is zero.';
    static HourBookingCommentMustBeDefinedIfAmountIsZero = 'Hour booking comment must be defined if the amount is zero!';
    static ProductBookingProductMustBeDefined = 'Product must be defined for product booking!';
    static RideBookingVehicleMustBeDefined = 'Equipment must be defined for ride booking !';
    static Browser = 'Browser';
    static EntryType = 'Entry Type';
    static EventLog = 'Event Log';
    static IpAddress = 'IP Address';
    static LogEntryType = 'Type';
    static ModifyUserName = 'Modifier';
    static Open = 'Open';
    static Operation = 'Operation';
    static OperationLog = 'Operation Log';
    static OperationLogParameterNotFound = 'Operation log \'{0}\' was not found!';
    static Parameters = 'Parameters';
    static RelatedObjectType = 'Record Type';
    static SoftwareVersion = 'Software Version';
    static Time = 'Time';
    static Tracking = 'Tracking';
    static ContactWithEmailMustBeDefinedWhenAcknowledementTypeIsNotDoNotSend = 'Contact with email address must be defined for work order when acknowledgement type is not \'Älä lähetä\'!';
    static EmployeesThatHaveWorkedInSite = 'Employees that have worked in the site';
    static OpenMapLink = 'Open map link';
    static SiteFamiliarizationRequired = 'Familiarization Required';
    static SiteSamiliarizationType1Suffix = ' (ep)';
    static WorkOrderRemoveConfirmationAcknowledgementSent = 'Acknowledgement request has been sent for the work order. Do you really want to remove worker order \'{0}\'?';
    static CreateMapLink = 'Create Map Link';
    static ProductBookingAmountMustBeGreaterThanZero = 'Product booking amount must be greater than zero!';
    static NextVehicleInspection = 'Next Equipment Inspection';
    static ShowNotInUse = 'Show Not In Use';
    static Competencies = 'Competencies';
    static Competency = 'Competency';
    static CompetencyParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = 'Competency \'{0}\' cannot be removed, because the competency is used in {1} employees!';
    static CompetencyParameterNotFound = 'Competency \'{0}\' was not found!';
    static CompetencyRemoveConfirmation = 'Do you really want to remove competence \'{0}\'?';
    static CompetencyRemoveFailedConcurrencyError = 'Failed to remove competency \'{0}\', because the competency has been modified concurrently by another user!';
    static CompetencyRemoveFailedErrorParameter = 'Failed to remove competency \'{0}\'! Error: \'{1}\'';
    static CompetencyRemoveSuccess = 'Competency \'{0}\' removed successfully!';
    static CompetencySaveFailedConcurrencyError = 'Failed to save competency \'{0}\', because the competency has been modified concurrently by another user!';
    static CompetencySaveFailedErrorParameter = 'Failed to save competency \'{0}\'! Error: \'{1}\'';
    static CompetencySaveSuccess = 'Competency \'{0}\' saved successfully!';
    static CompetencyType = 'Competency Type';
    static CompetencyTypesNotFound = 'Competency types not found!';
    static CompetenciesNotFound = 'Competencies not found!';
    static EmployeeCompetencies = 'Employee Competencies';
    static EmployeeCompetencyParameterNotFound = 'Employee competency \'{0}\' was not found!';
    static EmployeeCompetencyRemoveConfirmation = 'Do you really want to remove competency \'{1}\' from employee \'{0}\'?';
    static EmployeeCompetencyRemoveFailedConcurrencyError = 'Failed to remove competency \'{1}\' from employee \'{0}\', because the data has been modified concurrently by another user!';
    static EmployeeCompetencyRemoveFailedErrorParameter = 'Failed to remove competency \'{1}\' from employee \'{0}\'! Error: \'{1}\'';
    static EmployeeCompetencyRemoveSuccess = 'Competency \'{1}\' removed from employee \'{0}\' successfully!';
    static EmployeeCompetencySaveFailedConcurrencyError = 'Failed to save competency \'{1}\' to employee \'{0}\', because the data has been modified concurrently by another user!';
    static EmployeeCompetencySaveFailedErrorParameter = 'Failed to save competency \'{1}\' to employee \'{0}\'! Error: \'{1}\'';
    static EmployeeCompetencySaveSuccess = 'Competency \'{1}\' saved to employee \'{0}\' successfully!';
    static EmployeesNotFound = 'Employees not found!';
    static ExpirationDate = 'Expiration Date';
    static GrantDate = 'Grant Date';
    static ExpirationDateAbrv = 'Expires';
    static GrantDateAbrv = 'Granted';
    static AllStates = 'All States';
    static CreateInvoiceXmlFailedErrorParameter = 'Failed to create invoice file! Error: \'{0}\'';
    static CreateInvoiceXmlSuccess = 'Invoice file created successfully!';
    static DownloadInvoiceFile = 'Download Invoice File';
    static SetWorkOrdersAndInvoicesToTransferredState = 'Set Work Orders and Invoices to Transferred State';
    static TakeToInvoicing = 'Take to Invoicing';
    static ValueOfOptionParameterNotSpecified = 'Value of option \'{0}\' not specified!';
    static AdditionalInformation = 'Additional Information';
    static NotFullDay = 'Not Full Day';
    static PermissionDenied = 'Permission denied!';
    static WorkOrderInvoiceFile = 'Work Order Invoice File';
    static WorkOrderInvoicePrint = 'Work Order Invoice Print';
    static FileParameterDownloaded = 'File \'{0}\' downloaded!';
    static Camera = 'Camera';
    static CombineCustomersWorkOrdersToSameInvoice = 'Combine Customers Work Orders to Same Invoice';
    static ConvertToProject = 'Convert to Project';
    static OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToProject = 'Only work that is not attached to a project can be converted to project!';
    static WorkOrderProject = 'Project';
    static WorkOrderTask = 'Task';
    static WorkOrderWork = 'Work';
    static WorkTitle = 'Work Title';
    static WorkTitleMustBeDefined = 'Work title must be defined!';
    static AbrDays = 'd';
    static Attach = 'Attach';
    static DoYouReallyWantToConvertWorkOrderWorkToWorkOrderProject = 'Do you really want to convert work to project?';
    static AttachWorkToProject = 'Attach Work to Project';
    static EventLogMessageWorkOrderAttachedToProjectParameter = 'Work order attached to project \'{0}\'';
    static EventLogMessageWorkOrderConvertedToProject = 'Work order converted to project';
    static EventLogMessageWorkOrderDetachedFromProjectParameter = 'Task detached from project \'{0}\'';
    static WorkOrderDetachConfirmation = 'Do you really want to remove task \'{0}\' from project?';
    static WorkOrderDetachFromProjectErrorParameter = 'Failed to detach work order from project! Error: \'{0}\'';
    static WorkOrderDetachFromProjectSuccess = 'Task detached from project successfully!';
    static WorkOrderDetachConfirmationRecurring = 'Do you really want to remove task \'{0}\' from recurring work?';
    static WorkOrderParameterIsNotAttachedToWorkOrderParameter = 'Task \'{0}\' is not attached to project \'{1}\'!';
    static WorkOrderParameterIsNotProject = 'Work order \'{0}\' is not a project!';
    static WorkOrderParameterIsNotTask = 'Work order \'{0}\' is not a task!';
    static WorkOrdersAttachToProjectErrorParameter = 'Failed to attach work orders to project! Error: \'{0}\'';
    static WorkOrdersAttachToProjectSuccess = 'Work orders attached to project successfully!';
    static WorkOrdersCanBeAttachedOnlyToProjects = 'Work orders can be attached only to projects!';
    static WorkOrdersNotFound = 'Work orders not found!';
    static AbrNumber = 'No.';
    static AttachedToProject = 'Attached to Project';
    static DetachFromProject = 'Detach from Project';
    static Invoice = 'Invoice';
    static ProjectDoesNotHaveAnyTasksDoYouWantToRemoveProject = 'The project does not have any tasks.Do you want to remove the project?';
    static WorkOrderChildDetachConfirmation = 'Do you really want to detach task from project?';
    static WorkOrderChildDetachRemoveConfirmation = 'The project does not have tasks anymore. Do you also want to remove the project?';
    static WorkOrderProjectParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt = 'The project \'{0}\' cannot be removed, because tasks are attached to it!';
    static WorkOrderStateUndefined = '(several states)';
    static AllWorkOrderTasksMustBeInSameState = 'All tasks must be in the same state!';
    static RemoveWorkOrderProjectTasksBeforeRemovingTheProject = 'Remove tasks from the project before removing the project.';
    static WorkOrderProjectRemoveConfirmation = 'Do you really want to remove project \'{0}\'?';
    static WorkOrderTaskParameterCannotCrossWeekBoundary = 'Task \'{0}\' cannot cross week boundary!';
    static CombineProjectWorkOrdersToSameInvoice = 'Combine Project Work Orders to Same Invoice';
    static CreateInvoices = 'Create Invoices';
    static CreateInvoicesSuccess = 'Invoices created successfully!';
    static CreateWorkOrderInvoicesFailedErrorParameter = 'Failed to create invoice! Error: \'{0}\'';
    static CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = 'Customer \'{0}\' cannot be removed, because the customer is used in {1} invoices!';
    static EventLogMessageInvoiceCreated = 'Invoice created';
    static EventLogMessageInvoiceRemoved = 'Invoice removed';
    static EventLogMessageWorkOrderAttachedToInvoiceParameter = 'Work order attached to invoice \'{0}\'';
    static EventLogMessageWorkOrderDetachedFromInvoiceParameter = 'Work order detached from invoice \'{0}\'';
    static InvoiceDataDate = 'File Downloaded';
    static InvoiceParameterNotFound = 'Invoice \'{0}\' was not found!';
    static InvoiceRemoveConfirmation = 'Do you really want to remove invoice \'{0}\'?';
    static InvoiceRemoveFailedConcurrencyError = 'Failed to remove invoice \'{0}\', because the invoice has been modified concurrently by another user!';
    static InvoiceRemoveFailedErrorParameter = 'Failed to remove invoice \'{0}\'! Error: \'{1}\'';
    static InvoiceRemoveSuccess = 'Invoice \'{0}\' removed successfully!';
    static Invoices = 'Invoices';
    static InvoiceStatePreliminary = 'Preliminary';
    static InvoiceStateTransferred = 'Transferred';
    static SelectWorkOrders = 'Select Work Orders';
    static WorkOrderParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = 'Work order \'{0}\' cannot be removed, because the work order is used in {1} invoices!';
    static AddWorkOrdersToInvoiceFailedErrorParameter = 'Failed to add work orders to invoice \'{0}\'! Error: \'{1}\'';
    static AddWorkOrdersToInvoiceSuccess = 'Work orders added to invoice \'{0}\' successfully!';
    static CustomerSiteNumbers = 'Customer Site Numbers';
    static EventLogMessageInvoiceStateChanged = 'Invoice state changed: \'{0}\' => \'{1}\'';
    static StateOfAllInvoicesMustBeParameter = 'The state of all invoices must be \'{0}\'!';
    static WorkOrderRemoveFromInvoiceConfirmation = 'Do you really want to remove worker order \'{0}\' from invoice?';
    static WorkOrderRemoveFromInvoiceFailedConcurrencyError = 'Failed to remove work order \'{0}\' from invoice \'{1}\', because the work order has been modified concurrently by another user!';
    static WorkOrderRemoveFromInvoiceFailedErrorParameter = 'Failed to remove work order \'{0}\' from invoice \'{1}\'! Error: \'{2}\'';
    static WorkOrderRemoveFromInvoiceSuccess = 'Work order \'{0}\' removed from invoice \'{0}\' successfully!';
    static WorkOrdersToBeAddedMustHaveSameCustomerAsInvoice = 'Work orders to be added must have the same customer as the invoice!';
    static IsRelatedToInvoiceParameter = 'Is related to invoice {0}';
    static NoCompetenciesDefined = 'No competencies defined';
    static AllWorkOrderTypes = 'All Types';
    static CustomerErpReference = 'Customer ERP Reference';
    static EventLogMessageInvoiceFileCreated = 'Invoice file created';
    static WorkOrdersToBeAddedMustHaveSameWorkOrderTypeAsInvoice = 'Work orders to be added must have the same work order type as the invoice!';
    static Definitions = 'Definitions';
    static NewWork = 'New Work';
    static Show = 'Show';
    static Team = 'Team';
    static NoMatchesFound = 'No matches found';
    static ShowAdditionalResults = 'Show additional results...';
    static Collapse = 'Collapse';
    static Expand = 'Expand';
    static Calendar = 'Calendar';
    static InvoicingTitle = 'Invoicing';
    static MapTitle = 'Map';
    static WorkCalendarTitle = 'Planning';
    static WorkListTitle = 'Invoicing';
    static WorkTimeTitle = 'Work Time';
    static Billable = 'Billable';
    static Changed = 'Changed';
    static Reported = 'Reported';
    static WorkingTimeRegistrations = 'Work Shifts';
    static CalendarAccuracyDayTwoWeeks = '24 h';
    static CalendarAccuracyQuarterHour = '15 min';
    static NextDay = 'Next Day';
    static PreviousDay = 'Previous Day';
    static Weeks = 'Weeks';
    static CalendarAccuracyFourHours = '4 h';
    static AddNew = 'Add New';
    static OwnSettings = 'Settings';
    static SystemConfiguration = 'System Configuration';
    static Reports = 'Reports';
    static Select = 'Select';
    static Projects = 'Projects';
    static Resources = 'Resources';
    static StandByWorks = 'Standing by';
    static Teams = 'Teams';
    static SelectedOrder = 'Selected order';
    static FinishedWorks = 'Finished works';
    static TransferredWork = 'Transferred work';
    static Recurrence = 'Recurrence';
    static Allday = 'Allday';
    static EndTime = 'End time';
    static RecurrenceDuration = 'Duration';
    static StartTime = 'Start time';
    static Days = 'Day(s)';
    static EveryWeekday = 'Every weekday';
    static Yearly = 'Yearly';
    static Daily = 'Daily';
    static Every = 'Every';
    static Monthly = 'Monthly';
    static Weekly = 'Weekly';
    static Today = 'Today';
    static NoSelectedWorkOrders = 'No Selected Work Orders';
    static NewContactPerson = 'New contact person';
    static NewCustomer = 'New customer';
    static Subscriber = 'Subscriber';
    static WorkOrderTasks = 'Work order tasks';
    static CustomerMustBeDefined = 'Customer must be defined!';
    static Hour = 'Hour';
    static HourPluralDuration = 'Hours';
    static Minutes = 'minutes';
    static Always = 'Always';
    static April = 'April';
    static August = 'August';
    static Day = 'Day';
    static DayInterval = 'Day(s)';
    static DayPluralDuration = 'Days';
    static December = 'December';
    static EditRecurrence = 'Edit Recurrence';
    static February = 'February';
    static Friday = 'Friday';
    static January = 'January';
    static July = 'July';
    static June = 'June';
    static MakeRecurring = 'Make Recurring';
    static March = 'March';
    static May = 'May';
    static Monday = 'Monday';
    static MonthIntervalOn = 'month(s)';
    static November = 'November';
    static October = 'October';
    static OnDay = 'of every';
    static RangeOfRecurrence = 'Range of Recurrence';
    static RecurrenceAfterOccurrences = 'occurences';
    static RecurrenceEndAfter = 'End after';
    static RecurrenceEndBy = 'End by';
    static RecurrencePattern = 'Recurrence Pattern';
    static RemoveRecurrence = 'Remove Recurrence';
    static Saturday = 'Saturday';
    static September = 'September';
    static StartDateAndEndDateMustBeSpecifiedBeforeRecurrenceCanBeDefined = 'Start date and end date must be specified before recurrence can be defined!';
    static Sunday = 'Sunday';
    static Thursday = 'Thursday';
    static Tuesday = 'Tuesday';
    static Wednesday = 'Wednesday';
    static WeekIntervalOn = 'week(s) on:';
    static WeekPluralDuration = 'Weeks';
    static WorkTime = 'Work Time';
    static YearIntervalOn = 'year(s)';
    static EventLogMessageWorkOrderConvertedToRecurringProject = 'Work order converted to recurring project';
    static OnlyWorkOrderThatIsNotAttachedToProjectCanBeConvertedToRecurringProject = 'Only work that is not attached to a project can be converted to recurring project!';
    static StartTimeAndEndTimeMustBeDefinedForRecurringWork = 'Start time and end time must be defined for recurring work!';
    static EventLogMessageWorkOrderRecurrenceChanged = 'Recurring project recurrency changed';
    static RecurringWorkMustHaveAtLeastOneOccurence = 'Recurring work must have at least one occurence!';
    static AbrHours = 'h';
    static AbrMins = 'min';
    static CostCenter = 'Cost Center';
    static NoWorkShiftsForThisDay = 'No work shifts';
    static Order = 'Order';
    static Orders = 'Orders';
    static Settings = 'Settings';
    static Break = 'Break';
    static BreakReason = 'Reason of break';
    static CommentMustBeDefined = 'Comment must be defined!';
    static Continue = 'Continue';
    static CurrentWorkShiftIsNotRelatedToWorkOrder = 'Running work shift is not related to order!';
    static CurrentWorkShiftNotFound = 'Running work shift not found!';
    static DoYouWantToSetCurrentWorkOrderDone = 'Do you want to move current order to Done state?';
    static EndLocation = 'End Location';
    static Finish = 'Finish';
    static NonOrder = 'No orders';
    static NotInUse = 'Not in Use';
    static Pause = 'Pause';
    static PauseWork = 'Pause Work';
    static Start = 'Start';
    static StartLocation = 'Start Location';
    static StartNew = 'Start New';
    static SuitableWorkOrdersOrCostCentersNotFound = 'Suitable work orders or cost centers not found!';
    static WorkShiftTimeSlotTypeLunchBreak = 'Lunch';
    static WorkShiftInProgress = 'Work Shift in Progress';
    static WorkShiftTimeSlotSaveFailedConcurrencyError = 'Failed to save working time registration, because the working time registration has been modified concurrently by another user!';
    static WorkShiftTimeSlotSaveFailedErrorParameter = 'Failed to save working time registration! Error: \'{0}\'';
    static WorkShiftTimeSlotSaveSuccess = 'Working time registration saved successfully!';
    static WorkShiftTimeSlotStateInvoicing = 'In Invoicing';
    static WorkShiftTimeSlotStateDone = 'Reported';
    static WorkShiftTimeSlotStateInProgress = 'Running';
    static PreviousWorkShiftNotFound = 'Previous work shift not found!';
    static WorkShiftTimeSlotTypeBreak = 'Break';
    static WorkShiftTimeSlotTypeWork = 'Work';
    static AllTypes = 'All Types';
    static AType = 'Type';
    static Task = 'Task';
    static NoSelectedInvoice = 'No selected invoice';
    static SelectedInvoice = 'Selected invoice';
    static ThisWeek = 'This Week';
    static InvoicesTransfer = 'Transfer to invoicing';
    static Salaries = 'Salaries';
    static WorkingTimeRecords = 'Working Time Records';
    static WorkOrdersChecking = 'Checking of Work Orders';
    static RoutePoint = 'Route Point';
    static RoutePointBookingCheckParameterNotFound = 'Route booking check \'{0}\' was not found!';
    static RoutePointBookingParameterNotFound = 'Route booking \'{0}\' was not found!';
    static RoutePointBookingRemoveConfirmation = 'Do you want to remove the route booking?';
    static RoutePointBookingRemoveFailedConcurrencyError = 'Failed to remove route booking \'{0}\', because the route booking has been modified concurrently by another user!';
    static RoutePointBookingRemoveFailedErrorParameter = 'Failed to remove route booking \'{0}\'! Error: \'{1}\'';
    static RoutePointBookingRemoveSuccess = 'Route booking \'{0}\' removed successfully!';
    static RoutePointBookings = 'Route Bookings';
    static RoutePointBookingSaveFailedConcurrencyError = 'Failed to save route booking \'{0}\', because the route booking has been modified concurrently by another user!';
    static RoutePointBookingSaveFailedErrorParameter = 'Failed to save route booking \'{0}\'! Error: \'{1}\'';
    static RoutePointBookingSaveSuccess = 'Route booking \'{0}\' saved successfully!';
    static RoutePointNotFound = 'Route point not found!';
    static Total = 'Total';
    static AllRoutePointsHaveBeenAdded = 'All route points have been added!';
    static RecurringWorks = 'Recurring Works';
    static NoRoutePointChecksDefined = 'No checks defined for route point';
    static RecurringWork = 'Recurring Work';
    static StartRouteBooking = 'Start Route Booking';
    static RoutePointChecks = 'Route Point Checks';
    static CountryCode = 'Country Code';
    static MoveDown = 'Move Down';
    static MoveUp = 'Move Up';
    static RoutePointCheck = 'Route Point Check';
    static RoutePointCheckParameterNotFound = 'Route point check \'{0}\' was not found!';
    static RoutePointParameterNotFound = 'Route point \'{0}\' was not found!';
    static RoutePoints = 'Route Points';
    static WorkOrdersRemoveConfirmation = 'Do you really want to remove selected {0} worker orders?';
    static WorkOrdersRemoveFailedConcurrencyError = 'Failed to remove work orders \'{0}\', because work orders have been modified concurrently by another user!';
    static WorkOrdersRemoveFailedErrorParameter = 'Failed to remove work orders \'{0}\'! Error: \'{1}\'';
    static WorkOrdersRemoveSuccess = 'Work orders \'{0}\' removed successfully!';
    static EmptyResponse = 'Empty response';
    static GeocodeFailedFailedErrorParameter = 'Failed to geocode! Error: \'{0}\'';
    static NullValueResponse = 'Null value response';
    static ClickToChangeImage = 'Click to Change the Image';
    static InvoiceAddress = 'Invoice Address';
    static InvoicePeriod = 'Invoice Period';
    static SelectImageFile = 'Please Select Image File!';
    static WorkOrderParameterEmployeesMustBeDefined = 'Employees of work \'{0}\' must be defined!';
    static WorkOrderParameterEndDateMustBeDefined = 'End time of work \'{0}\' must be defined!';
    static WorkOrderParameterHourBookingsMustBeDefined = 'Hour bookings of work \'{0}\' must be defined!';
    static WorkOrderParameterRideBookingsMustBeDefined = 'Ride bookings of work \'{0}\' must be defined!';
    static WorkOrderParameterStartDateMustBeDefined = 'Start time of work \'{0}\' must be defined!';
    static WorkOrderParameterStateChangeFailedConcurrencyError = 'Failed to change state of work order \'{0}\', because the work order has been modified concurrently by another user!';
    static WorkOrderParameterStateChangeFailedErrorParameter = 'Failed to change state of work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderParameterStateChangeSuccess = 'Work order \'{0}\' state changed successfully!';
    static WorkOrderProjectParameterStateCannotBeChangedBecauseTasksAreNotInSameState = 'The state of project \'{0}\' cannot be changed, because all tasks of the project are not in the same state!';
    static WorkOrdersParameterStateChangeFailedConcurrencyError = 'Failed to change state of work orders \'{0}\', because work orders have been modified concurrently by another user!';
    static WorkOrdersParameterStateChangeFailedErrorParameter = 'Failed to change state of work orders \'{0}\'! Error: \'{1}\'';
    static WorkOrdersParameterStateChangeSuccess = 'Work orders \'{0}\' state changed successfully!';
    static WorkOrderTaskParameterEmployeesMustBeDefined = 'Employees of task \'{0}\' must be defined!';
    static WorkOrderTaskParameterEndDateMustBeDefined = 'End time of task \'{0}\' must be defined!';
    static WorkOrderTaskParameterHourBookingsMustBeDefined = 'Hour bookings of task \'{0}\' must be defined!';
    static WorkOrderTaskParameterRideBookingsMustBeDefined = 'Ride bookings of task \'{0}\' must be defined!';
    static WorkOrderTaskParameterStartDateMustBeDefined = 'Start time of task \'{0}\' must be defined!';
    static Latitude = 'Latitude';
    static Longitude = 'Longitude';
    static Location = 'Location';
    static Card = 'Card';
    static List = 'List';
    static WorkOrderSaveFailedRecurrenceChangeFailed = 'Failed to save work order \'{0}\'! Failed to create recurring tasks!';
    static APrint = 'Print';
    static Data = 'Information';
    static NoCheckedWorkOrdersFound = 'Work orders that are in state \'Checked\' not found!';
    static Address = 'Address';
    static CustomerSites = 'Customer Sites';
    static SiteParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Site \'{0}\' cannot be removed, because the site is used in {1} work orders!';
    static SiteRemoveConfirmation = 'Do you really want to remove site \'{0}\'?';
    static SiteRemoveFailedConcurrencyError = 'Failed to remove site \'{0}\', because the site has been modified concurrently by another user!';
    static SiteRemoveFailedErrorParameter = 'Failed to remove site \'{0}\'! Error: \'{1}\'';
    static SiteRemoveSuccess = 'Site \'{0}\' removed successfully!';
    static FieldParameterValueMustBeDefined = 'Field \'{0}\' value must be defined!';
    static AttachExisting = 'Attach Existing';
    static ContactWithNameParameterAlreadyExists = 'Contact with name \'{0}\' already exists!';
    static StartTimeMustBeDefined = 'Start time must be defined!';
    static WorkingTimeRegistration = 'Work Shift';
    static NotTransfered = 'Not Transfered';
    static OwnerCannotBeChanged = 'Owner cannot be changed!';
    static SelectCostCenter = 'Select Cost Center';
    static SelectWorkOrder = 'Select Work Order';
    static TransferredToInvoicing = 'Transferred to Invoicing';
    static TransferredToSalary = 'Transferred to Salary Payment';
    static WorkShiftTimeSlotHasBeenTranferredToInvoicing = 'Working time registration has been tranferred to invoicing!';
    static WorkShiftTimeSlotHasBeenTranferredToSalaryPayment = 'Working time registration has been tranferred to salary payment!';
    static WorkShiftTimeSlotParameterNotFound = 'Working time registration \'{0}\' was not found!';
    static WorkShiftTimeSlotStateComplete = 'Complete';
    static WorkShiftTimeSlotStateSalary = 'In Salary Calculation';
    static DoYouReallyWantToSaveWorkShiftTimeSlot = 'Do you want to save working time registration?';
    static SelectTaskType = 'Select Task Type';
    static Roadmap = 'Roads';
    static Satellite = 'Satellite';
    static Terrain = 'Terrain';
    static WorkOrdersApproveToBeInvoicedConfirmation = 'Do you really want to approved selected {0} worker orders to be invoiced?';
    static CreateReport = 'Create Report';
    static InvalidRequestData = 'Invalid Request Data!';
    static Preview = 'Preview';
    static Report = 'Report';
    static ReportParameterNotFound = 'Report \'{0}\' was not found!';
    static AddCustomersBeforeAddingCustomerSites = 'Add customers before adding customer sites!';
    static MeasuredTimeParameterWorkTimeParameter = 'Measured time {0}, work time {1}';
    static ContinueWork = 'Continue Work';
    static WorkTimeMeasurement = 'Work Time Measurement';
    static Hide = 'Hide';
    static ToBeInvoicedRecords = 'To Be Invoiced Rows';
    static WorkOrderDocumentsSaveFailedErrorParameter = 'Failed to save work order \'{0}\' documents! Error: \'{1}\'';
    static WorkOrderDocumentsSaveSuccess = 'Work order \'{0}\' documents saved successfully!';
    static WorkOrderRemoveDocumentsFailedErrorParameter = 'Failed to remove work order \'{0}\' documents! Error: \'{1}\'';
    static WorkOrderRemoveDocumentsSuccess = 'Work order \'{0}\' documents removed successfully!';
    static AddHourBooking = 'Add Hour Booking';
    static AddProductBooking = 'Add Product Booking';
    static AddRideBooking = 'Add Ride Booking';
    static DoYouWantToMoveWorkOrderStateToParameterState = 'Do you want to move work order to \'{0}\' state?';
    static WorkOrderParameterHourBookingSaveFailedConcurrencyError = 'Failed to save work order \'{0}\' hour booking, because the hour booking has been modified concurrently by another user!';
    static WorkOrderParameterHourBookingsSaveFailedErrorParameter = 'Failed to save work order \'{0}\' hour bookings! Error: \'{1}\'';
    static WorkOrderParameterHourBookingsSaveSuccess = 'Work order \'{0}\' hour bookings saved successfully!';
    static WorkOrderParameterProductBookingSaveFailedConcurrencyError = 'Failed to save work order \'{0}\' product booking, because the product booking has been modified concurrently by another user!';
    static WorkOrderParameterProductBookingsSaveFailedErrorParameter = 'Failed to save work order \'{0}\' product bookings! Error: \'{1}\'';
    static WorkOrderParameterProductBookingsSaveSuccess = 'Work order \'{0}\' product bookings saved successfully!';
    static WorkOrderParameterRideBookingSaveFailedConcurrencyError = 'Failed to save work order \'{0}\' ride booking, because the ride booking has been modified concurrently by another user!';
    static WorkOrderParameterRideBookingsSaveFailedErrorParameter = 'Failed to save work order \'{0}\' ride bookings! Error: \'{1}\'';
    static WorkOrderParameterRideBookingsSaveSuccess = 'Work order \'{0}\' ride bookings saved successfully!';
    static WorkOrderRemoveHourBookingsFailedErrorParameter = 'Failed to remove work order \'{0}\' hour bookings! Error: \'{1}\'';
    static WorkOrderRemoveHourBookingsSuccess = 'Work order \'{0}\' hour bookings removed successfully!';
    static WorkOrderRemoveProductBookingsFailedErrorParameter = 'Failed to remove work order \'{0}\' product bookings! Error: \'{1}\'';
    static WorkOrderRemoveProductBookingsSuccess = 'Work order \'{0}\' product bookings removed successfully!';
    static WorkOrderRemoveRideBookingsFailedErrorParameter = 'Failed to remove work order \'{0}\' ride bookings! Error: \'{1}\'';
    static WorkOrderRemoveRideBookingsSuccess = 'Work order \'{0}\' ride bookings removed successfully!';
    static ChangeState = 'Change State';
    static RecurringWorkCannotHaveMoreThanParameterOccurences = 'Recurring work cannot have more than {0} occurences!';
    static Hours = 'Hours';
    static CalculateHours = 'Calculate Hours';
    static CostCenters = 'Cost Centers';
    static DailyAdditionalHours = 'Daily Additional Hours';
    static EventsWorkTimeTotal = 'Events Work Time Total';
    static DoYouReallyWantToCalculateHoursAndDiscardAllCurrentData = 'Do you really want to calculate hours and discard all current hours?';
    static DoYouReallyWantToSaveHours = 'Do you really want to save hours?';
    static HoursSaveSaveFailedErrorParameter = 'Failed to save hours! Error: \'{0}\'';
    static HoursSaveSuccess = 'Hours saved successfully!';
    static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings = 'Employee \'{0}\' cannot be removed, because the employee is used in {1} hour bookings!';
    static EmployeeParameterCannotBeRemovedBecauseItIsRelatedToParameterSalaries = 'Employee \'{0}\' cannot be removed, because the employee is used in {1} salaries!';
    static SalaryPeriod = 'Salary Period';
    static SalaryType = 'Salary Type';
    static Calculate = 'Calculate';
    static DoYouReallyWantToCalculateSalaryAndDiscardAllCurrentData = 'Do you really want to calculate salary row types and discard all current row values?';
    static DoYouReallyWantToSaveSalary = 'Do you really want to save the salary?';
    static SalaryParameterNotFound = 'Salary \'{0}\' was not found!';
    static SalaryRowType = 'Salary Row Type';
    static SalarySaveFailedConcurrencyError = 'Failed to save salary \'{0}\', because the salary has been modified concurrently by another user!';
    static SalarySaveSaveFailedErrorParameter = 'Failed to save salary \'{0}\'! Error: \'{1}\'';
    static SalarySaveSuccess = 'Salary saved successfully!';
    static DateMustBeDefined = 'Date must be defined!';
    static SalaryTransfer = 'Salary Transfer';
    static Transferred = 'Transferred';
    static WorkShiftTimeSlotRemoveConfirmation = 'Do you really want to remove working time registration?';
    static WorkShiftTimeSlotRemoveFailedConcurrencyError = 'Failed to remove working time registration, because the working time registration has been modified concurrently by another user!';
    static WorkShiftTimeSlotRemoveFailedErrorParameter = 'Failed to remove working time registration! Error: \'{0}\'';
    static WorkShiftTimeSlotRemoveSuccess = 'Working time registration removed successfully!';
    static BusinessId = 'Business Id';
    static CustomerType = 'Customer Type';
    static CustomerTypesMustBeDefinedBeforeAddingCustomers = 'Customer types must be defined before adding customers!';
    static ProductGroup = 'Product Group';
    static ProductGroupsMustBeDefinedBeforeAddingProducts = 'Product groups must be defined before adding products!';
    static Canceled = 'Canceled';
    static CancelWorkOrder = 'Cancel Work Order';
    static EventLogMessageWorkOrderCanceled = 'Work order canceled';
    static EventLogMessageWorkOrderRestored = 'Work order restored';
    static ProjectCannotBeCanceled = 'Project cannot be canceled!';
    static RestoreWorkOrder = 'Restore Work Order';
    static WorkOrderCancelConfirmation = 'Do you really want to cancel the worker order?';
    static WorkOrderCancelFailedConcurrencyError = 'Failed to cancel work order \'{0}\', because the work order has been modified concurrently by another user!';
    static WorkOrderCancelFailedErrorParameter = 'Failed to cancel work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderCancelSuccess = 'Work order \'{0}\' canceled successfully!';
    static WorkOrderRestoreConfirmation = 'Do you really want to restore the worker order?';
    static WorkOrderRestoreFailedConcurrencyError = 'Failed to restore work order \'{0}\', because the work order has been modified concurrently by another user!';
    static WorkOrderRestoreFailedErrorParameter = 'Failed to restore work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderRestoreSuccess = 'Work order \'{0}\' restored successfully!';
    static Attachment = 'Attachment';
    static WorkOrderDocumentSaveFailedErrorParameter = 'Failed to save work order \'{0}\' document! Error: \'{1}\'';
    static WorkOrderDocumentSaveSuccess = 'Work order \'{0}\' document saved successfully!';
    static MapOnId = 'Mapon Id';
    static VehicleLocationsSaveFailedErrorParameter = 'Failed to save equipment \'{0}\' locations! Error: \'{1}\'';
    static VehicleLocationsSaveSuccess = 'Equipment \'{0}\' locations saved successfully!';
    static AddToFavories = 'Add to Favories';
    static Favorites = 'Favorites';
    static LookUp = 'Search';
    static RemoveFromFavories = 'Remove from Favories';
    static SiteMapLinkUpdateFailedConcurrencyError = 'Failed to update site \'{0}\' map link, because the site has been modified concurrently by another user!';
    static SiteMapLinkUpdateFailedErrorParameter = 'Failed to update site \'{0}\' map link! Error: \'{1}\'';
    static SiteMapLinkUpdateSuccess = 'Site \'{0}\' map link updated successfully!';
    static SitesMapLinkUpdateFailedConcurrencyError = 'Failed to update map links for {0} sites, because sites have been modified concurrently by another user!';
    static SitesMapLinkUpdateFailedErrorParameter = 'Failed to update map links for {0} sites! Error: \'{1}\'';
    static SitesMapLinkUpdateSuccess = '{0} sites map links updated successfully!';
    static SitesRemoveConfirmation = 'Do you really want to remove selected {0} sites?';
    static SitesRemoveFailedConcurrencyError = 'Failed to remove {0} sites, because sites have been modified concurrently by another user!';
    static SitesRemoveFailedErrorParameter = 'Failed to remove {0} sites! Error: \'{1}\'';
    static SitesRemoveSuccess = '{0} sites removed successfully!';
    static CalendarAccuracyDefault = 'Default Calendar Accuracy';
    static CalendarOfficeTimeEnd = 'Calendar Office Time End';
    static CalendarOfficeTimeStart = 'Calendar Office Time Start';
    static ClickToAddImage = 'Click to Add Image';
    static DefaultCulture = 'Language';
    static LanguageEnglish = 'English';
    static LanguageFinnish = 'Finnish';
    static Map = 'Map';
    static OnMap = 'On Map';
    static TimeTable = 'Timetable';
    static Company = 'Company';
    static CompanySettings = 'Company Settings';
    static CompetencyTypeRemoveConfirmation = 'Do you really want to remove competency type \'{0}\'?';
    static CompetencyTypes = 'Competency Types';
    static CostCenterRemoveConfirmation = 'Do you really want to remove cost center \'{0}\'?';
    static CustomerTypeRemoveConfirmation = 'Do you really want to remove customer type \'{0}\'?';
    static CustomerTypes = 'Customer Types';
    static DayBookingTypeRemoveConfirmation = 'Do you really want to remove day booking type \'{0}\'?';
    static DayBookingTypes = 'Day Booking Types';
    static HourBookingTypes = 'Hour Booking Types';
    static Mobile = 'Mobile';
    static ProductGroups = 'Product Groups';
    static SalaryRowTypes = 'Salary Row Types';
    static SalaryTypes = 'Salary Types';
    static SendingType = 'Sending Type';
    static UserGroupRemoveConfirmation = 'Do you really want to remove user group \'{0}\'?';
    static UserGroups = 'User Groups';
    static VatRate = 'VAT';
    static Web = 'Planner';
    static WorkOrderAcknowledgementTypeRemoveConfirmation = 'Do you really want to remove acknowledgement request type \'{0}\'?';
    static WorkOrderAcknowledgementTypes = 'Acknowledgement Request Types';
    static WorkOrderAcknowledgementTypeSendingTypeDoNotSend = 'Do Not Send';
    static WorkOrderAcknowledgementTypeSendingTypeSendAlways = 'Send Always';
    static WorkOrderAcknowledgementTypeSendingTypeSendManually = 'Send Manually';
    static WorkOrderTypeRemoveConfirmation = 'Do you really want to remove work order type \'{0}\'?';
    static LocationError = 'Location: Not Found';
    static LocationSuccess = 'Location: Found';
    static AddRoute = 'Add Route';
    static AddRoutePoint = 'Add Route Point';
    static EditRoute = 'Edit Route';
    static HideAdditionalInformation = 'Hide Additional Information';
    static Route = 'Route';
    static ShowAdditionalInformation = 'Show Additional Information';
    static RoutePointRemoveConfirmation = 'Do you really want to remove route point \'{0}\'?';
    static Approve = 'Approve';
    static UnresourcedStandByWorkOrders = 'Unresourced Stand by Work Orders';
    static UnscheduledStandByWorkOrders = 'Unscheduled Stand by Work Orders';
    static OwnerParameterNotFound = 'Company \'{0}\' was not found!';
    static OwnerSaveFailedConcurrencyError = 'Failed to save company, because the company has been modified concurrently by another user!';
    static OwnerSaveFailedErrorParameter = 'Failed to save company! Error: \'{0}\'';
    static OwnerSaveSuccess = 'Company saved successfully!';
    static Theme = 'Theme';
    static DoLockAll = 'Lock';
    static DoUnlockAll = 'Unlock';
    static Locked = 'Locked';
    static WorkShiftTimeSlotsLockingFailedConcurrencyError = 'Failed to lock working time registrations, because working time registrations have been modified concurrently by another user!';
    static WorkShiftTimeSlotsLockingFailedErrorParameter = 'Failed to lock working time registrations! Error: \'{0}\'';
    static WorkShiftTimeSlotsLockingSuccess = 'Working time registrations locked successfully!';
    static WorkShiftTimeSlotStateInProgressCannotBeLocked = 'Running working time registration cannot be locked!';
    static WorkShiftTimeSlotStateLocked = 'Locked';
    static WorkShiftTimeSlotStateLockedCannotBeChanged = 'Locked working time registration cannot be changed!';
    static WorkShiftTimeSlotStateLockedCannotBeRemoved = 'Locked working time registration cannot be removed!';
    static WorkShiftTimeSlotsUnlockingFailedConcurrencyError = 'Failed to unlock working time registrations, because working time registrations have been modified concurrently by another user!';
    static WorkShiftTimeSlotsUnlockingFailedErrorParameter = 'Failed to unlock working time registrations! Error: \'{0}\'';
    static WorkShiftTimeSlotsUnlockingSuccess = 'Working time registrations unlocked successfully!';
    static CalculatedH = 'Measured (h)';
    static InvoiceH = 'Invoice (h)';
    static Invoicing = 'Invoicing';
    static Measured = 'Measured';
    static FormContainsUnapprovedValues = 'Form contains values that have not been approved!';
    static Kind = 'Kind';
    static PeriodAdditionalHours = 'Period Additional Hours';
    static WorkHours = 'Work Hours';
    static SalaryPeriodParameterNotFound = 'Salary period \'{0}\' was not found!';
    static WorkTimePeriodParameterNotFound = 'Work time period \'{0}\' was not found!';
    static SalaryPeriodRemoveConfirmation = 'Do you really want to remove salary period \'{0}\'?';
    static SalaryPeriods = 'Salary Periods';
    static WorkTimePeriod = 'Work Time Period';
    static WorkTimePeriods = 'Work Time Periods';
    static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterHourBookings = 'Cost center \'{0}\' cannot be removed, because the cost center is used in {1} hour bookings!';
    static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkShiftTimeSlots = 'Cost center \'{0}\' cannot be removed, because the cost center is used in {1} working time registrations!';
    static CostCenterParameterNotFound = 'Cost center \'{0}\' was not found!';
    static CostCenterRemoveFailedConcurrencyError = 'Failed to remove cost center, because the cost center has been modified concurrently by another user!';
    static CostCenterRemoveFailedErrorParameter = 'Failed to remove cost center! Error: \'{0}\'';
    static CostCenterRemoveSuccess = 'Cost center \'{0}\' removed successfully!';
    static CostCenterSaveFailedConcurrencyError = 'Failed to save cost center \'{0}\', because the cost center has been modified concurrently by another user!';
    static CostCenterSaveFailedErrorParameter = 'Failed to save cost center \'{0}\'! Error: \'{1}\'';
    static CostCenterSaveSuccess = 'Cost center \'{0}\' saved successfully!';
    static EmployeeGroupRemoveConfirmation = 'Do you really want to remove employee group \'{0}\'?';
    static EmployeeGroups = 'Employee Groups';
    static EmployeeGroupsHaveNotBeenSpecified = 'Employee groups have not been specified!';
    static SalaryTypesHaveNotBeenSpecified = 'Salary types have not been specified!';
    static UserGroupsHaveNotBeenSpecified = 'User groups have not been specified!';
    static VehicleGroup = 'Equipment Group';
    static VehicleGroupRemoveConfirmation = 'Do you really want to remove equipment group \'{0}\'?';
    static VehicleGroups = 'Equipment Groups';
    static VehicleGroupsHaveNotBeenSpecified = 'Equipment groups have not been specified!';
    static TokenHasBeenExpired = 'Session has been expired!';
    static WorkOrderDurationIsZero = 'Work order duration is 0 minutes!';
    static WorkOrderParameterDurationIsZero = 'Work order \'{0}\' duration is 0 minutes!';
    static WorkOrderTaskParameterDurationIsZero = 'Task \'{0}\' duration is 0 minutes!';
    static InvalidEndTime = 'Invalid end time!';
    static RemoveTask = 'Remove Task';
    static RemoveTasks = 'Remove Tasks';
    static WorkOrderProjectRecurringParameterCannotBeRemovedBecauseOtherWorkOrderTasksAreBelongingToIt = 'The recurring work \'{0}\' cannot be removed, because tasks are attached to it!';
    static WorkOrdersDetachConfirmation = 'Do you really want to remove selected tasks from project?';
    static WorkOrdersDetachConfirmationRecurring = 'Do you really want to remove selected tasks from recurring work?';
    static WorkShiftStartedParameter = 'Work Shift Started {0}';
    static AddRecurringTasks = 'Add Recurring Tasks';
    static DoYouReallyWantToAddRecurringTasks = 'Do you want to add recurring tasks?';
    static AddExistingWorkOrderWork = 'Add Existing Order';
    static AddTask = 'Add Task';
    static AttachWorkToRecurringWork = 'Attach Work to Recurring Work';
    static DoYouReallyWantToChangeWorkOrders = 'Do you want to change work orders?';
    static DurationOfRecurringTaskCannotBeZero = 'Duration of recurring task cannot be 0 minutes!';
    static DurationOfRecurringWorkCannotBeZero = 'Duration of recurring work cannot be 0 minutes!';
    static Set = 'Set';
    static SetEmployees = 'Set Employees';
    static SetState = 'Set State';
    static SetVehicles = 'Set Equipments';
    static ValuesCopiedFromRecurringWork = 'Values Copied From Recurring Work';
    static ValuesCopiedFromWorkOrderTask = 'Values Copied From Task';
    static WorkOrderAddRecurringTasksFailedConcurrencyError = 'Failed to add recurring tasks, because the recurring work has been modified concurrently by another user!';
    static WorkOrderAddRecurringTasksFailedErrorParameter = 'Failed to add recurring tasks! Error: \'{0}\'';
    static WorkOrderAddRecurringTasksSuccess = 'Recurring tasks added successfully!';
    static WorkOrderParameterEmployeesChangeFailedConcurrencyError = 'Failed to set employees of work order \'{0}\', because the work order has been modified concurrently by another user!';
    static WorkOrderParameterEmployeesChangeFailedErrorParameter = 'Failed to set employees of work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderParameterEmployeesChangeSuccess = 'Work order \'{0}\' employees set successfully!';
    static WorkOrderParameterVehiclesChangeFailedConcurrencyError = 'Failed to set equipment of work order \'{0}\', because the work order has been modified concurrently by another user!';
    static WorkOrderParameterVehiclesChangeFailedErrorParameter = 'Failed to set equipment of work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderParameterVehiclesChangeSuccess = 'Work order \'{0}\' equipment set successfully!';
    static WorkOrdersParameterEmployeesChangeFailedConcurrencyError = 'Failed to set employees of work orders \'{0}\', because work orders have been modified concurrently by another user!';
    static WorkOrdersParameterEmployeesChangeFailedErrorParameter = 'Failed to set employees of work orders \'{0}\'! Error: \'{1}\'';
    static WorkOrdersParameterEmployeesChangeSuccess = 'Work orders \'{0}\' employees set successfully!';
    static WorkOrdersParameterVehclesChangeFailedErrorParameter = 'Failed to set equipment of work orders \'{0}\'! Error: \'{1}\'';
    static WorkOrdersParameterVehiclesChangeFailedConcurrencyError = 'Failed to set equipment of work orders \'{0}\', because work orders have been modified concurrently by another user!';
    static WorkOrdersParameterVehiclesChangeSuccess = 'Work orders \'{0}\' equipment set successfully!';
    static IntegrationParameterSaveFailed = 'Integration parameter save failed';
    static IntegrationParameterSaveSuccess = 'Integration parameter saved successfully';
    static WorkOrderParameterRoutePointSaveFailedConcurrencyError = 'Failed to save work order \'{0}\' route point, because the route point has been modified concurrently by another user!';
    static WorkOrderParameterRoutePointsSaveFailedErrorParameter = 'Failed to save work order \'{0}\' route points! Error: \'{1}\'';
    static WorkOrderParameterRoutePointsSaveSuccess = 'Work order \'{0}\' route points saved successfully!';
    static WorkOrderRemoveRoutePointsFailedErrorParameter = 'Failed to remove work order \'{0}\' route points! Error: \'{1}\'';
    static WorkOrderRemoveRoutePointsSuccess = 'Work order \'{0}\' route points removed successfully!';
    static CreateSalaryFileDataFailedErrorParameter = 'Failed to create salary file! Error: \'{0}\'';
    static CreateSalaryFileDataSuccess = 'Salary file created successfully!';
    static EventLogMessageSalaryFileCreated = 'Salary file created';
    static SalaryFile = 'SalaryFile';
    static BusinessIdentityCode = 'Business Identity Code';
    static EInvoiceAddress = 'E-Invoice Address';
    static EInvoiceOperator = 'E-Invoice Operator';
    static InvoiceEmail = 'Invoice Email';
    static SiteTypePoint = 'Address';
    static SiteTypeRoute = 'Route';
    static VatCode = 'Vat Code';
    static WeekHours = 'Week Hours';
    static WorkTimeTotal = 'Total work time';
    static WorkShiftTimeSlot = 'Working Time Registration';
    static WorkShiftTimeSlotTypeParameterNotFound = 'Working time registration type \'{0}\' was not found!';
    static WorkShiftTimeSlotTypesHaveNotBeenSpecified = 'Working time registration types have not been specified!';
    static AllRoutePointsHaveBeenHandled = 'All route points have been handled!';
    static RoutePointWorkShiftTimeSlotEndTime = 'Departure';
    static RoutePointWorkShiftTimeSlotEndTimeSet = 'Set Departure Time';
    static RoutePointWorkShiftTimeSlotStartTime = 'Arrival';
    static SavingIsNotFinished = 'Saving is not finished!';
    static WorkOrderParameterRoutePointParameterHasBookingsAndCannotBeRemoved = 'Work order \'{0}\' route point \'{1}\' has bookings so it cannot be removed!';
    static ParameterTargets = '{0} targets';
    static SiteTypeNoLocation = 'Office';
    static Integrations = 'Integrations';
    static VehiclesVisibleOnMap = 'Equipment Visible on Map';
    static Abbreviation = 'Abbreviation';
    static Telematics = 'Telematics';
    static SiteCannotBeChangedWhenRoutePointBookingsAreDefined = 'Site cannot be changed if route point bookings are defined for site route!';
    static WorkShiftTimeSlotDurationCannotBeExtended = 'Working time registration duration cannot be extended!';
    static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = 'Customer \'{0}\' cannot be removed, because the customer is used in {1} employees!';
    static CostCenterParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles = 'Customer \'{0}\' cannot be removed, because the customer is used in {1} equipment!';
    static EmployeeGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterEmployees = 'Employee group \'{0}\' cannot be removed, because the employee group is used in {1} employees!';
    static EmployeeGroupParameterNotFound = 'Employee group \'{0}\' was not found!';
    static EmployeeGroupRemoveFailedConcurrencyError = 'Failed to remove employee group, because the employee group has been modified concurrently by another user!';
    static EmployeeGroupRemoveFailedErrorParameter = 'Failed to remove employee group! Error: \'{0}\'';
    static EmployeeGroupRemoveSuccess = 'Employee group removed successfully!';
    static EmployeeGroupSaveFailedConcurrencyError = 'Failed to save employee group, because the employee group registration has been modified concurrently by another user!';
    static EmployeeGroupSaveFailedErrorParameter = 'Failed to save employee group! Error: \'{0}\'';
    static EmployeeGroupSaveSuccess = 'Employee group saved successfully!';
    static DurationMustBeDefined = 'Duration must be defined!';
    static Employment = 'Calculation Model';
    static AddDayBooking = 'Add day booking';
    static AddWorkShiftTimeSlot = 'Add working time registration';
    static CostCentersHaveNotBeenSpecified = 'Cost centers have not been specified!';
    static DayBookingRemoveConfirmation = 'Do you really want to remove day booking?';
    static DayBookingRemoveFailedErrorParameter = 'Failed to remove day booking! Error: \'{0}\'';
    static DayBookingRemoveSuccess = 'Day booking removed successfully!';
    static DayBookingSaveFailedConcurrencyError = 'Failed to save day booking \'{0}\', because the day booking has been modified concurrently by another user!';
    static DayBookingSaveSuccess = 'Day booking saved successfully!';
    static DayBookingsRemoveFailedErrorParameter = 'Failed to remove day bookings! Error: \'{0}\'';
    static DayBookingsRemoveSuccess = 'Day bookings removed successfully!';
    static DayBookingsSaveFailedConcurrencyError = 'Failed to save day bookings, because day bookings have been modified concurrently by another user!';
    static DayBookingsSaveFailedErrorParameter = 'Failed to save day bookings! Error: \'{0}\'';
    static DayBookingsSaveSuccess = 'Day bookings saved successfully!';
    static DetachTasks = 'Detach Tasks';
    static EmployeeIsNotInUse = 'Employee is not in use!';
    static EventLogWorkShiftTimeSlotChanged = 'Working time registration modified';
    static EventLogWorkShiftTimeSlotDeleted = 'Working time registration deleted';
    static InvalidPassword = 'Invalid password!';
    static RefreshTokenIsEmpty = 'Refresh token is empty!';
    static RefreshTokenValidationFailed = 'Refresh token validation failed!';
    static SigningIn = 'Signing In';
    static SigningOut = 'Signing Out';
    static SignInRefresh = 'Sign In Refreshing';
    static OrginalTimeParameter = 'Original time {0}-{1}';
    static InvoiceHoursAreNotCloseToReportedHours = 'Invoiced hours differ greatly from reported hours!';
    static CodeMustBeDefined = 'Code must be defined!';
    static CustomerTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterCustomers = 'Customer type \'{0}\' cannot be removed, because the customer type is used in {1} customers!';
    static CustomerTypeParameterNotFound = 'Customer type \'{0}\' was not found!';
    static CustomerTypeRemoveFailedConcurrencyError = 'Failed to remove customer type, because the customer type has been modified concurrently by another user!';
    static CustomerTypeRemoveFailedErrorParameter = 'Failed to remove customer type! Error: \'{0}\'';
    static CustomerTypeRemoveSuccess = 'Customer type removed successfully!';
    static CustomerTypeSaveFailedConcurrencyError = 'Failed to save customer type, because the customer type has been modified concurrently by another user!';
    static CustomerTypeSaveFailedErrorParameter = 'Failed to save customer type! Error: \'{0}\'';
    static CustomerTypeSaveSuccess = 'Customer type saved successfully!';
    static ProductGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterProducts = 'Product group \'{0}\' cannot be removed, because the product group is used in {1} products!';
    static ProductGroupParameterNotFound = 'Product group \'{0}\' was not found!';
    static ProductGroupRemoveConfirmation = 'Do you really want to remove product group \'{0}\'?';
    static ProductGroupRemoveFailedConcurrencyError = 'Failed to remove product group, because the product group has been modified concurrently by another user!';
    static ProductGroupRemoveFailedErrorParameter = 'Failed to remove product group! Error: \'{0}\'';
    static ProductGroupRemoveSuccess = 'Product group removed successfully!';
    static ProductGroupSaveFailedConcurrencyError = 'Failed to save product group, because the product group has been modified concurrently by another user!';
    static ProductGroupSaveFailedErrorParameter = 'Failed to save product group! Error: \'{0}\'';
    static ProductGroupSaveSuccess = 'Product group saved successfully!';
    static VehicleGroupParameterCannotBeRemovedBecauseItIsRelatedToParameterVehicles = 'Equipment group \'{0}\' cannot be removed, because the equipment group is used in {1} equipment!';
    static VehicleGroupParameterNotFound = 'Equipment group \'{0}\' was not found!';
    static VehicleGroupRemoveFailedConcurrencyError = 'Failed to remove equipment group, because the equipment group has been modified concurrently by another user!';
    static VehicleGroupRemoveFailedErrorParameter = 'Failed to remove equipment group! Error: \'{0}\'';
    static VehicleGroupRemoveSuccess = 'Equipment group removed successfully!';
    static VehicleGroupSaveFailedConcurrencyError = 'Failed to save equipment group, because the equipment group has been modified concurrently by another user!';
    static VehicleGroupSaveFailedErrorParameter = 'Failed to save equipment group! Error: \'{0}\'';
    static VehicleGroupSaveSuccess = 'Equipment group saved successfully!';
    static FilterWorkOrders = 'Filter Orders';
    static ClearFilters = 'Clear Filters';
    static MyWorkList = 'My Work List';
    static SelectedDay = 'Selected Day';
    static SelectedPeriod = 'Selected Period';
    static TimeFilter = 'Time';
    static IntegrationServiceCallFailedErrorParameter = 'Failed to call integration service! Error: \'{0}\'';
    static EmployeeParametersSavedSuccessfully = 'Employee parameters saved successfully!';
    static EmployeeParametersSaveFailedConcurrencyError = 'Failed to save employee parameters, because employee parameters have been modified concurrently by another user!';
    static EmployeeParameterSSaveFailedErrorParameter = 'Failed to save employee parameters! Error: \'{0}\'';
    static ReadyWorkOrders = 'Ready WorkOrders';
    static ToBeInvoicedWorkOrders = 'To Be Invoiced Work Orders';
    static PreliminaryInvoices = 'Preliminary Invoices';
    static TransferredInvoices = 'Transferred Invoices';
    static SsoIntegrations = 'SSO Integrations';
    static SsoParameters = 'SSO Parameters';
    static EmployeeMustBeDefined = 'Employee must be defined!';
    static MeasureUnitTn = 'tn';
    static GoToWorkOrderArchive = 'Go to Work Order Archive';
    static ExportToInvoicingSystem = 'Export to invoicing system';
    static ExportToTransferFile = 'Export to transfer file';
    static FileExportedToInvoicingSystem = 'File \'{0}\' is now exported to the invoicing system';
    static OverlappingWorkShiftTimeSlots = 'Overlapping working time registrations!';
    static ShowSelectedCostCenters = 'Show Selected Cost Centers';
    static ShowSelectedVehicles = 'Show Selected Vehicles';
    static CustomerPriceNotFound = 'Customer specific price was not found!';
    static CustomerPriceRemoveFailedConcurrencyError = 'Failed to remove customer specific price, because the customer specific price has been modified concurrenlty by another user!';
    static CustomerPriceRemoveFailedError = 'Failed to remove customer specific price! Error: {0}';
    static CustomerPriceRemoveSuccess = 'Customer specific price removed successfully!';
    static CustomerPriceSaveFailedConcurrencyError = 'Failed to save customer specific price, because the customer specific price has been modified concurrently by another user!';
    static CustomerPriceSaveFailedError = 'Failed to save customer specific price! Error: {0}';
    static CustomerPriceSaveSuccess = 'Customer specific price saved successfully!';
    static CustomerPriceRemoveConfirmation = 'Do you really want to remove customer price?';
    static CustomerPrices = 'Customer specific prices';
    static CustomerPrice = 'Customer price';
    static APIInvoiceSuccessfullyCreated = 'Invoice {0} successfully created';
    static InvoicesRemoveConfirmation = 'Do you really want to remove selected {0} invoices?';
    static InvoicesRemoveFailedConcurrencyError = 'Failed to remove invoices \'{0}\', because the invoices have been modified concurrently by another user!';
    static InvoicesRemoveFailedErrorParameter = 'Failed to remove invoices \'{0}\'! Error: \'{1}\'';
    static InvoicesRemoveSuccess = 'Invoices \'{0}\' removed successfully!';
    static RelatedToInvoice = 'Related to Invoice';
    static AllWorkOrderClasses = 'All Work Order Classes';
    static WorkOrderClass = 'Work Order Class';
    static WorkOrderProjectTask = 'Project Task';
    static WorkOrderRecurringTask = 'Recurring Task';
    static InvoiceReturnToPreliminaryConfirmation = 'Do you really want to return invoice \'{0}\' to preliminary state? Current invoice will be removed and new invoice will be created for work orders.';
    static InvoicesReturnToPreliminaryConfirmation = 'Do you really want to return selected {0} invoices to preliminary state? Current invoices will be removed and new invoices will be created for work orders.';
    static ReturnToPreliminaryState = 'Return to Preliminary State';
    static WorkOrderDistanceInvoicingByActualDistance = 'Realized distance';
    static WorkOrderDistanceInvoicingByPlannedDistance = 'Planned distance';
    static WorkOrderDistanceInvoicingNone = 'None';
    static UserGroupPermissionAdmin = 'Administrator';
    static UserGroupPermissionEdit = 'User';
    static UserGroupPermissionNone = 'No permission';
    static EstimatedHours = 'Work amount (h)';
    static DetachFromRecurringWork = 'Detach from recurring work';
    static EstimatedHoursMustBeDefined = 'Work amount must be defined!';
    static SetDurationByEstimatedHours = 'Set duration by work amount';
    static WorkOrderParameterDurationChangeFailedConcurrencyError = 'Failed to set duration of work order \'{0}\', because the work order has been modified concurrently by another user!';
    static WorkOrderParameterDurationChangeFailedErrorParameter = 'Failed to set duration of work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderParameterDurationChangeSuccess = 'Work order \'{0}\' duration set successfully!';
    static WorkOrderParameterEstimatedHoursMustBeDefined = 'Work amount of work \'{0}\' must be defined!';
    static WorkOrdersParameterDurationChangeFailedConcurrencyError = 'Failed to set duration of work orders \'{0}\', because work orders have been modified concurrently by another user!';
    static WorkOrdersParameterDurationChangeFailedErrorParameter = 'Failed to set duration of work orders \'{0}\'! Error: \'{1}\'';
    static WorkOrdersParameterDurationChangeSuccess = 'Work orders \'{0}\' duration set successfully!';
    static WorkOrderTaskParameterEstimatedHoursMustBeDefined = 'Work amount of task \'{0}\' must be defined!';
    static And = 'and';
    static Month = 'Month';
    static Occurs = 'Occurs';
    static Year = 'Year';
    static NewProject = '';
    static DayBookingTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterDayBookings = 'Day booking type \'{0}\' cannot be removed, because the day booking type is used in {1} day bookings!';
    static DayBookingTypeParameterNotFound = 'Day booking type \'{0}\' was not found!';
    static DayBookingTypeParameterParameterSaveFailedErrorParameter = 'Failed to save day booking type \'{0}\'! Error: \'{1}\'';
    static DayBookingTypeParameterRemoveFailedConcurrencyError = 'Failed to remove day booking type \'{0}\', because the day booking type has been modified concurrently by another user!';
    static DayBookingTypeParameterRemoveFailedErrorParameter = 'Failed to remove day booking type \'{0}\'! Error: \'{1}\'';
    static DayBookingTypeParameterRemoveSuccess = 'Day booking type \'{0}\' removed successfully!';
    static DayBookingTypeParameterSaveFailedConcurrencyError = 'Failed to save day booking type \'{0}\', because the day booking type has been modified concurrently by another user!';
    static DayBookingTypeParameterSaveSuccess = 'Day booking type \'{0}\' saved successfully!';
    static DayBookingTypesParameterRemoveFailedConcurrencyError = 'Failed to remove day booking types \'{0}\', because day booking types have been modified concurrently by another user!';
    static DayBookingTypesParameterRemoveFailedErrorParameter = 'Failed to remove day booking types \'{0}\'! Error: \'{1}\'';
    static DayBookingTypesParameterRemoveSuccess = 'Day booking types \'{0}\' removed successfully!';
    static RoutingFailedFailedErrorParameter = 'Failed to find route! Error: \'{0}\'';
    static SetLocationFromMap = 'Set location from map';
    static CopyRecurringWork = 'Copy Recurring Work';
    static CopyWorkOrderProject = 'Copy Project';
    static CopyWorkOrderTask = 'Copy Task';
    static CopyWorkOrderWork = 'Copy Work';
    static DataToBeCopied = 'Data to Be Copied';
    static WorkOrderCopyFailedErrorParameter = 'Failed to copy work order \'{0}\'! Error: \'{1}\'';
    static WorkOrderCopySuccess = 'Work order \'{0}\' copied successfully!';
    static EmployeeGroupsVisibleOnDesign = 'Employee Groups Visible on Planning View';
    static VehicleGroupsVisibleOnDesign = 'Vehicle Groups Visible on Design View';
    static GeneralSettings = 'General Settings';
    static MoveToState = 'Move to State';
    static UserProfile = 'User Profile';
    static CustomerPriceParameterNotFound = 'Customer specific price \'{0}\' was not found!';
    static WorkOrderAttachmentToInvoice = 'Add to invoice';
    static DayView = 'Day View';
    static ShowOrdersAs = 'Show orders as';
    static WeekView = 'Weekly View';
    static WeekViewNoaccordions = 'Week view without day accordions';
    static VatRateMustBeDefined = 'VAT must be defined!';
    static EmployeeUsageType = 'Employee Usage';
    static EmployeeUsageTypeMultiple = 'Multiple Employees';
    static EmployeeUsageTypeOne = 'One Employee';
    static HoursInvoicingType = 'Hours Invoicing';
    static HoursInvoicingTypePlanned = 'Based on estimated work amount';
    static HoursInvoicingTypeRealized = 'Based on realized bookings';
    static LocationUsageType = 'Location Data Usage';
    static AttachmentsAreVisible = 'Attachments are Visible';
    static CommentIsVisible = 'Comment is Visible';
    static DescriptionIsVisible = 'Description is Visible';
    static EstimatedHoursAreVisible = 'Work Amount is Visible';
    static MapIsVisible = 'Map is Visible';
    static ToBeInvoicedRecordsAreVisible = 'To Be Invoiced Rows are Visible';
    static SiteRoutePointUsageType = 'Route Point Usage';
    static SiteRoutePointUsageTypeRoutePoint = 'Route points can be added to work order';
    static SiteRoutePointUsageTypeSite = 'Route points of site are displayed';
    static VehicleUsageType = 'Vehicle Usage';
    static VehicleUsageTypeMultiple = 'Multiple Vehicles';
    static VehicleUsageTypeOne = 'One Vehicle';
    static RoutePointTypes = 'Route Point Types';
    static WorkOrderAcknowledgementTypeIsVisible = 'Work Order Acknowledgement Request is Visible';
    static WorkOrderTypeParameterNotFound = 'Work order type \'{0}\' was not found!';
    static RoutePointWorkShiftTimeSlotType = 'Route Point State';
    static RoutePointWorkShiftTimeSlotTypes = 'Route Point States';
    static WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterWorkOrders = 'Work order type \'{0}\' cannot be removed, because the work order type is used in {1} work orders!';
    static WorkOrderTypeParameterParameterSaveFailedErrorParameter = 'Failed to save work order type \'{0}\' ! Error: \'{1}\'';
    static WorkOrderTypeParameterRemoveFailedConcurrencyError = 'Failed to remove work order type \'{0}\', because the work order type has been modified concurrently by another user!';
    static WorkOrderTypeParameterRemoveFailedErrorParameter = 'Failed to remove work order type \'{0}\'! Error: \'{1}\'';
    static WorkOrderTypeParameterRemoveSuccess = 'Work order type \'{0}\' removed successfully!';
    static WorkOrderTypeParameterSaveFailedConcurrencyError = 'Failed to save work order type \'{0}\', because the work order type has been modified concurrently by another user!';
    static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveFailedErrorParameter = 'Failed to remove work order type \'{0}\' route point states! Error: \'{1}\'';
    static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesRemoveSuccess = 'Work order type \'{0}\' route point states removed successfully!';
    static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveFailedErrorParameter = 'Failed to save work order type \'{0}\' route point states! Error: \'{1}\'';
    static WorkOrderTypeParameterWorkOrderTypeWorkShiftTimeSlotTypesSaveSuccess = 'Work order type \'{0}\' route point states saved successfully!';
    static WorkOrderTypeParameterWorkShiftTimeSlotTypeSaveFailedConcurrencyError = 'Failed to save work order type \'{0}\' route point state, because the route point state has been modified concurrently by another user!';
    static WorkOrderTypesParameterRemoveFailedConcurrencyError = 'Failed to remove work order types \'{0}\', because work order types have been modified concurrently by another user!';
    static WorkOrderTypesParameterRemoveFailedErrorParameter = 'Failed to remove work order types \'{0}\'! Error: \'{1}\'';
    static WorkOrderTypesParameterRemoveSuccess = 'Work order types \'{0}\' removed successfully!';
    static WorkOrderTypeTypeParameterSaveSuccess = 'Work order type \'{0}\' saved successfully!';
    static WorkOrderTypeWorkShiftTimeSlotTypeParameterNotFound = 'Route point state \'{0}\' was not found!';
    static WorkOrderTypeParameterCannotBeRemovedBecauseItIsRelatedToParameterInvoices = 'Work order type \'{0}\' cannot be removed, because the work order type is used in {1} invoices!';
    static ShowFilteredWorkOrders = 'Show Filtered Work Orders on Timetable';
    static NoWorkOrders = 'No Work Orders';
    static WorkTimeAccountTransactionSaveSuccess = 'Work time account transaction saved successfully!';
    static WorkTimeAccountTransactionSaveFailed = 'Failed to save work time account transaction! Error: \'{0}\'';
    static AddWorkHoursToBank = 'Add work hours to work hour bank';
    static TakeWorkHoursFromBank = 'Import work hour bank hours to payroll';
    static TransferWorkHours = 'Transfer hours';
    static WorkHourBalance = 'Work hour balance';
    static WorkhourBank = 'Work hour bank transactions';
    static AddWorkOrdersToInvoice = 'Add Work Orders to Invoice';
    static CombineWorkOrdersByWorkOrderTypesToSameInvoice = 'Combine Work Orders by Work Order Types to Same Invoice';
    static InvoiceAddWorkOrdersDifferentWorkOrderTypeConfirmation = 'Selected work orders have different work order type than work order type of the invoice. Do you really want to add selected work orders to the invoice?';
    static InvoiceDataFromWorkOrderType = 'Invoice Data from Work Order Type';
    static InvoiceParameterParameterSaveFailedErrorParameter = 'Failed to save invoice \'{0}\'! Error: \'{1}\'';
    static InvoiceParameterSaveFailedConcurrencyError = 'Failed to save invoice \'{0}\', because the invoice has been modified concurrently by another user!';
    static InvoiceParameterSaveSuccess = 'Invoice \'{0}\' saved successfully!';
    static RouteAndSchedule = 'Route and Schedule';
    static ShowAllCostCenters = 'Show All Cost Centers';
    static ShowAllVehicles = 'Show All Vehicles';
    static Summary = 'Summary';
    static RouteLength = 'Route Length';
    static RoutePhases = 'Route Phases';
    static TotalDuration = 'Total Duration';
    static NoVehicle = 'No Vehicle';
    static Planned = 'Planned';
    static Realized = 'Realized';
    static RoutePointBooking = 'Route Booking';
    static WorkhourBankInUse = 'Work hour bank in use';
    static CreateSalaryDataFailedErrorParameter = 'Failed to create salary data! Error: \'{0}\'';
    static CreateSalaryDataSuccess = 'Salary data created successfully!';
    static EventLogMessageSalaryDataCreated = 'Salary data created';
    static EventLogMessageSalaryTransferred = 'Salary transferred';
    static SalariesTransferredToSalarySystem = 'Salaries are now exported to the salary system';
    static ExportToSalarySystem = 'Export to salary system';
    static SetSalariesToTransferredState = 'Set Salaries to Transferred State';
    static TransferToBePaid = 'Transfer to be Paid';
    static IncludeTransferredSalaries = 'Include Already Transferred Salaries';
    static DataToBeSavedInWorkOrderTemplate = 'Data to Be Saved in Work Order Template';
    static NewProjectBasedOnWorkOrderTemplate = 'New Project Based on Work Order Template';
    static NewWorkBasedOnWorkOrderTemplate = 'New Work Based on Work Order Template';
    static NoWorkOrderTemplates = 'No work order templates!';
    static SaveAsWorkOrderTemplate = 'Save as Work Order Template';
    static SelectWorkOrderTemplate = 'Select Work Order Template';
    static WorkOrderSaveAsWorkOrderTemplateFailedErrorParameter = 'Failed to save work order \'{0}\' as work order template! Error: \'{1}\'';
    static WorkOrderSaveAsWorkOrderTemplateSuccess = 'Work order \'{0}\' saved successfully as work order template!';
    static WorkOrderTemplate = 'Work Order Template';
    static WorkOrderTemplateRemoveConfirmation = 'Do you really want to remove worker order template \'{0}\'?';
    static WorkOrderTemplateRemoveFailedErrorParameter = 'Failed to remove work order template \'{0}\'! Error: \'{1}\'';
    static WorkOrderTemplateRemoveSuccess = 'Work order template \'{0}\' removed successfully!';
    static WorkOrderTemplatesRemoveFailedErrorParameter = 'Failed to remove work order templates \'{0}\'! Error: \'{1}\'';
    static WorkOrderTemplatesRemoveSuccess = 'Work order templates \'{0}\' removed successfully!';
    static NoSalaryDataToTransfer = 'No salary data to transfer!';
    static SalaryDatas = 'Salary Data';
    static WorkQueue = 'Work Queue';
    static AssignToMe = 'Assign To Me';
    static WorkOrderIsShownInWorkQueue = 'Work Order Is Shown in Work Queue';
    static DoYouWantToReturnWorkOrderToWorkQueue = 'Are you sure you want to unassign the task and return the task into the queue ?';
    static ReturnWorkOrderToWorkQueue = 'Return Work Order to Work Queue';
    static UsesWorkQueue = 'Uses work queue';
    static EmployeeGroupAddFailedErrorParameter = 'Failed to add employee group! Error: \'{0}\'';
    static EmployeeGroupAddSuccess = 'Employee group added successfully!';
    static EmployeeGroupsAddFailedErrorParameter = 'Failed to add employee groups! Error: \'{0}\'';
    static EmployeeGroupsAddSuccess = 'Employee groups added successfully!';
    static EmployeeGroupsRemoveFailedErrorParameter = 'Failed to remove employee groups! Error: \'{0}\'';
    static EmployeeGroupsRemoveSuccess = 'Employee groups removed successfully!';
    static SecondaryEmployeeGroups = 'Secondary Employee Groups';
    static WorkQueuePostalCodeRangeEnd = 'Work Queue Postal Code Range End';
    static WorkQueuePostalCodeRangeStart = 'Work Queue Postal Code Range Start';
    static AssignToMeDoStartWorkConfirmation = 'Do you want to start work time recording for work?';
    static AssignToMeWorkOrderConfirmation = 'Do you want assign work order to yourself?';
    static CompletionDate = 'Completion Date';
    static CreationDate = 'Creation Date';
    static WorkOrderAssignToMeFailedErrorParameter = 'Failed to assign work order! Error: \'{0}\'';
    static WorkOrderAssignToMeSuccess = 'Work order assigned successfully!';
    static WorkOrderUnAssignFromEmployeesFailedErrorParameter = 'Failed to return work order to work queue! Error: \'{0}\'';
    static WorkOrderUnAssignFromEmployeesSuccess = 'Work order returned to work queue successfully!';
    static WorkTimeMeasurementStarted = 'Work time measurement started!';
    static PrimaryEmployeeGroup = 'Primary Employee Group';
    static MenuStorage = 'Storage maintain';
    static StorageLocations = 'Storage Locations';
    static StorageReport = 'Report';
    static Storages = 'Storages';
    static NoSelectedItem = 'No selection';
    static SelectedProduct = 'Selected product';
    static SelectedStorage = 'Selected storage';
    static SelectedStorageLocation = 'Selected storage location';
    static SelectedStorageProduct = 'Selected storage product';
    static Storage = 'Storage';
    static StorageLocation = 'Storage location';
    static StorageLocationShort = 'Storage location';
    static StorageProduct = 'Storage product';
    static StorageProductRemoveConfirmation = 'Are you sure you want to remove the storage product?';
    static StorageProductRemoveSuccess = 'Storage product was removed successfully';
    static StorageProducts = 'Storage products';
    static StorageProductSaveSuccess = 'Varastotuote tallennettu';
    static StorageLocationSaveSuccess = 'Storage location saved';
    static StorageLocationCode = 'Code';
    static StorageLocationName = 'Name';
    static StorageLocationRemoveConfirmation = 'Are you sure you want to remove the storage location?';
    static StorageLocationRemoveSuccess = 'Storage location was removed successfully';
    static CreateNewStorageLocation = 'Create storage location';
    static CreateNewStorageProduct = 'Create a new product';
    static ProductAddedToCart = 'Product added to cart';
    static ProductNotFound = 'Product not found';
    static RemoveSelected = 'Remove selected';
    static ConcuranceError = 'Failed to save, because sites have been modified concurrently by another user!';
    static SaveFailed = 'Failed to save. Please contact customer support at asiakaspalvelu@easyopp.fi or by calling +358 29 170 1788.';
    static SaveSuccess = 'Saved!';
    static MultipleStorageLocationRemoveConfirmation = 'Are you sure you want to remove selected storage locations?';
    static MultipleStorageLocationsRemoveSuccess = 'Selected storage locations were removed successfully';
    static NoCustomer = '(No customer)';
    static AreYouSureWantDelete = 'Are you sure want make this delete?';
    static SaveAndClose = 'Save and close';
    static CreateStorageLocations = 'Create storage locations';
    static YouCanAlsoCreateStorageLocations = 'You can also create storage locations for the storage at once.';
    static CreateNewStorage = 'Create new storage';
    static CreateNewStorageText = 'Create new storage by giving it a name and an address.';
    static AddProducts = 'Add products';
    static YouCanAlsoAddProducts = 'You can also add products for storage locations.';
    static EditStorage = 'Edit storage';
    static NameAndAddressMustBeDefined = 'Name and address must be defined';
    static CreateProduct = 'Create product';
    static AddStorageProducts = 'Add storage products';
    static AddStorageProductsText = 'Add storage products for selected locations.';
    static ProductSearch = 'Product search';
    static BookedAmount = 'Reserved';
    static FreeAmount = 'Available';
    static Sum = 'Sum';
    static CustomerSiteAddress = 'Customer\'s site';
    static ProductMustBeDefined = 'Product must be defined';
    static StorageLocationMustBeDefined = 'Storage location must be defined';
    static Code1 = 'Code';
    static OwnerLogo = 'Company logo';
    static OwnerMobileBackgroundImage = 'Mobile app\'s background image';
    static ShowDefaultLogo = 'Replace company logo with EasyOpp logo';
    static CanNotDeleteStorage = 'Deleting is not possible because the storage has storage locations.';
    static DeleteWasSuccess = 'Removing was success.';
    static LocationDataSavingInterval = 'Location Data Saving Interval';
    static LocationDataSavingInUse = 'Location Data Saving Is in Use';
    static LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameterBeforeWorkOrderCanBeMovedToInProgressState = 'Location permission must be granted in the browser settings for this website (\'{0}\') before the work order can be moved to \'In Progress\' state! Battery optimization must also be disabled for browser apps in the system settings to make location data more reliable.';
    static WorkOrdersParameterLocationSaveFailedErrorParameter = 'Failed to save work orders \'{0}\' location! Error: \'{1}\'';
    static WorkOrdersParameterLocationSaveSuccess = 'Work orders \'{0}\' location saved successfully!';
    static LocationPermissionMustBeAllowedInTheDeviceForThisWebSiteParameter = 'Location permission must be granted in the browser settings for this website (\'{0}\')! Battery optimization must also be disabled for browser apps in the system settings to make location data more reliable.';
    static AvailableForBooking = 'Available';
    static ErrorAmountNotEnough = 'Not enough product available';
    static AddToOrder = 'Add to order';
    static CreateNewOrder = 'Create new order';
    static ShoppingCart = 'To product cart';
    static SelectExistingWorkOrder = 'Select existing work order';
    static ShoppingCartProductListTitle = 'Products to add to work order';
    static DeliveryPoint = 'Delivery point';
    static ProductName = 'Product name';
    static NoRows = 'No rows';
    static BookingContainsNotValidLines = 'In saving remarked that someone has booked amounts same time. Please fix lines which are marked to as failed.';
    static SalaryPeriodTypeRemoveConfirmation = 'Do you really want to remove salary period type \'{0}\'?';
    static SalaryPeriodTypes = 'Salary Period Types';
    static InvalidEndDate = 'Invalid end date!';
    static SalaryPeriodType = 'Salary Period Type';
    static SalaryPeriodTypeParameterNotFound = 'Salary period type \'{0}\' was not found!';
    static SalaryPeriodTypeSaveFailedConcurrencyError = 'Failed to save salary period type \'{0}\', because the salary period type has been modified concurrently by another user!';
    static SalaryPeriodTypeSaveFailedErrorParameter = 'Failed to save salary period type \'{0}\'! Error: \'{1}\'';
    static SalaryPeriodTypeSaveSuccess = 'Salary period type \'{0}\' saved successfully!';
    static AddSalaryPeriod = 'Add Salary Period';
    static AddSalaryPeriods = 'Add Salary Periods';
    static ParameterMonths = '{0} months';
    static ParameterWeeks = '{0} weeks';
    static PeriodLength = 'Period Length';
    static SalaryPeriodParameterCannotBeEditedBecauseItIsUsedInParameterSalaries = 'Salary period \'{0}\' cannot be modified, because the salary period is used in {1} salaries!';
    static SalaryPeriodParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries = 'Salary period \'{0}\' cannot be removed, because the salary period is used in {1} salaries!';
    static SalaryPeriodTypeParameterCannotBeRemovedBecauseItIsUsedInParameterSalaries = 'Salary period type \'{0}\' cannot be removed, because the salary period type is used in {1} salaries!';
    static SalaryPeriodTypeParameterRemoveFailedErrorParameter = 'Failed to remove salary period type \'{0}\'! Error: \'{1}\'';
    static SalaryPeriodTypeParameterRemoveSuccess = 'Salary period type \'{0}\' removed successfully!';
    static SalaryPeriodTypeParameterSalaryPeriodsRemoveFailedErrorParameter = 'Failed to remove salary period type \'{0}\' salary periods! Error: \'{1}\'';
    static SalaryPeriodTypeParameterSalaryPeriodsRemoveSuccess = 'Salary period type \'{0}\' salary periods removed successfully!';
    static SalaryPeriodTypeParameterSalaryPeriodsSaveFailedConcurrencyError = 'Failed to save salary period type \'{0}\' salary period, because the salary period has been modified concurrently by another user!';
    static SalaryPeriodTypeParameterSalaryPeriodsSaveFailedErrorParameter = 'Failed to save salary period type \'{0}\' salary periods! Error: \'{1}\'';
    static SalaryPeriodTypeParameterSalaryPeriodsSaveSuccess = 'Salary period type \'{0}\' salary periods saved successfully!';
    static EmployeeGroupsForSalaryCalculationHaveNotBeenSpecified = 'Employee groups for salary calculation have not been specified!';
    static EmployeeGroupForDesign = 'Employee Group for Planning';
    static EmployeeGroupForSalaryCalculation = 'Employee Group for Salary Calculation';
    static EmployeeGroupSalaryPeriodTypeCannotBeRemovedError = 'Salary period type cannot be removed from employee group, because the employee group is used as salary calculation employee group in {0} employees!';
    static SalaryCalculation = 'Salary Calculation';
    static WorkOrderCanBeCreatedInMobileApp = 'Work Order Can Be Created in Mobile App';
    static Extranet = 'Extranet';
    static CommonType = 'Common';
    static StorageInvoice = 'Invoice of storage';
    static ErrorAmountNotEnoughWithName = 'Not enough product available (\'{0}\')! Available amount: {1}.';
    static StorageProductParameterNotFound = 'Storage product \'{0}\' was not found!';
    static ProductsAddedToWorkOrder = 'The products were added successfully.';
    static ProductsWillBeAddedAfterSave = 'The selected products will be added after saving the work order.';
    static AddingProductsToWorkOrder = 'Adding products to work order...';
    static CheckAmounts = 'Check Amounts';
    static ErrorState = 'Error';
    static RoutePointHasProduct = 'Route point has added product. Remove products first. Name:  {0}';
    static DoYouWantSaveChanges = 'Do you want save changes?';
    static ToShoppingCart = 'To product cart';
    static WorkOrderCreationAllowedInMobileApp = 'Work Order Creation Allowed in Mobile App';
    static WorkOrderSequentialTaskExecution = 'Fixed execution order';
    static WorkOrderSequentialTaskExecutionNon = 'Flexible execution order';
    static DailyDeductionHours = 'Daily Deduction Hours';
    static PeriodDeductionHours = 'Period Deduction Hours';
    static SuitableWorkOrderTypesForNewWorkOrderNotFound = 'Work order types that are suitable for new work order not found!';
    static ProductStorageLocationError = 'Product connected to storage locations.';
    static ProductBookingError = 'Product connected to work orders\'s booking.';
    static ProductStorageError = 'Product connected to storage products.';
    static StorageProductRemoveWarning = 'Are your sure you want to remove this product. It won\'t get removed from existing work orders.';
    static WorkOrderSortOrderError = 'Failed to update project\'s task order';
    static WorkOrderSortOrderUpdated = 'Project\'s task order updated';
    static EventLogMessageSiteCreated = 'Site created';
    static ERPCodeMissing = 'Failed to send. ERP-code is missing from employee.';
    static CollectiveAgreement = 'Collective Agreement';
    static ProductsToDeliver = 'Products to deliver';
    static ProductsToFetch = 'Products to fetch';
    static PlannedRoute = 'Planned Route';
    static CancelSetLocationFromMap = 'Cancel setting location from map';
    static SendGridAddressIsMissing = 'SendGrid address is missing';
    static SendGridApiKeyIsMissing = 'SendGrid ApiKey is missing';
    static SendGridSendingMailFailed = 'Failed to send email';
    static HoursInvoicingProduct = 'Hours Invoicing Product';
    static HoursInvoicingTypeNoNotInUse = 'No invoicing';
    static KmsInvoicingProduct = 'Kilometers Invoicing Product';
    static KmsInvoicingType = 'Kilometers Invoicing';
    static KmsInvoicingTypeNotInUse = 'No invoicing';
    static KmsInvoicingTypePlanned = 'Based on planned route estimated distance';
    static KmsInvoicingTypeRealized = 'Based on realized distance';
    static NotSelected = 'Not Selected';
    static RoutePointUsage = 'Route Point Usage';
    static UserInterfaceSettings = 'User Interface Settings';
    static Bookings = 'Bookings';
    static HideMap = 'Hide Map';
    static ShowMap = 'Show Map';
    static UseCollectionListInMobile = 'Use Collection List in Mobile';
    static MarkProductAsCollectedByCheckingRow = 'Mark product as collected by checking the row';
    static StorageProductBookingAmountChangeFailed = 'Failed to change product amount! Reopen work order and try change amount again.';
    static StorageProductBookingRemoveConfirmation = 'Do you want to remove product \'{0}\'?';
    static StorageProductBookings = 'Products';
    static StartingTimeMustOccurBeforeEndingTime = 'Starting time must occur before Ending time!';
    static TaskTypeMustBeSelected = 'Task type must be selected!';
    static Interval = 'Time interval';
    static SelectSalaryType = 'Select salary type';
    static ShowCostCenters = 'Show cost centers';
    static ShowCustomers = 'Show customers';
    static ShowOrders = 'Show orders';
    static InvoiceReport = 'Invoice Report';
    static SalaryReport = 'Sallary report';
    static WorkTimeReport = 'Work time report';
    static OpenBetaVersion = 'Beta-version';
    static ApproveAll = 'Approve all';
    static Approved = 'Approved';
    static SalaryPeriodMissing = 'Salary periods missing. Please add periods.';
    static HourBookingTypesMissing = 'Hour booking types missing.';
    static InvalidTabIndex = 'Invalid tab index';
    static CloseWindow = 'close window';
    static ChooseCustomer = 'Choose customer';
    static ChooseStorage = 'Choose storage';
    static SearchConditions = 'Search conditions';
    static NoOptions = 'No options';
    static SalaryRowTypeParameterNotFound = 'Salary row type \'{0}\' was not found!';
    static SalaryRowTypeRemoveConfirmation = 'Do you really want to remove salary row type?';
    static SalaryRowTypeParameterSaveFailedConcurrencyError = 'Failed to save salary row type, because salary rows have been modified concurrently by another user!';
    static SalaryRowTypeParameterSaveSuccess = 'Salary row type \'{0}\' saved successfully!';
    static SalaryRowTypeParameterParameterSaveFailedErrorParameter = 'Failed to save salary row type \'{0}\'! Error: \'{1}\'';
    static SalaryRowTypeKind = 'Salary row type kind';
    static SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterDayBookingType = 'Salary row type \'{0}\' cannot be removed, because the salary row type is used in {1} day booking type!';
    static SalaryRowTypeParameterRemoveFailedConcurrencyError = 'Failed to remove salary row type \'{0}\', because salary row types have been modified concurrently by another user!';
    static SalaryRowTypeParameterRemoveFailedErrorParameter = 'Failed to remove salary row type \'{0}\'! Error: \'{1}\'';
    static SalaryRowTypesParameterRemoveFailedConcurrencyError = 'Failed to remove salary row types \'{0}\', because salary row types have been modified concurrently by another user!';
    static SalaryRowTypesParameterRemoveFailedErrorParameter = 'Failed to remove salary row types \'{0}\'! Error: \'{1}\'';
    static SalaryRowTypesParameterRemoveSuccess = 'Salary row type \'{0}\' removed successfully!';
    static SalaryRowTypeParameterCannotBeRemovedBecauseItIsInUsed = 'Salary row type \'{0}\' cannot be removed, because the salary row type is in used!';
    static SalaryRowTypeParameterCannotBeRemovedBecauseItIsUsedInParameterHourBookingType = 'Salary row type \'{0}\' cannot be removed, because the salary row type is used in {1} hour booking type!';
    static FieldIsRequired = '\'{0}\' is a required field.';
    static FieldMaxLengthError = 'The maximum length of \'{0}\' is {1} characters.';
    static FieldMinLengthError = 'The minimum length of \'{0}\' is {1} characters.';
    static StorageNotFound = 'Storage not found.';
    static ShowOwnWorkOrders = 'Show own work orders';
    static EditStorageLocation = 'Edit storage location';
    static BillingProduct = 'Billing product';
    static AddProduct = 'Add product';
    static InvalidAmountError = 'Amount must be at least 0.';
    static InvalidValue = 'Invalid value.';
    static StorageLocationNotFound = 'Storage location was not found.';
    static CannotDeleteStorageLocationWithProducts = 'Deleting is not possible because the storage location has products.';
    static ScrollLabelRowsPerPage = 'Rows per page';
    static ShowDetails = 'Show details';
    static RemoveProductFromCart = 'Remove product from cart';
    static CloseCart = 'Close cart';
    static FetchFailed = 'Loading failed';
    static ProductNumber = 'Product number';
    static ProductDescription = 'Product description';
    static CreateNewProduct = 'Create a new product';
    static EditProduct = 'Muokkaa tuotetta';
    static EnterPositiveNumber = 'Enter a positive number.';
    static NewStorageLocationRow = 'Add to storage';
    static InvalidPriceError = 'Price must be at least 0.';
    static NoCustomerPrices = 'No customer prices';
    static CustomerPriceAlreadyExists = 'The selected customer already has a unit price.';
    static AreYouSureWantDeleteStorageProduct = 'Are you sure you want to remove product from storage?';
    static ShowOnlyNotApproved = 'Show Only Not Approved';
    static LockAll = 'Lock all';
    static UnlockAll = 'Unlock all';
    static WorkTimeOriginIntegration = 'Work time received through integration';
    static WorkTimeDifferentFromOriginal = 'Working hours differ from original entry';
    static ApproveAndSave = 'Approve and save';
    static WorkHoursApproveWarning = 'Do you really want to approve and save hours? All current hours will be replaced with calculated hours.';
    static WorkTimeApproval = 'Workhour approval';
    static WorkTimeDetailsHeaderLabel = 'Work time records';
    static WorkTimeType = 'Work Phase';
    static NoSalaryRowType = 'No Salary Row Type';
    static AbrWeek = 'wk';
    static WorkHoursLockRowsWarning = 'Do you really want to lock worktimes?';
    static EmployeeNotFound = 'Employee not found!';
    static RoundAllWorkShiftStartAndEnds = 'Round Workshifts';
    static RoundUnlockedWorkShiftsApproveWarning = 'Unlocked Workshifts will be rounded, do you really want to continue?';
    static PrintStarted = 'Generating PDF, download starts soon';
    static WorkShiftTimeSlotStateInProgressCannotBeChanged = 'Running working time registration cannot be modified!';
    static WorkShiftTimeSlotEndTimeBeforeStartTime = 'End time cannot be before start time!';
    static SalaryRowTypeKindIdMustBeDefined = 'Salary row type kind must be defined';
    static MeasureUnitMustBeDefined = 'Measure Unit must be defined';
    static Vacations = 'Vacations';
    static Absences = 'Absences';
    static AreYouSureYouWantToRemoveTransportPlan = 'Are you sure you want to remove transport plan?';
    static NewPlan = 'New Plan';
    static NewTransportPlan = 'New Transport Plan';
    static NoEmployee = 'No Employee';
    static NonOrders = 'No Orders';
    static RemovePlan = 'Remove Plan';
    static ShowCalendarFrom = 'Show Calendar From';
    static TransportOrders = 'orders';
    static TimeZone = 'Time zone';
    static WorkTimeRoundingInfoInProgressWorkShifts = 'Work shifts in progress have not been rounded.';
    static TransportOrdersInvoices = 'Transport Orders';
    static SelectedOrders = 'Selected orders';
    static CreateNewInvoice = 'Create new invoice';
    static SelectAllDelivered = 'Select all delivered';
    static TransferToInvoice = 'Transfer to invoice';
    static TransferToInvoicing = 'Transfer to invoicing';
    static Definition = 'Definition';
    static OrderNumber = 'Order number';
    static NotDelivered = 'Not delivered';
    static ShowOnlyDelivered = 'Show Only Delivered';
    static Default = 'Default';
    static WorkTimeTypes = 'Work Time Types';
    static Selected = 'Selected';
    static WorkTimeTypeParameterNotFound = 'Work time type \'{0}\' was not found!';
    static WorkTimeTypeRemoveConfirmation = 'Are you sure you want to remove work time type \'{0}\'?';
    static WorkTimeTypeRemoveFailedErrorParameter = 'Failed to remove work time type \'{0}\'! Error: \'{1}\'';
    static WorkTimeTypeRemoveSuccess = 'Work time type \'{0}\' removed successfully!';
    static WorkTimeTypesAlreadyInitialized = 'Work Time Types already initialized';
    static InitializeWorkTimeTypes = 'Initialize Work Time Types';
    static InitializeWorkTimeTypesConfirmation = 'Confirm Initialization of Work Time Types';
    static InitializeWorkTimeTypesSuccess = 'Work Time Types Initialized Successfully';
    static InitializeWorkTimeTypesFailedErrorParameter = 'Initialization of Work Time Types Failed, error: \'{0}\'';
    static ApiKeyLabel = 'Api key';
    static AddTransportToInvoice = 'Add New Transport to Invoice';
    static ApproveInvoice = 'Approve Invoice';
    static InvoiceInformation = 'Invoice Information';
    static InvoiceInformationSaveFailed = 'Failed to save invoice information.';
    static InvoiceInformationSaveSuccess = 'Invoice information saved successfully.';
    static InvoiceNumber = 'Invoice Number';
    static NoInvoiceRows = 'No Invoice Rows';
    static ReferenceInformation = 'Payer\'s Reference';
    static RemoveOrder = 'Remove Order';
    static SaveInvoiceInformation = 'Save Invoice Information';
    static TaxFree = 'Tax-Free';
    static TaxInclusive = 'Tax-Inclusive';
    static TaxInclusiveTotalEUR = 'Tax-Inclusive Total EUR';
    static Transports = 'Transports';
    static Unit = 'Unit';
    static UnitPriceEUR = 'Unit Price EUR';
    static ShowOnlyInvoiceable = 'Show Only Invoiceable';
    static OnTheInvoice = 'On the invoice';
    static NoSelectedOrders = 'No Selected Orders';
    static RemoveInvoice = 'Remove Invoice';
    static FailedToCreateInvoice = 'Failed To Create Invoice!';
    static DisplaySentInvoices = 'Display Sent Invoices';
    static InvoiceApproved = 'Invoice Approved';
    static InvoiceNotApproved = 'Invoice Not Approved';
    static InvoiceSent = 'Invoice Sent';
    static PayersName = 'Payer\'s Name';
    static SentDate = 'Sent Date';
    static TaxExclusiveTotal = 'Tax Exclusive Total';
    static TotalInvoiceAmount = 'Total Invoice Amount';
    static TotalTax = 'Total Tax';
    static TransportOrderInvoices = 'Transport Order Invoices';
    static SearchByInvoiceNumber = 'Search by Invoice Number';
    static SendInvoice = 'Send Invoice';
    static InvoiceDetails = 'Invoice Details';
    static CreateInvoiceSuccess = 'Invoice created successfully!';
    static AddSelected = 'Add Selected';
    static CreateInvoicesFailed = 'Create Invoices Failed!';
    static AddTransportOrdersToInvoiceFailed = 'Failed to add transport orders to invoice!';
    static AddTransportOrdersToInvoiceSuccess = 'Transport orders added to invoice successfully!';
    static CategoryNormal = 'Normal workhour';
    static CategoryPeriodOvertime50 = 'Period overtime 50%';
    static CategoryPeriodOvertime100 = 'Period overtime 100%';
    static CategoryAlternativeSunday = 'Alternative Sunday addition';
    static CategoryBreak = 'Break';
    static CategoryPaidBreak = 'Paid Break';
    static CategoryCustomNormalType = 'Custom normal workhour';
    static CategoryDailyOvertime100 = 'Daily overtime 100%';
    static CategoryDailyOvertime50 = 'Daily overtime 50%';
    static CategoryEvening = 'Evening addition';
    static CategoryHalfDailyAllowance = 'Half daily allowance';
    static CategoryNight = 'Night addition';
    static CategoryDailyAllowance = 'Daily allowance';
    static CategorySalaryInSickTime = 'Sick leave';
    static CategorySaturday = 'Saturday addition';
    static CategorySundayOrHoliday = 'Sunday addition';
    static CategoryWeekOvertime100 = 'Week overtime 100%';
    static CategoryWeekOvertime50 = 'Week over time 50%';
    static CategoryWorkTimeCut = 'Work time cut';
    static InvoiceSendFailed = 'Sending Invoice failed';
    static ShowAllTransportOrders = 'Show All Transport Orders';
    static NotSent = 'Not Sent';
    static Sent = 'Sent';
    static EditInvoice = 'Edit Invoice';
    static CategoryMealBreak = 'Meal break';
    static CategorySecondaryEvening = 'Secondary evening addition';
    static CategoryMidWeekHoliday = 'Mid week holiday';
    static CategoryMidWeekHoliday200 = 'Mid week holiday 200';
    static CategorySecondaryNight = 'Additional saturday addition';
    static Delivered = 'Delivered';
    static DeliveryDetails = 'Delivery Details';
    static PickupDetails = 'Pickup Details';
    static WaybillNumber = 'Waybill Number';
    static TotalPrice = 'Total Price';
    static BillingCustomer = 'Billing Customer';
    static DeliveryTime = 'Time of Delivery';
    static Price = 'Price';
    static WaybillNo = 'Waybill No.';
    static AreYouSureWantRevokeApproval = 'Are you sure you want to revoke the approval?';
    static RevokeApproval = 'Revoke Approval';
    static InvoicingBetaTitle = 'Invoicing Beta';
    static WorkTimeTypeBreak = 'Break';
    static WorkTimeTypeDriving = 'Driving';
    static WorkTimeTypeLoading = 'Loading';
    static WorkTimeTypeOther = 'Other work';
    static WorkTimeTypeUnLoading = 'Unloading';
    static WorkTimeTypeWaiting = 'Waiting';
    static TransportOrderInvoiceFetchFail = 'Fetching Invoice failed';
    static TransportOrderInvoicesFetchFail = 'Fetching Invoices failed';
    static TransportOrdersFetchFail = 'Fetching Transport Orders failed';
    static RemoveFromPlan = 'Remove from plan';
    static Invoiced = 'Invoiced';
    static CategoryPaidWaiting = 'Paid waiting';
    static CategoryUnPaidWaiting = 'Waiting';
    static CategoryOvertimeBase = 'Overtime base';
    static CategoryMonthlyOvertime50 = 'Monthly overtime 50%';
    static CategoryGrandHoliday = 'Grand Holiday';
    static EditRecurringTransportPlan = 'Edit Recurring Transport Plan';
    static CannotAddOrderToCompletedPlan = 'Cannot add a transport order to a completed transport plan.';
    static ThreeMonthTransportPlanQuery = 'Search returns plans starting from the selected date and extending 3 months forward.';
    static TransportPlansFetchFail = 'Failed to fetch transport plans.';
    static AddBreak = 'Add Break';
    static breakRangeError = 'Break duration can be 1-60 minutes';
    static ChangeInInvoicingStateWasSuccessful = 'The change in invoicing state was successful.';
    static Invoiceable = 'Invoiceable';
    static InvoicingStateChangeFailed = 'Invoicing state change failed.';
    static MarkAsInvoiced = 'Mark as Invoiced';
    static ReturnToInvoiceable = 'Return to Invoiceable';
    static TransportOrderInvoicingStateConfirmation = 'Do you really want to change the invoicing state to \'{0}\'?';
    static FixedPriceProductRemoveInformation = 'A Fixed-price product \'{0}\' cannot be removed';
    static OverlappingWorkTimeEntries = 'Failed to save work time entry. Conflicting work time entries are not allowed.';
    static WorkShiftInProgressCannotBeSaved = 'WorkShiftTimeSlot cannot be saved if it overlaps with the current entry and exceeds the current time';
    static DateNotInSalaryPeriod = 'Date cannot be outside salary period.';
    static OverwritingWorkTimeRangeError = 'Work time entry duration must be between 1 minute and 24 hours. Entries longer than 24 hours cannot be saved with overwriting.';
    static CategoryCustomAnyType = 'Custom other work';
    static CategoryOverTimeCalculation = 'Work which has accrued overtime';
    static CategoryPaidMealBreak = 'Paid meal break';
    static CategoryCustomAddition = 'Custom addition';
    static DayBookingLocked = 'Day booking state is locked';
    static DayBookingLockFail = 'Failed to lock Day Bookings! Error: \'{0}\'';
    static DayBookingLockSuccess = 'Day Bookings locked successfully!';
    static DayBookingUnlockSuccess = 'Day Bookings unlocked successfully!';
    static DayBookingUnlockFail = 'Failed to unlock Day Bookings! Error: \'{0}\'';
    static DayBookingStateLocked = 'Day Booking is locked';
    static CategoryFillingHour = 'Filling hour';
    static CategoryFillingHourCalculation = 'Filling hour calculation';
    static CustomerParameterCannotBeRemovedBecauseItIsRelatedToParameterCostCenters = 'Customer \'{0}\' cannot be removed, because the customer is used in {1} cost centers!';
    static EventLogWorkShiftTimeSlotCreated = 'Working time registration created';
    static EventLogWorkShiftTimeSlotFinished = 'Working time registration finished';
    static EventLogWorkShiftTimeSlotLocked = 'Working time registration locked';
    static EventLogWorkShiftTimeSlotStarted = 'Working time registration started';
    static EventLogWorkShiftTimeSlotUnlocked = 'Working time registration unlocked';
    static CategoryFixedAddition = 'Fixed addition';
    static WorkShiftDateHasBeenModified = 'Modifying work time entry has been rejected. Work shift date has been modified.';
}
// -----------------------------------------------------------------
// !!! THIS FILE IS AUTOMATICALLY GENERATED DO NOT EDIT MANUALLY !!!
// -----------------------------------------------------------------
