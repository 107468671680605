import React, { useMemo } from "react";
import CardContainer from "../cardContainer";
import EmployeeCardContent from "./employeeCardContent";
import { EmployeeData } from "../../../models/monitorView/monitorViewTypes";
import { getWorkTimeTypeColorClass } from "../../../models/workShitTimeSlot/workTimeType";
import { WorkShiftDto } from "../../../models/workShift/workShift";
import { getShiftAndSlotDuration } from "../monitorViewUtils";

interface EmployeeCardProps {
    employee: EmployeeData;
    vehicle: {
        registerNumber: string;
        brand: string;
    } | null;
    workShifts: WorkShiftDto[];
    isSelected: boolean;
    onClick: () => void;
}

const EmployeeCard: React.FC<EmployeeCardProps> = ({ employee, vehicle, workShifts, isSelected, onClick }) => {
    const workShiftTimeSlotDuration = useMemo(() => getShiftAndSlotDuration(workShifts, employee.employeeId), [workShifts, employee.employeeId]);
    const { shiftDuration, timeSlotDuration } = workShiftTimeSlotDuration;
    return (
        <CardContainer isSelected={isSelected} onClick={onClick}>
            <EmployeeCardContent
                effectiveDate={workShiftTimeSlotDuration.effectiveDate}
                employeeId={employee.employeeId}
                driver={vehicle ? `${vehicle.registerNumber} (${vehicle.brand})` : ""}
                mainTitle={employee.employeeName}
                shiftDuration={shiftDuration}
                timeSlotDuration={timeSlotDuration}
                workTimeStatusIconClass={`text-${getWorkTimeTypeColorClass(employee.workTimeTypeType)}`}
                workTimeTypeName={employee.workTimeTypeName}
            />
        </CardContainer>
    );
};

export default EmployeeCard;