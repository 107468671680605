import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { showApiError, showApiSuccess } from "../framework/formUtils";
import { getOverlappingWorkShiftTimeSlots, saveWorkShiftTimeSlot } from "../../services/workTimeBetaService";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { IWorkTimeFormItem, TaskType } from "./workTimeDetailsForm";
import { useParams } from "react-router-dom";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { Base } from "../../framework/base";
import { CompactTimeline } from "./compactTimeline";
import { WorkTimeSaveData } from "../../models/workShitTimeSlot/workTimeSaveData";
import { Translations } from "../../models/translations";
import { AppUtils } from "../../models/common/appUtils";
import { OverlappingSlotsQueryData } from "../../models/workShitTimeSlot/overlappingSlotsQueryData";
import { EditDialog } from "../framework/editDialog";
import { useAppDispatch } from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { useTranslation } from "react-i18next";

interface IOverlappingSaveDialogProps {
    open: boolean;
    onClose: () => void;
    workTime: IWorkTimeFormItem;
    clearForm: () => void;
    afterSave: () => void;
    editItem?: WorkShiftTimeSlotItem;
}

export const OverlappingSaveDialog = ({ open, onClose, workTime, clearForm, afterSave, editItem }: IOverlappingSaveDialogProps) => {
    const [overlappingSlots, setOverlappingSlots] = useState<WorkShiftTimeSlotItem[]>();
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleClose = () => {
        onClose();
    };

    const mapFormItemToSaveData = (formItem: IWorkTimeFormItem): WorkTimeSaveData => {
        return {
            id: editItem?.id || "",
            rowId: editItem?.rowId || "",
            employeeId: employeeId,
            vehicleId: formItem.vehicle?.value || "",
            workTimeTypeId: formItem.workTimeTypeId?.value || "",
            salaryRowTypeId: formItem.salaryRowTypeId?.value || "",
            costCenterId: formItem.taskType === TaskType.CostCenter ? formItem.costCenter?.value || "" : "",
            workOrderId: formItem.taskType === TaskType.Order ? formItem.workOrder?.value || "" : "",
            comment: formItem.description,
            startDate: formItem.startDate || "",
            endDate: formItem.endDate || "",
            timeZoneName: formItem.timeZoneName?.value || "",
        };
    };

    const calculateDuration = (startDate, endDate, short=false) => {
        const totalMinutes = Base.dateDiffInMinutes(new Date(startDate), new Date(endDate));
        if (short) return AppUtils.getDurationStrByDurationMinShort(totalMinutes);
        return AppUtils.getDurationStrByDurationMin(totalMinutes);
    };

    const formatTime = (time: string) =>
        Base.isSameDay(Base.dateStrToDayjsIgnoreTimezone(time), date)
            ? Base.dateStrToOriginalTimezoneTimeStr(time)
            : Base.dateStrToOriginalTimezoneDateTimeStr(time);

    const formatDates = (slot: WorkShiftTimeSlotItem) => {
        return `${formatTime(slot.startDate)} - ${slot.endDate ? formatTime(slot.endDate) : ""} (UTC ${Base.dateTzOffset(slot.startDate, slot.timeZoneName)})`;
    };

    const handleConfirmShiftOverwrite = () => {
        dispatch(
            storeActions.setConfirmation(
                ConfirmationDialogType.Warning,
                Translations.Warning,
                t("workTime.confirmSaveForModifiedShift"),
                () => {
                    dispatch(storeActions.clearConfirmation());
                    handleOverwrite(true);
                },
                () => {
                    dispatch(storeActions.clearConfirmation());
                }
            )
        );
    };

    const handleOverwrite = (allowShiftOverwrite = false) => {
        const saveData = mapFormItemToSaveData(workTime);
        const allowOverwrite = true;
        saveWorkShiftTimeSlot(
            saveData,
            allowOverwrite,
            true,
            allowShiftOverwrite
        )
            .then(() => {
                showApiSuccess(Translations.SaveSuccess);
                clearForm();
                afterSave();
                handleClose();
            })
            .catch((error) => {
                if (
                    error.message === Translations.WorkShiftDateHasBeenModified
                ) {
                    handleConfirmShiftOverwrite();
                } else {
                    showApiError(error);
                }
            });
    };

    const fetchOverLappingSlots = async() => {
        if (workTime && open && workTime.startDate && workTime.endDate) {
            try {
                const queryData: OverlappingSlotsQueryData = {
                    startDate: workTime.startDate,
                    endDate: workTime.endDate,
                    timeZoneName: workTime.timeZoneName.value,
                    employeeId: employeeId
                };
                const slots = await getOverlappingWorkShiftTimeSlots(queryData);
                setOverlappingSlots(slots.filter(s => s.id !== editItem?.id));
            } catch (error) {
                showApiError(AppUtils.translate("workTime.overlappingFetchFailed"));
            }
        }
    };

    useEffect(() => {
        void fetchOverLappingSlots();
    }, [open, workTime]);

    const primaryActions = [
        <Button
            onClick={() => handleOverwrite()}
            color="success"
            variant="contained"
            fullWidth
        >
            {AppUtils.translate("workTime.saveOverwriting")}
        </Button>,
        <Button
            onClick={handleClose}
            variant="outlined"
            color="success"
            fullWidth
        >
            {AppUtils.translate("common.cancelSave")}
        </Button>,
    ];

    return (
        workTime && (
            <>
                <EditDialog
                    open={open}
                    onClose={handleClose}
                    title={AppUtils.translate(
                        "workTime.conflictingEntriesNotAllowed"
                    )}
                    body={
                        <>
                            <Typography gutterBottom variant="subtitle2">
                                {AppUtils.translate("workTime.overlapDetected")}
                                :
                            </Typography>
                            {overlappingSlots?.map((ds) => (
                                <Typography
                                    key={ds.id}
                                    color="error"
                                    gutterBottom
                                    variant="subtitle2"
                                >
                                    {formatDates(ds)}
                                </Typography>
                            ))}
                            {overlappingSlots && (
                                <CompactTimeline
                                    overlappingSlots={overlappingSlots}
                                    newSlot={workTime}
                                    formatTime={formatTime}
                                    calculateDuration={calculateDuration}
                                />
                            )}
                        </>
                    }
                    primaryActions={primaryActions}
                ></EditDialog>
            </>
        )
    );
};
