import React from "react";
import { Box, Grid } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import PersonIcon from "@mui/icons-material/Person";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Text, BoldText, Subtitle } from "../styledComponents";

interface VehicleCardContentProps {
    driver: string;
    mainTitle: string;
    speed: string;
    status: string;
    subTitle: string;
    vehicleStatusDuration: string;
    vehicleStatusIconClass: string;
    workTimeStatusIconClass: string;
    workTimeTypeName: string;
}

const VehicleCardContent: React.FC<VehicleCardContentProps> = ({
    driver,
    mainTitle,
    speed,
    status,
    subTitle,
    vehicleStatusDuration,
    vehicleStatusIconClass,
    workTimeStatusIconClass,
    workTimeTypeName
}) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <TitleSection mainTitle={mainTitle} subTitle={subTitle} />
            </Grid>
            <Grid item xs={12} sm={7}>
                <StatusSection
                    driver={driver}
                    status={status}
                    vehicleStatusIconClass={vehicleStatusIconClass}
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <DetailsSection
                    speed={speed}
                    vehicleStatusDuration={vehicleStatusDuration}
                    vehicleStatusIconClass={vehicleStatusIconClass}
                    workTimeStatusIconClass={workTimeStatusIconClass}
                    workTimeTypeName={workTimeTypeName}
                />
            </Grid>
        </Grid>
    );
};

const TitleSection: React.FC<{ mainTitle: string; subTitle: string }> = ({ mainTitle, subTitle }) => (
    <Grid container alignItems="center">
        <Grid item xs={12}>
            <BoldText variant="h6"> { mainTitle } </BoldText>
            <Subtitle variant="subtitle1"> { subTitle } </Subtitle>
        </Grid>
    </Grid>
);

const StatusSection: React.FC<{
    driver: string;
    status: string;
    vehicleStatusIconClass: string;
}> = ({
    driver,
    status,
    vehicleStatusIconClass,
}) => {
    return (
        <Grid container>
            <Grid item xs={12} container alignItems="center">
                <Grid item>
                    <Box sx={{ marginRight: 1 }} className={vehicleStatusIconClass}>
                        <LocalShippingIcon />
                    </Box>
                </Grid>
                <Grid item>
                    <BoldText> { status } </BoldText>
                </Grid>
            </Grid>
            { driver && (
                <Grid item xs={12} container alignItems="center">
                    <Grid item>
                        <Box sx={{ marginRight: 1 }}>
                            <PersonIcon />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Text> { driver } </Text>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const DetailsSection: React.FC<{
    speed: string;
    vehicleStatusDuration: string;
    vehicleStatusIconClass: string;
    workTimeStatusIconClass: string;
    workTimeTypeName: string;
}> = ({
    speed,
    vehicleStatusDuration,
    vehicleStatusIconClass,
    workTimeStatusIconClass,
    workTimeTypeName
}) => (
    <Grid container sx={{ textAlign: "right" }}>
        {vehicleStatusDuration && (
            <Grid item xs={12}>
                <BoldText className={vehicleStatusIconClass}>
                    { vehicleStatusDuration }
                </BoldText>
                { speed && (
                    <BoldText sx={{ marginLeft: 1, color: "gray !important" }}>
                        { speed }
                    </BoldText>
                )}
            </Grid>
        )}
        {workTimeTypeName && (
            <Grid item xs={12}>
                <CircleIcon className={workTimeStatusIconClass} sx={{ display: "inline-block" }} />
                <BoldText className={workTimeStatusIconClass} sx={{ marginLeft: 1 }}>
                    { workTimeTypeName }
                </BoldText>
            </Grid>
        )}
    </Grid>
);

export default VehicleCardContent;