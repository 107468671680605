import React from "react";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import EmployeeCard from "./employeeCard/employeeCard";
import { SidebarProps, EmployeeData } from "../../models/monitorView/monitorViewTypes";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import { fetchSalaryPeriods, selectSalaryPeriods } from "../../store/salaryPeriodSlice";
import { handleApiError } from "../../models/store/storeEffects";
import { fetchSalaryRowTypes, selectSalaryRowTypes } from "../../store/workShiftTimeSlotSlice";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";

export const MonitorViewEmployeesSidebar = ({
    data,
    selectedId: selectedEmployeeId,
    setSelectedId: setSelectedEmployeeId,
}: SidebarProps<EmployeeData>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const salaryPeriods = useAppSelector(selectSalaryPeriods);
    const salaryRowTypes = useAppSelector(selectSalaryRowTypes);

    useAsyncThunkAction(
        () => fetchSalaryPeriods(),
        { onError: (e) => handleApiError(e, dispatch), },
        [],
        !salaryPeriods?.ids?.length // Only fetch if salaryPeriods is empty
    );

    useAsyncThunkAction(
        () => fetchSalaryRowTypes(),
        { onError: (e) => handleApiError(e, dispatch), },
        [],
        !salaryRowTypes?.length // Only fetch if salaryRowTypes is empty
    );

    return (
        data?.length > 0 ? (
            data.map((e: EmployeeData) => (
                <EmployeeCard
                    key={e.employeeId}
                    employee={e}
                    vehicle={e.vehicle}
                    isSelected={e.employeeId === selectedEmployeeId}
                    onClick={() => setSelectedEmployeeId(e.employeeId)}
                    workShifts={e.workShifts}
                />
            ))
        ) : (
            <Alert variant="standard" severity="info">
                {t("employee.noRows")}
            </Alert>
        )
    );
};