import React from "react";
import CardContainer from "../cardContainer";
import VehicleCardContent from "./vehicleCardContent";
import { getWorkTimeTypeColorClass } from "../../../models/workShitTimeSlot/workTimeType";
import { VehicleData } from "../../../models/monitorView/monitorViewTypes";
import { getVehicleStatusText } from "../monitorViewUtils";

interface VehicleCardProps {
    vehicle: VehicleData;
    driver: string;
    isSelected: boolean;
    onClick: () => void;
}

const VehicleCard: React.FC<VehicleCardProps> = ({ vehicle, driver, isSelected, onClick }) => {
    const getVehicleStatusDuration = () => {
        if (vehicle.drivingGmt) {
            const startDate = new Date(vehicle.drivingGmt).getTime();
            const duration = Date.now() - startDate;
            const minutes = Math.floor(duration / 60000);
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            if (hours > 0) {
                return `${hours} h ${remainingMinutes} min`;
            } else {
                return `${remainingMinutes} min`;
            }
        }
        return "";
    };

    const getVehicleStatusColorClass = (type: number) => {
        switch (type) {
            case null:
                return "black";
            case -1:
                return "black";
            case 0:
                return "red";
            case 1:
                return "green-light";
            case 2:
                return "green-light";
            default:
                return "black";
        }
    };

    return (
        <CardContainer isSelected={isSelected} onClick={onClick}>
            <VehicleCardContent
                driver={driver ? driver : "Ei kuljettajaa"}
                mainTitle={vehicle.registerNumber}
                speed={`${vehicle.speed} km/h`}
                status={getVehicleStatusText(vehicle.driving)}
                subTitle={vehicle.brand}
                vehicleStatusDuration={getVehicleStatusDuration()}
                vehicleStatusIconClass={`text-${getVehicleStatusColorClass(vehicle.driving)}`}
                workTimeStatusIconClass={`text-${getWorkTimeTypeColorClass(vehicle.workTimeTypeType)}`}
                workTimeTypeName={vehicle.employeeWorkTimeTypes[0]?.workTimeTypeName || ""}
            />
        </CardContainer>
    );
};

export default VehicleCard;