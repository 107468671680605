import { useCallback, useEffect, useRef, useState } from "react";
import * as store from "../framework/customStore";
import * as baseService from "../services/baseService";
import * as storeActions from "../models/store/storeActions";

export default function useForm(
    save: (formData: any, ...args: any[]) => Promise<any>,
    afterSave: () => void,
    params: object,
    type?: any,
    successMessage?: string,
    onSuccess?: (result: any) => void,
    onError?: (error: any) => boolean
): [
    React.MutableRefObject<HTMLFormElement>,
    boolean,
    (...args: any[]) => Promise<boolean>
] {
    const formRef = useRef<HTMLFormElement>();
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = useCallback(
        (e?: SubmitEvent, ...extraArgs: any[]) => {
            e?.preventDefault();
            setSubmitting(true);

            const formData: FormData = new FormData(formRef.current);
            Object.keys(params || {}).forEach((k) =>
                formData.set(k, params[k])
            );

            return save(
                type ? Object.fromEntries(formData.entries()) : formData,
                ...extraArgs
            )
                .then((success) => {
                    if (onSuccess) {
                        onSuccess(success);
                    }
                    store.customStore.dispatch(
                        storeActions.showSuccessMessage(
                            successMessage ?? success.message
                        )
                    );
                    return true;
                })
                .catch((error) => {
                    let showErrorMessage = true;
                    if (onError) {
                        showErrorMessage = onError(error);
                    }
                    if (showErrorMessage) {
                        store.customStore.dispatch(
                            storeActions.showErrorMessage(
                                baseService.getErrorMessageFromError(error)
                            )
                        );
                    }
                    return false;
                })
                .finally(() => {
                    setSubmitting(false);
                    afterSave();
                });
        },
        [save, params, afterSave]
    );

    const submit = (...args: any[]) => handleSubmit(undefined, ...args);

    useEffect(() => {
        const form: HTMLFormElement = formRef.current;

        if (form) {
            form.addEventListener("submit", handleSubmit);
        }

        return () => {
            if (form) {
                form.removeEventListener("submit", handleSubmit);
            }
        };
    }, [handleSubmit]);

    return [formRef, submitting, submit];
}
