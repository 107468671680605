import React from "react";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

const StyledAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.lightBlue.main,
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[1],
    "& .MuiAccordionSummary-content": {
        alignItems: "center",
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
}));

interface AccordionItem {
    key?: string; // Needed if header is not string.
    header: string | JSX.Element;
    subheader?: string;
    content: JSX.Element | JSX.Element[];
}

interface Props {
    items: AccordionItem[];
}

const AccordionGroup = ({ items }: Props) => {
    const [expanded, setExpanded] = useState<string | false>(false);

    useEffect(() => {
        if (items.length > 0) {
            setExpanded(items[0].key ?? (items[0].header as string));
        }
    }, [items]);

    const handleChange = (panel: string, content: JSX.Element | JSX.Element[]) => (_: React.SyntheticEvent, newExpanded: boolean) => {
        if (Object.keys(content).length > 0) {
            setExpanded(newExpanded ? panel : false);
        }
    };

    return items.map((i) => (
        <StyledAccordion
            key={i.key ?? (i.header as string)}
            expanded={expanded === (i.key ?? (i.header as string))}
            onChange={handleChange(i.key ?? (i.header as string), i.content)}
            component={Card}
        >
            <AccordionSummary
                expandIcon={Object.keys(i.content).length > 0 ? <ExpandMoreIcon /> : null}
                sx={{ py: 1 }}
            >
                <>
                    <Typography variant="h5" sx={{ width: "50%", flexGrow: 1 }}>
                        {i.header}
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{ color: "success.main", ml: 1, mr: 1 }}
                    >
                        {i.subheader}
                    </Typography>
                </>
            </AccordionSummary>
            <StyledAccordionDetails>{i.content}</StyledAccordionDetails>
        </StyledAccordion>
    ));
};

export default AccordionGroup;

interface AccordionDetailsItemProps {
    title: string;
    hours: string;
}

export const AccordionDetailsItem = ({
    title,
    hours,
}: AccordionDetailsItemProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box
                sx={{
                    textAlign: "left",
                    pt: 1,
                }}
            >
                <Typography sx={{ color: "primary.dark" }}>{title}</Typography>
            </Box>
            <Box
                sx={{
                    pt: 1,
                    textAlign: "right",
                    whiteSpace: "nowrap",
                }}
            >
                <Typography sx={{ color: "success.main" }}>{hours}</Typography>
            </Box>
        </Box>
    );
};