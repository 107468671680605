//SettingsEmployeeGroupDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as employeeGroupService from "../../services/employeeGroupService";
import { PropertyDialog } from "../framework/dialog";
import { SaveData } from "../../framework/saveData";
import { IEmployeeGroupEdit } from "../../models/employeeGroup/employeeGroupEdit";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";
import { CheckBox } from "../framework/checkbox";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";
import { SalaryPeriodTypeItem, ISalaryPeriodTypeItem } from "../../models/salaryPeriodType/salaryPeriodTypeItem";
import { CollectiveAgreementItem, ICollectiveAgreementItem } from "../../models/collectiveAgreement/collectiveAgreementItem";
import { t } from "i18next";
import { WorkTimeType } from "../../models/workShitTimeSlot/workTimeType";
import { NumberInput } from "../framework/numberInput";
import MuiSelect from "../framework/muiSelect";

// SettingsEmployeeGroupDialog
// ***********************************************************************************************************************
export interface ISettingsEmployeeGroupDialogProp {
    classes?: string;
    editItem: IEmployeeGroupEdit;
    onOk: () => void;
    onCancel: () => void;
}

export interface ISettingsEmployeeGroupDialogState {
    employments: IIdTitle[];
    salaryPeriodTypes: ISalaryPeriodTypeItem[];
    employmentId: string;
    salaryPeriodTypeId: string;
    workTimeTypes: WorkTimeType[];
    name: string;
    description: string;
    workQueueInUse: boolean;
    locationDataInUse: boolean;
    canCreateWorkOrderInMobile: boolean;
    collectiveAgreements: ICollectiveAgreementItem[];
    collectiveAgreementId: string;
    workTimeAutoStartEnabled: boolean;
    workTimeAutoEndEnabled: boolean;
    workTimeAutoDrivingEnabled: boolean;
    workTimeAutoStopEnabled: boolean;
    workTimeAutoStopWorkTimeTypeId: string;
    workTimeAutoDrivingThreshold: number;
    workTimeAutoStopThreshold: number;
    paymentGroup: string;
    canEditWorkEntries: boolean;
}

export class SettingsEmployeeGroupDialog extends React.Component<ISettingsEmployeeGroupDialogProp, ISettingsEmployeeGroupDialogState> {
    private orgStateHash: string = "";

    constructor(props: ISettingsEmployeeGroupDialogProp) {
        super(props);
        const employeeGroup = props.editItem.employeeGroup;
        const employments = props.editItem.employments.slice(0);
        if (employments.length > 0) {
            employments.unshift(IdTitle.createIdTitle("", ""));
        }
        const salaryPeriodTypes = props.editItem.salaryPeriodTypes.slice(0);
        salaryPeriodTypes.unshift(new SalaryPeriodTypeItem());
        const collectiveAgreements = props.editItem.collectiveAgreements.slice(0);
        collectiveAgreements.unshift(new CollectiveAgreementItem());
        this.state = {
            employments: employments,
            salaryPeriodTypes: salaryPeriodTypes,
            employmentId: employeeGroup.employmentId,
            salaryPeriodTypeId: employeeGroup.salaryPeriodTypeId,
            name: employeeGroup.name,
            description: employeeGroup.description,
            workQueueInUse: employeeGroup.workQueueInUse,
            locationDataInUse: employeeGroup.locationDataInUse,
            canCreateWorkOrderInMobile: employeeGroup.canCreateWorkOrderInMobile,
            collectiveAgreements: collectiveAgreements,
            collectiveAgreementId: employeeGroup.collectiveAgreementId,
            workTimeTypes: props.editItem.workTimeTypes.slice(0),
            workTimeAutoStartEnabled: employeeGroup.workTimeAutoStartEnabled,
            workTimeAutoEndEnabled: employeeGroup.workTimeAutoEndEnabled,
            workTimeAutoDrivingEnabled: employeeGroup.workTimeAutoDrivingEnabled,
            workTimeAutoStopEnabled: employeeGroup.workTimeAutoStopEnabled,
            workTimeAutoStopWorkTimeTypeId: employeeGroup.workTimeAutoStopWorkTimeTypeId,
            workTimeAutoDrivingThreshold: employeeGroup.workTimeAutoDrivingThreshold,
            workTimeAutoStopThreshold: employeeGroup.workTimeAutoStopThreshold,
            paymentGroup: employeeGroup.paymentGroup,
            canEditWorkEntries: employeeGroup.canEditWorkEntries,
        };
        const saveData = SettingsEmployeeGroupDialog.getSaveDataFromState(props, this.state);
        this.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name = target.name as keyof ISettingsEmployeeGroupDialogState;
        if (name === "name") {
            this.setState({ name: value });
        } else if (name === "description") {
            this.setState({ description: value });
        } else if (name === "employmentId") {
            this.setState({ employmentId: value });
        } else if (name === "salaryPeriodTypeId") {
            this.setState({ salaryPeriodTypeId: value });
        } else if (name === "collectiveAgreementId") {
            this.setState({ collectiveAgreementId: value });
        } else if (name === "paymentGroup") {
            this.setState({ paymentGroup: value });
        }
    };

    handleChangeBoolean = (name: keyof ISettingsEmployeeGroupDialogState, value: boolean) => {
        if (name === "workQueueInUse") {
            this.setState({ workQueueInUse: value });
        } else if (name === "locationDataInUse") {
            this.setState({ locationDataInUse: value });
        } else if (name === "canCreateWorkOrderInMobile") {
            this.setState({ canCreateWorkOrderInMobile: value });
        } else if (name === "workTimeAutoStartEnabled") {
            this.setState({ workTimeAutoStartEnabled: value });
        } else if (name === "workTimeAutoEndEnabled") {
            this.setState({ workTimeAutoEndEnabled: value });
        } else if (name === "workTimeAutoDrivingEnabled") {
            this.setState({ workTimeAutoDrivingEnabled: value });
        } else if (name === "workTimeAutoStopEnabled") {
            this.setState({ workTimeAutoStopEnabled: value });
        } else if (name === "canEditWorkEntries") {
            this.setState({ canEditWorkEntries: value });
        }
    };
    // #endregion General

    handleDrivingThresholdChange = (value: number) => {
        this.setState({ workTimeAutoDrivingThreshold: value });
    }

    handleStopThresholdChange = (value: number) => {
        this.setState({ workTimeAutoStopThreshold: value });
    }

    handleStopWorkTimeTypeChange = (value: string) => {
        this.setState({ workTimeAutoStopWorkTimeTypeId: value });
    }

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            if (!state.name) {
                AppUtils.showErrorMessage(Translations.NameMustBeDefined);
                return resolve(false);
            }
            if (state.workTimeAutoDrivingEnabled && state.workTimeAutoDrivingThreshold < 1) {
                AppUtils.showErrorMessage(t("workTime.drivingThresholdValidation"));
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static getSaveDataFromState = (props: ISettingsEmployeeGroupDialogProp, state: ISettingsEmployeeGroupDialogState): SaveData => {
        const data = new SaveData();
        const employeeGroup = props.editItem.employeeGroup;
        // Common
        data.append("id", employeeGroup.id);
        data.append("rowId", employeeGroup.rowId);
        // General
        data.append("employmentId", state.employmentId ? state.employmentId : String(null));
        data.append("salaryPeriodTypeId", state.salaryPeriodTypeId ? state.salaryPeriodTypeId : String(null));
        data.append("name", state.name);
        data.append("description", state.description);
        data.append("workQueueInUse", state.workQueueInUse.toString());
        data.append("locationDataInUse", state.locationDataInUse.toString());
        data.append("canCreateWorkOrderInMobile", state.canCreateWorkOrderInMobile.toString());
        data.append("collectiveAgreementId", state.collectiveAgreementId ? state.collectiveAgreementId : String(null));
        data.append("workTimeAutoStartEnabled", state.workTimeAutoStartEnabled.toString());
        data.append("workTimeAutoEndEnabled", state.workTimeAutoEndEnabled.toString());
        data.append("workTimeAutoDrivingEnabled", state.workTimeAutoDrivingEnabled.toString());
        data.append("workTimeAutoStopEnabled", state.workTimeAutoStopEnabled.toString());
        data.append("workTimeAutoStopWorkTimeTypeId", state.workTimeAutoStopWorkTimeTypeId.toString());
        data.append("workTimeAutoDrivingThreshold", state.workTimeAutoDrivingThreshold.toString());
        data.append("workTimeAutoStopThreshold", state.workTimeAutoStopThreshold.toString());
        data.append("paymentGroup", state.paymentGroup);
        data.append("canEditWorkEntries", state.canEditWorkEntries.toString());

        return data;
    };

    saveEditItem = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        const saveData = SettingsEmployeeGroupDialog.getSaveDataFromState(props, state);
        //Call server
        const result = await AppUtils.callService(() => employeeGroupService.saveEmployeeGroupEdit(saveData.formData));
        if (!result) return;
        props.onOk();
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = async() => {
        const props = this.props;
        const state = this.state;
        const cancelResult = await AppUtils.cancel(SettingsEmployeeGroupDialog.getSaveDataFromState(props, state).hash, this.orgStateHash);
        if (cancelResult === ConfirmationDialogResult.Cancel) return;
        if (cancelResult === ConfirmationDialogResult.Yes) {
            this.saveEditItem();
        } else {
            props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const editItem = props.editItem;
        const dialogClasses = "settings employeeGroup px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.EmployeeGroup + " - " + (!editItem.employeeGroup.isNew() ? editItem.employeeGroup.name : Translations.New)}
                    show={true}
                    body={
                        <div>
                            <div className="">
                                <div>
                                    <div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group required">
                                                    <label className="control-label smallFont">{Translations.Name}</label>
                                                    <input type="text" className="form-control" name="name" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="control-label smallFont">&nbsp;</label>
                                                    <CheckBox
                                                        title={Translations.LocationDataSavingInUse}
                                                        enabled={true}
                                                        checked={state.locationDataInUse}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("locationDataInUse", checked); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="control-label smallFont">{Translations.Description}</label>
                                                    <textarea className="form-control" name="description" title={Translations.Description} value={state.description} onChange={this.handleChange} maxLength={1000} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="commandRow">
                                            <label className="control-label listTitle">{t("workTime.integrationAutomations")}</label>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <CheckBox
                                                        title={t("workTime.workStartAutomation")}
                                                        enabled={true}
                                                        checked={state.workTimeAutoStartEnabled}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("workTimeAutoStartEnabled", checked); }}
                                                    />
                                                    <CheckBox
                                                        title={t("workTime.workEndautomation")}
                                                        enabled={true}
                                                        checked={state.workTimeAutoEndEnabled}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("workTimeAutoEndEnabled", checked); }}
                                                    />
                                                    <CheckBox
                                                        title={t("workTime.drivingAutomation")}
                                                        enabled={true}
                                                        checked={state.workTimeAutoDrivingEnabled}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("workTimeAutoDrivingEnabled", checked); }}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <NumberInput size="small" value={state.workTimeAutoDrivingThreshold} label={t("workTime.drivingThreshold")} onChange={this.handleDrivingThresholdChange} disabled={!state.workTimeAutoDrivingEnabled} />
                                                </div>
                                                <div className="form-group">
                                                    <CheckBox
                                                        title={t("workTime.stopAutomation")}
                                                        enabled={true}
                                                        checked={state.workTimeAutoStopEnabled}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("workTimeAutoStopEnabled", checked); }}
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <NumberInput size="small" value={state.workTimeAutoStopThreshold} label={t("workTime.stopThreshold")} onChange={this.handleStopThresholdChange} disabled={!state.workTimeAutoStopEnabled} />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <MuiSelect
                                                                label={t("workTime.autoStopWorkTimeType")}
                                                                size="small"
                                                                value={state.workTimeAutoStopWorkTimeTypeId}
                                                                onChange={this.handleStopWorkTimeTypeChange}
                                                                noSelectionLabel="Työajan lopetus"
                                                                displayNoSelectionLabel
                                                                options={state.workTimeTypes.map((workTimeType) => ({ value: workTimeType.workTimeTypeId, label: workTimeType.name}))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="commandRow">
                                            <label className="control-label listTitle">{Translations.Planning}</label>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <CheckBox
                                                        title={Translations.UsesWorkQueue}
                                                        enabled={true}
                                                        checked={state.workQueueInUse}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("workQueueInUse", checked); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="commandRow">
                                            <label className="control-label listTitle">{t("permissions")}</label>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <CheckBox
                                                        title={Translations.WorkOrderCreationAllowedInMobileApp}
                                                        enabled={true}
                                                        checked={state.canCreateWorkOrderInMobile}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("canCreateWorkOrderInMobile", checked); }}
                                                    />
                                                    <CheckBox
                                                        title={t("canEditWorkEntries")}
                                                        enabled={true}
                                                        checked={state.canEditWorkEntries}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleChangeBoolean("canEditWorkEntries", checked); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="commandRow">
                                            <label className="control-label listTitle">{Translations.SalaryCalculation}</label>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="control-label smallFont">{Translations.SalaryPeriodType}</label>
                                                    <select className="form-control" name="salaryPeriodTypeId" title={Translations.SalaryPeriodType} value={state.salaryPeriodTypeId} onChange={this.handleChange}>
                                                        {state.salaryPeriodTypes.map((salaryPeriodType) =>
                                                            <option key={salaryPeriodType.id} value={salaryPeriodType.id}>{salaryPeriodType.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            {state.employments.length > 0 &&
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="control-label smallFont">{Translations.Employment}</label>
                                                        <select className="form-control" name="employmentId" title={Translations.Employment} value={state.employmentId} onChange={this.handleChange}>
                                                            {state.employments.map((employment) =>
                                                                <option key={employment.id} value={employment.id}>{employment.title}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row mt-2">
                                            {state.collectiveAgreements.length > 0 &&
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="control-label smallFont">{Translations.CollectiveAgreement}</label>
                                                        <select className="form-control" name="collectiveAgreementId" title={Translations.CollectiveAgreement} value={state.collectiveAgreementId} onChange={this.handleChange}>
                                                            {state.collectiveAgreements.map((collectiveagreement) =>
                                                                <option key={collectiveagreement.id} value={collectiveagreement.id}>{collectiveagreement.name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="control-label smallFont">{t("employeeGroup.paymentGroup")}</label>
                                                    <input type="text" className="form-control" name="paymentGroup" title={t("employeeGroup.paymentGroup")} value={state.paymentGroup} onChange={this.handleChange} maxLength={50} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}

const InfoTooltip = (props: {text: string}) => {

    return <div>info</div>;
};
