import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useTranslation } from "react-i18next";
import { VehicleDriving } from "../../models/vehicle/vehiclePosition";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";

dayjs.extend(duration);

export const getShiftAndSlotDuration = (workShifts: WorkShiftDto[], id: string): { timeSlotDuration: string; shiftDuration: string; effectiveDate: string } => {
    const workShiftAndTimeSlot = workShifts
        .flatMap(shift => shift.workShiftTimeSlots.map(timeSlot => ({ workShift: shift, timeSlot })))
        .find(({ workShift, timeSlot }) => timeSlot.employeeId === id && workShift.endTime === null);

    let timeSlotDuration = "";
    if (workShiftAndTimeSlot && workShiftAndTimeSlot.timeSlot) {
        const totalMinutes = new WorkShiftTimeSlotItem(workShiftAndTimeSlot.timeSlot).getDurationMinutes();
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        timeSlotDuration = hours > 0 ? `${hours} h ${minutes} min` : `${minutes} min`;
    }

    let shiftDuration = "";
    let effectiveDate = "";
    if (workShiftAndTimeSlot && workShiftAndTimeSlot.workShift) {
        const startTime = dayjs(workShiftAndTimeSlot.workShift.startTime);
        const now = dayjs();
        const diff = dayjs.duration(now.diff(startTime));
        const hours = Math.floor(diff.asHours());
        const minutes = diff.minutes();
        shiftDuration = `${startTime.format("D.M H:mm")} (${hours} h ${minutes} min)`;
        effectiveDate = workShiftAndTimeSlot.workShift.effectiveDate ? dayjs(workShiftAndTimeSlot.workShift.effectiveDate).format("YYYY-MM-DD") : "";
    }

    return { timeSlotDuration, shiftDuration, effectiveDate };
};

export const getVehicleStatusText = (status: VehicleDriving) => {
    const { t } = useTranslation();

    switch (status) {
        case VehicleDriving.Inactive:
            return t("monitorView.vehicleDriving.inactive");
        case VehicleDriving.Stopped:
            return t("monitorView.vehicleDriving.stopped");
        case VehicleDriving.Moving:
            return t("monitorView.vehicleDriving.moving");
        case VehicleDriving.Driving:
            return t("monitorView.vehicleDriving.driving");
        default:
            return t("monitorView.vehicleDriving.unknown");
    }
};
