import React, { useState, useEffect } from "react";
import {
    LocalizationProvider,
    DesktopTimePicker,
    DesktopDatePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Base } from "../../framework/base";

interface IDateTimePickerProps {
    value: string;
    dateLabel: string;
    timeLabel?: string;
    onChange: (val: string) => void;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    dateOnly?: boolean;
    asUtc?: boolean;
    fullWidth?: boolean;
}

export const MuiDateTimePicker = ({
    value,
    onChange,
    error,
    helperText,
    required,
    dateLabel,
    timeLabel,
    dateOnly,
    asUtc = true,
    fullWidth,
}: IDateTimePickerProps) => {
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
        dayjs(value)
    );

    useEffect(() => {
        if (value) {
            setSelectedDate(dayjs(value));
        }
    }, [value]);

    const handleDateChange = (date: Dayjs | null) => {
        setSelectedDate(date);
        if (date) {
            onChange(
                dateOnly
                    ? Base.dayjsToJsonDate(date)
                    : asUtc
                    ? date.toISOString()
                    : Base.dayjsToJsonDateTime(date)
            );
        } else {
            onChange("");
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
            <DesktopDatePicker
                label={dateLabel}
                value={selectedDate}
                onChange={handleDateChange}
                format="D.M.YYYY"
                closeOnSelect
                views={["day"]}
                slots={{ toolbar: null }}
                slotProps={{
                    textField: {
                        helperText,
                        error: !!error,
                        required,
                        fullWidth,
                    },
                }}
            />
            {!dateOnly && (
                <DesktopTimePicker
                    label={timeLabel}
                    value={selectedDate}
                    onChange={handleDateChange}
                    ampm={false}
                    closeOnSelect
                    slotProps={{
                        textField: {
                            helperText,
                            error: !!error,
                            required,
                        },
                    }}
                    view="hours"
                />
            )}
        </LocalizationProvider>
    );
};
