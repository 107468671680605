import React from "react";
import { Card, CardActionArea, CardContent } from "@mui/material";

interface CardContainerProps {
    isSelected: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const CardContainer: React.FC<CardContainerProps> = ({ isSelected, onClick, children }) => {
    return (
        <Card sx={{ borderRadius: 0, backgroundColor: isSelected ? "rgba(99, 164, 255, 0.3)" : "inherit" }}>
            <CardActionArea onClick={onClick}>
                <CardContent sx={{ padding: 0.5 }}>
                    { children }
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default CardContainer;