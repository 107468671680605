import { useMemo } from "react";
import { useAppSelector } from "../../framework/customStore";
import { makeSelectSalaryPeriodByDate } from "../../store/salaryPeriodSlice";

export const useSelectSalaryPeriodByDate = (date: string) => {
    const state = useAppSelector((state) => state.salaryPeriod);

    const selectSalaryPeriodByDate = useMemo(makeSelectSalaryPeriodByDate, []);
    return selectSalaryPeriodByDate(state, date);
};
