import { WorkShift, WorkShiftDto } from "../models/workShift/workShift";
import { apiCall } from "./apiClient";
import { getApiCall, postApiCall } from "./tokenService";

export const getWorkShifts = (
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    withSlots = false,
    signal?: AbortSignal
): Promise<WorkShiftDto[]> => {
    return getApiCall<WorkShiftDto[]>(
        `api/workshifts?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}&withSlots=${withSlots}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        null,
        signal
    );
};

export const getWorkShiftsNew = (
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    withSlots = false,
    signal?: AbortSignal
) => {
    return apiCall<WorkShiftDto[]>(
        `workshifts?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}&withSlots=${withSlots}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        "GET",
        null,
        signal
    );
};

export const saveWorkShifts = (
    employeeIds: string[],
    startDate: string,
    endDate: string,
    workShifts: WorkShift[]
): Promise<void> => {
    return postApiCall<void>(
        "api/workshifts/save",
        null,
        JSON.stringify({
            employeeIds,
            startDate,
            endDate,
            workShifts
        })
    );
};

export const getCurrentWorkShift = async (
    employeeId: string,
    withSlots = false,
    signal?: AbortSignal
): Promise<WorkShiftDto> => {
    const current = await getApiCall<WorkShiftDto>(
        `api/workshift/current?employeeId=${encodeURIComponent(
            employeeId
        )}&withSlots=${withSlots}`,
        null,
        signal
    ).catch((e) => {
        if (e.code !== "404") {
            throw e;
        }
    });

    return current || null;
};

export const saveWorkShift = (
    workShiftId: string,
    date: string
): Promise<void> => {
    return postApiCall<void>(
        `api/workshift/${encodeURIComponent(workShiftId)}/save`,
        null,
        JSON.stringify({ date })
    );
};
