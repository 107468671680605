import {
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityState,
    PayloadAction,
} from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISalaryPeriodItem } from "../models/salaryPeriod/salaryPeriodItem";
import { getSalaryPeriods } from "../services/salaryPeriodService";
import { Base } from "../framework/base";
import { RootState } from "../framework/customStore";

const salaryPeriodAdapter = createEntityAdapter<ISalaryPeriodItem>({
    sortComparer: (a, b) => a.startDate.localeCompare(b.startDate),
});

export const salaryPeriodSlice = createSlice({
    name: "salaryPeriod",
    initialState: salaryPeriodAdapter.getInitialState(),
    reducers: {
        setSalaryPeriods: (
            state,
            action: PayloadAction<ISalaryPeriodItem[]>
        ) => {
            salaryPeriodAdapter.setAll(state, action.payload);
        },
    },
});

export const { setSalaryPeriods } = salaryPeriodSlice.actions;

export const fetchSalaryPeriods = createAsyncThunk(
    "salaryPeriod/fetch",
    async(_, thunkApi) => {
        const salaryPeriodItems = await getSalaryPeriods();

        thunkApi.dispatch(setSalaryPeriods(salaryPeriodItems));
    }
);

export const salaryPeriodReducer = salaryPeriodSlice.reducer;

export const salaryPeriodSelector = salaryPeriodAdapter.getSelectors();

export const selectSalaryPeriods = (state: RootState) => state.salaryPeriod;

export const makeSelectSalaryPeriodByDate = () =>
    createSelector(
        (state: EntityState<ISalaryPeriodItem>) =>
            salaryPeriodSelector.selectAll(state),
        (_, date: string) => date,
        (periods: ISalaryPeriodItem[], date: string) =>
            periods.find((p) =>
                Base.isBetween(date, p.startDate, p.endDate, "day", "[]")
            )
    );
