import React, { useCallback, useMemo, useState } from "react";
import { MainLayout } from "../layout/mainLayout";
import { LoadingIndicator } from "../framework/loadingIndicatorNew";
import { VehiclePositionInfo } from "../../models/vehicle/vehiclePosition";
import { MonitorViewMap } from "./monitorViewMap";
import { MonitorViewFilters } from "./monitorViewFilters";
import { MonitorViewSidebar } from "./monitorViewSidebar";
import { Base } from "../../framework/base";
import { useAppSelector } from "../../framework/customStore";

import { EmployeeData, MonitorViewMainLayoutProps, VehicleData } from "../../models/monitorView/monitorViewTypes";

export const MonitorViewMainLayout = ({
    positions,
    vehicles,
    loading,
    workShift,
    workShifts,
    selectedVehicleId,
    selectedWorkShiftTimeSlotId,
    selectedEmployeeId,
    reloadKey,
    onRefresh,
    setSelectedVehicleId,
    setSelectedWorkShiftTimeSlot,
    setSelectedEmployeeId,
}: MonitorViewMainLayoutProps) => {
    const [hoveredWorkShiftTimeSlotId, setHoveredWorkShiftTimeSlotId] =
        useState<string>();

    const employees = useAppSelector(
        (state) => state.employees.employees
    );

    const showNoWorkTime = useAppSelector(
        (state) => state.monitorView.filters.noWorkTime
    );

    const selectedWorkTimeTypeIds = useAppSelector(
        (state) => state.monitorView.filters.workTimeTypeIds
    );

    const filterByWorkTimeType = useCallback(
        (p: VehiclePositionInfo) =>
            !selectedWorkTimeTypeIds ||
            p.employeeWorkTimeTypes.some((w) =>
                selectedWorkTimeTypeIds.includes(w.workTimeTypeId)
            ),
        [selectedWorkTimeTypeIds]
    );

    const applyFilters = (
        p: VehiclePositionInfo,
        showNoWorkTime: boolean,
        filterByWorkTimeType: (p: VehiclePositionInfo) => boolean
    ): boolean => {
        return (
            // If vehicles with no workTime are not shown,
            // Only filter by WorkTimeType
            (!showNoWorkTime && filterByWorkTimeType(p)) ||
            // Or if vehicles with no workTime are shown
            (showNoWorkTime && p.employeeWorkTimeTypes.length === 0) ||
            // Or by WorkTimeType
            filterByWorkTimeType(p)
        );
    };

    const filteredVehicleData: VehicleData[] = useMemo(
        () =>
            positions
                ?.filter((p) => applyFilters(p, showNoWorkTime, filterByWorkTimeType))
                .map((p) => ({
                    carId: p.carId,
                    vehicleId: p.vehicleId,
                    registerNumber: p.registerNumber,
                    brand: p.brand,
                    latitude: p.latitude,
                    longitude: p.longitude,
                    timestamp: p.timestamp,
                    employeeWorkTimeTypes: p.employeeWorkTimeTypes,
                    codriverGmt: p.codriverGmt,
                    codriverId: p.codriverId,
                    driverGmt: p.driverGmt,
                    driverId: p.driverId,
                    driving: p.driving,
                    drivingGmt: p.drivingGmt,
                    speed: p.speed,
                    coords: { lat: p.latitude, lng: p.longitude },
                    employeeName: p.employeeWorkTimeTypes[0]?.employeeName,
                    workTimeTypeName: p.employeeWorkTimeTypes[0]?.workTimeTypeName,
                    workTimeTypeType: p.employeeWorkTimeTypes[0]?.workTimeTypeType
                }))
                .sort((a, b) => Base.stringCompare(a.registerNumber, b.registerNumber))
                .sort((a, b) => Base.stringCompare(a.workTimeTypeName, b.workTimeTypeName)),
        [positions, showNoWorkTime, filterByWorkTimeType, workShifts]
    );

    const filteredEmployeeData: EmployeeData[] = useMemo(
        () =>
            employees?.map((e) => {
                const position = positions
                    ?.filter((p) => applyFilters(p, showNoWorkTime, filterByWorkTimeType))
                    ?.find((p) =>
                        p.employeeWorkTimeTypes.some((w) => w.employeeId === e.id)
                    );
                const workTimeType = position?.employeeWorkTimeTypes.find(
                    (w) => w.employeeId === e.id
                );
                const vehicleId = position?.vehicleId;
                return {
                    employeeId: e.id,
                    employeeName: e.fullName,
                    workTimeTypeName: workTimeType?.workTimeTypeName,
                    workTimeTypeType: workTimeType?.workTimeTypeType,
                    vehicle: vehicleId ? {
                        vehicleId: vehicleId,
                        registerNumber: position?.registerNumber,
                        brand: position?.brand
                    } : null,
                    workShifts: workShifts,
                };
            }).sort((a, b) =>
                Base.stringCompare(a.workTimeTypeName, b.workTimeTypeName)
            ),
        [positions, employees, showNoWorkTime, filterByWorkTimeType, workShifts]
    );

    const countByWorkTimeType = useMemo(
        () =>
            positions
                ? Object.fromEntries(
                    Object.values(
                        Base.groupArray(
                            positions.map((p) => ({
                                id: p.employeeWorkTimeTypes[0]
                                    ?.workTimeTypeId,
                            })),
                            "id"
                        )
                    ).map((v) => [v[0].id, v.length])
                )
                : {},
        [positions]
    );

    const activeEmployeesAndVehicles = useMemo(
        () => {
            let activeEmployees = 0;
            let activeVehicles = 0;

            positions
                ?.filter((p) => applyFilters(p, showNoWorkTime, filterByWorkTimeType))
                ?.forEach(({ employeeWorkTimeTypes }) => {
                    if (employeeWorkTimeTypes?.length > 0) {
                        activeVehicles++;
                        activeEmployees++;
                    }
                });

            const totalEmployees = employees?.length > 0 ? employees.length - 1 : 0; // exclude admin user
            const totalVehicles = vehicles?.length || 0;

            return {
                activeVehicles: `${activeVehicles}/${totalVehicles}`,
                activeEmployees: `${activeEmployees}/${totalEmployees}`
            };
        }, [positions, showNoWorkTime, filterByWorkTimeType]);

    const createSidebarProps = <T, >(
        data: T[],
        selectedId: string,
        setSelectedId: (id: string) => void,
        additionalProps = {}
    ) => ({
            data,
            selectedId,
            setSelectedId,
            ...additionalProps
        });

    const vehicleSidebarProps = useMemo(
        () => createSidebarProps(filteredVehicleData, selectedVehicleId, setSelectedVehicleId),
        [filteredVehicleData, selectedVehicleId, setSelectedVehicleId]
    );

    const employeeSidebarProps = useMemo(
        () => createSidebarProps(filteredEmployeeData, selectedEmployeeId, setSelectedEmployeeId),
        [filteredEmployeeData, selectedEmployeeId, setSelectedEmployeeId]
    );

    const workShiftSidebarProps = useMemo(
        () => createSidebarProps(
            [{ workShift }],
            selectedWorkShiftTimeSlotId,
            setSelectedWorkShiftTimeSlot,
            {
                setHoveredWorkShiftTimeSlotId: (id) => setHoveredWorkShiftTimeSlotId(id),
                setSelectedVehicleId: setSelectedVehicleId,
            }
        ),
        [workShift, selectedWorkShiftTimeSlotId, setSelectedWorkShiftTimeSlot, setHoveredWorkShiftTimeSlotId, setSelectedVehicleId]
    );

    return (
        <MainLayout
            noPadding
            sideComponentSide="left"
            topComponent={
                <MonitorViewFilters countByWorkTimeType={countByWorkTimeType} />
            }
            sideComponent={
                <MonitorViewSidebar
                    vehicleSidebarProps={vehicleSidebarProps}
                    workShiftSidebarProps={workShiftSidebarProps}
                    employeeSidebarProps={employeeSidebarProps}
                    activeEmployeesAndVehicles={activeEmployeesAndVehicles}
                />
            }
            sideOverFlowHidden
        >
            <>
                <LoadingIndicator loading={loading} />
                <MonitorViewMap
                    loading={loading}
                    vehicleData={filteredVehicleData}
                    onRefresh={() => onRefresh(true)}
                    selectedVehicleId={selectedVehicleId}
                    setSelectedVehicle={setSelectedVehicleId}
                    workShift={workShift}
                    reloadKey={reloadKey}
                    selectedWorkShiftTimeSlotId={selectedWorkShiftTimeSlotId}
                    hoveredWorkShiftTimeSlotId={hoveredWorkShiftTimeSlotId}
                    setSelectedWorkShiftTimeSlotId={
                        setSelectedWorkShiftTimeSlot
                    }
                />
            </>
        </MainLayout>
    );
};
