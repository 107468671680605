import { styled } from "@mui/system";
import { Typography } from "@mui/material";

export const Text = styled(Typography)(() => ({
    display: "inline-block",
}));

Text.defaultProps = {
    variant: "body2",
};

export const BoldText = styled(Typography)(() => ({
    display: "inline-block",
    fontWeight: "bold"
}));

BoldText.defaultProps = {
    variant: "body2",
};

export const Subtitle = styled(Typography)(({ theme }) => ({
    display: "inline-block",
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: "0.875rem"
}));

export const Subtitle2 = styled(Typography)(({ theme }) => ({
    display: "inline-block",
    color: theme.palette.text.secondary,
    fontSize: "0.75em"
}));
